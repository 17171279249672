import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiSbpPurchaseDetail.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import InputContainer from "../InputContainer/InputContainer.component";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Switch, Tab, Tabs } from "@mui/material";
import moment from "moment";
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseStorage } from "../../backend/firebaseHandler";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import { MdOutlineFileDownload } from "react-icons/md"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";

const SbpPurchaseDetail = () => {

    const location = useLocation()

    const [userData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);
    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState(0)
    const [linkModal, setLinkModal] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const sendLink = () => {

        if (!application.paymentLink) {
            setSnackBar({ severity: "warning", visibility: true, message: "Please upload payment link" })
            return
        }

        let tempObj = { ...application }

        tempObj.paymentLinkSentDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.linkSentBy = userData.uid
        tempObj.status = "Link Sent"
        tempObj.subStatus = "Sent"

        setSubmitting(true)
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Link Sent"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Link Sent",
                    subStatus: "Sent",
                    date: tempObj.paymentLinkSentDate,
                    ref: "HI_SBP_APPLICATIONS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HI_SBP_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
        
    }

    const handleHealthDeclaration = () => {
        let requestDate = moment().format("DD-MM-YYYY")
        let url = `https://sc-srm-api-nine.vercel.app/pdf/generate-health-declaration?name=${application.name}&travelDate=${application.travelDate}&effDate=${application.coverageStartDate}&toDate=${application.coverageEndDate}&requestDate=${requestDate}&nomineeName=${application.nomineeName}&nomineeDob=${application.nomineeDOB}&nomineeRelation=${application.nomineeRelation}&provider=${application.planDetail.provider}`
        window.open(url, "_blank")
    }

    const handleFile = (type) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `SBP_PURCHASE_DOCS/${application.key}/${type}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    
                    if (type === "Payment Proof") {
                        handlePaymentProof(url)
                    } else {
                        handlePolicy(url)
                    }
                    
                    
                })
            }
        }
        inputElement.click();
    }

    const handlePaymentProof = async (url) => {
        let tempObj = {...application}
        tempObj.paymentProofDoc = url
        tempObj.paymentProofUploadDate = moment().format("DD-MM-YYYY h:mm a")

        if (!application.phoneNumber) {
            alert ("Something doesn't seem right.")
            return
        }
        await set(ref(firebaseDatabase, `HI_SBP_APPLICATIONS/${application.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)
        setApplication({ ...tempObj })
        setSubmitting(false)
        alert("Payment Proof Uploaded")
        navigate(-1)
    }

    const handlePolicy = (url) => {
        let tempObj = {...application}
        tempObj.policyDoc = url
        tempObj.policyDocUploadDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.status = "Policy Generated"

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${tempObj.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Policy Generated"
                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Policy Generated",
                    subStatus: "",
                    date: tempObj.policyDocUploadDate,
                    ref: "HI_SBP_APPLICATIONS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                if (!application.phoneNumber) {
                    alert ("Something doesn't seem right.")
                    return
                }

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${tempObj.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `HI_SBP_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
                setSubmitting(false)
                alert("Policy Document Uploaded")
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    return (
        <div className={Styles.hiOpCompLeadDetailContainer}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>

                <table style={{ backgroundColor: "#98fab2", padding: '16px 0', borderRadius: "12px" }}>
                    <tr>
                        <th style={{ fontSize: '0.9rem' }}>Current Plan</th>
                        <th style={{ fontSize: '0.9rem' }}>{application.planDetail.name === "SC Gold Plus" ? "Sum Insured" : "Deductable"}</th>
                        <th style={{ fontSize: '0.9rem' }}>Premium</th>
                    </tr>
                    <tr>
                        <td style={{ padding: "0 24px " }}>{application.planDetail.name}</td>
                        <td style={{ padding: "0 24px " }}>₹ {application.planDetail.deductible === "a" ? "--" : application.planDetail.deductible}</td>
                        <td style={{ padding: "0 24px " }}>₹ {application.planDetail.premium}</td>
                    </tr>
                </table>

                {/* <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Applicant Details" {...a11yProps(0)} />
                    <Tab label="More Details" {...a11yProps(1)} />
                    <Tab label="Upload App Docs" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.formDocContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input value={application.dob} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email-Id</p>
                            <input value={application.emailId} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Gender</p>
                            <Select style={{ width: "100%" }} readOnly value={application.gender || ""} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Male"} >Male</MenuItem>
                                <MenuItem value={"Female"} >Female</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                        <div style={{ gridColumn: "1/2" }}>
                            <InputContainer value={application.phoneNumber} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Is this whatsapp number?</p>
                            <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "No" }) }}>No</p>
                        </div>

                        {
                            application.isThisWhatsappNumber === "No"
                            &&
                            <InputContainer value={application.whatsappNumber} className={Styles.inputField} placeholder="Whatsapp Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        }

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Traveling to</p>
                            <input value={application.desiredCountry} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University</p>
                            <input value={application.desiredUniversity} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Travel Date</p>
                            <input value={application.travelDate} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Period</p>
                            <input value={application.coveragePeriod} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Start Date</p>
                            <input value={application.coverageStartDate} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage End Date</p>
                            <input value={application.coverageEndDate} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>In India?</p>
                            <input value={application.currentCountry} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                    </div>
                }

                {
                    value === 1
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>PAN number</p>
                            <input value={application.panNumber} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Sponsor name</p>
                            <input value={application.sponsorName} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Emergency contact number</p>
                            <input value={application.emergencyNumber} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Alternate email-id</p>
                            <input value={application.alternateEmail} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Mother's name</p>
                            <input value={application.motherName} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Passport number</p>
                            <input value={application.passportNumber} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee Name</p>
                            <input value={application.nomineeName} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee relation</p>
                            <input value={application.nomineeRelation} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee dob</p>
                            <input value={application.nomineeDOB} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address</p>
                            <input value={application.indiaAddress} disabled className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Do you have a US address?</p>
                            <p style={{ color: application.haveUsAddress === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >Yes</p>
                            <p>|</p>
                            <p style={{ color: application.haveUsAddress === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >No</p>
                        </div>

                        {
                            application.haveUsAddress === "Yes"
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>US Address</p>
                                <input value={application.usAddress} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                application.applicantDocs
                                &&
                                application.applicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        
                        {
                            application.currentCountry !== "Yes"
                            &&
                            <div className={Styles.downloadButton} onClick={()=>{handleHealthDeclaration()}}>
                                <p>Download Health Declaration</p>
                                <MdOutlineFileDownload color="white" />
                            </div>
                        }
                    </>
                }
            </div>

            {
                userData.position === "Operations"
                &&
                <div className={Styles.buttonsContainer}>
                    {
                        application.status === "Operations"
                        ?
                        <Button disabled={submitting} onClick={() => { setLinkModal(true) }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Send Payment Link</Button>
                        :
                        (application.status === "Link Sent" && !application.paymentProofDoc)
                        ?
                        <Button disabled={submitting} onClick={() => {handleFile("Payment Proof")}} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Upload Payment Proof</Button>
                        :
                        (application.status === "Link Sent" && application.paymentProofDoc)
                        ?
                        <Button disabled={submitting} onClick={() => {handleFile("Policy")}} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Upload Policy</Button>
                        :
                        null
                    }
                </div>
            }
            

            {/* Payment link modal */}
            <Modal open={linkModal} onClose={() => { setLinkModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>

                        <div style={{ width: "300px", display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 20, flex: 1 }}>
                            <p className={Styles.label}>Payment link</p>
                            <input value={application.paymentLink} onChange={(event) => { setApplication({ ...application, paymentLink: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setLinkModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { sendLink() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default SbpPurchaseDetail