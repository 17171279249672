import Styles from "./NbfcManagerDisbursedLeads.module.css"
import React, { useState } from "react";
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"

const NbfcManagerDisbursedLeads = ({ activeList }) => {

    return (
        <div>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Snc Amount</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Disb Amount</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Disb Date</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Executive</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.applicationId}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.sanctionedAmount}</TableCell>
                                            <TableCell>{item.disbursedAmount}</TableCell>
                                            <TableCell>{item.disbursementAmountDate?item.disbursementAmountDate.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.assignedRmName}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default NbfcManagerDisbursedLeads