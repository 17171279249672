import React, { useContext, useEffect, useState } from "react";
import Styles from "./FutureIntakeLeadDetail.module.css"
import InputContainer from "../InputContainer/InputContainer.component";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import QRCode from "react-qr-code";
import { Alert, Box, Button, Checkbox, FormControlLabel, MenuItem, Modal, Select, Snackbar, Switch, Tab, Tabs } from "@mui/material";
import relationMap from "../../workload/CoSignerRelationMap";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseSC } from "../../backend/firebaseHandler";
import isEmail from "validator/lib/isEmail";
import moment from "moment";
import getDocumentMap from "../../workload/DocumentSet";
import CryptoJS from "crypto-js";

const FutureIntakeLeadDetail = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [userData, setUserData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);

    const [examsWritten, setExamsWritten] = useState([])

    const [accountExists, setAccountExists] = useState("No")
    const [accountModal, setAccountModal] = useState(false)

    const [qrLink, setQrLink] = useState("")

    const [submitting, setSubmitting] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })


    useEffect(() => {
        onValue(ref(firebaseDatabaseSC, `USERS_ARCHIVE/${application.phoneNumber}`), (snapShot) => {
            if (snapShot.exists()) {
                setAccountExists("Yes")
            } else {
                setAccountExists("No")
            }
        }, { onlyOnce: true })

        if (application.examsWritten) {
            setExamsWritten(application.examsWritten)
        }

        if (application.type === "INCOGNITO") {
            setQrLink(encrypt(`INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
        } else {
            setQrLink(encrypt(`NEW_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
        }

    }, [])

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    const checkDialogContent = () => {

        if (application.name === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the customer's name" })
            return
        }
        if (application.emailId === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the customer's email-Id" })
            return
        }

        if (!isEmail(application.emailId)) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter a valid email-Id" })
            return
        }

        setAccountModal(true)
    }

    const moveToInterested = async () => {
        setSubmitting(true)

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                setSubmitting(false)

                return
            }
        }
        let tempObj = { ...application }

        tempObj.status = "Interested"
        tempObj.interestedDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.examsWritten = [...examsWritten]
        tempObj.customerId = Date.now().toString()

        if (tempObj.type === "INCOGNITO") {
            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    let lifelineObj = snap.val()
                    lifelineObj.status = "Interested"
    
                    let array = [...lifelineObj.lifeline]
                    let arrayItem = {
                        actionBy: userData.uid,
                        status: "Interested",
                        subStatus: "",
                        date: tempObj.interestedDate,
                        ref: "INCOGNITO_INTERESTED_LEADS"
                    }
                    array.push(arrayItem)
                    lifelineObj.lifeline = [...array]
                    await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
    
                    if (accountExists === "No") {
                        let tempAccObj = {}
    
                        tempAccObj = {
                            ...tempAccObj,
                            "createdBy": userData.uid,
                            "emailId": tempObj.emailId,
                            "isSignup": true,
                            "name": tempObj.name,
                            "otp": "",
                            "otpSent": "",
                            "phoneNumber": tempObj.phoneNumber,
                            "signupTimestamp": ""
                        }
                        await set(ref(firebaseDatabaseSC, `USERS_ARCHIVE/${tempAccObj.phoneNumber}`), tempAccObj)
                    }
    
                    await set(ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                    await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                    await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`));
                    setSubmitting(false)
                    setAccountModal(false)
                    navigate(-1)
                }
            }, { onlyOnce: true })
        } else {
            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    let lifelineObj = snap.val()
                    lifelineObj.status = "Interested"
    
                    let array = [...lifelineObj.lifeline]
                    let arrayItem = {
                        actionBy: userData.uid,
                        status: "Interested",
                        subStatus: "",
                        date: tempObj.interestedDate,
                        ref: "INTERESTED_LEADS"
                    }
                    array.push(arrayItem)
                    lifelineObj.lifeline = [...array]
                    await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)
    
                    if (accountExists === "No") {
                        let tempAccObj = {}
    
                        tempAccObj = {
                            ...tempAccObj,
                            "createdBy": userData.uid,
                            "emailId": tempObj.emailId,
                            "isSignup": true,
                            "name": tempObj.name,
                            "otp": "",
                            "otpSent": "",
                            "phoneNumber": tempObj.phoneNumber,
                            "signupTimestamp": ""
                        }
                        await set(ref(firebaseDatabaseSC, `USERS_ARCHIVE/${tempAccObj.phoneNumber}`), tempAccObj)
                    }
    
                    await set(ref(firebaseDatabase, `INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                    await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                    await remove(ref(firebaseDatabase, `NEW_LEADS/${userData.uid}/${application.phoneNumber}`));
                    setQrLink(`INTERESTED_LEADS/${userData.uid}`)
                    setSubmitting(false)
                    setAccountModal(false)
                    navigate(-1)
                }
            }, { onlyOnce: true })
        }
    }

    const handleCoApplicantSelect = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicant = value
        tempObj.coApplicantProfession = ""
        tempObj.coApplicantAge = ""
        tempObj.coApplicantDocs = null
        tempObj.whyFatherNotFin = ""
        tempObj.whyMotherNotFin = ""
        tempObj.coApplicantInIndia = ""
        tempObj.nonFinancialCoApplicants = [...relationMap[value]]

        let tempDocs = []
        for (const index in tempObj.nonFinancialCoApplicants) {
            tempDocs.push(getDocumentMap()["NonFinancial"])
        }

        tempObj.nonFinancialDocs = [...tempDocs]
        setApplication({ ...tempObj })
    }

    const handleProfession = (value) => {

        let tempObj = { ...application }
        tempObj.coApplicantProfession = value

        let searchWord = ""

        if (["Father", "Mother", "Spouse", "Father-in-law", "Mother-in-law", "Sister", "Brother"].includes(application.coApplicant)) {
            searchWord = "Direct"
        } else {
            searchWord = "InDirect"
        }
        tempObj.coApplicantDocs = [...getDocumentMap()[searchWord][value]]

        setApplication({ ...tempObj })
    }

    const handleWorkExperience = (value) => {
        setApplication({ ...application, hasWorkExperience: value })

        let tempObj = { ...application }
        tempObj.hasWorkExperience = value

        if (value === "Yes") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentWorking"]]
        } else if (value === "No") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentNonWorking"]]
        } else {
            tempObj.applicantDocs = [...getDocumentMap()["StudentUsedToWork"]]
        }

        setApplication(_ => ({ ...tempObj }))
    }

    const handleLGDocs = (value) => {
        let tempObj = { ...application }

        tempObj.hasOwnHouse = value
        if (value === "No") {
            tempObj["locationGuarantorDocs"] = [...getDocumentMap()["LocationGuarantor"]]
        } else {
            tempObj["locationGuarantorDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleCurrentCountry = (value) => {
        let tempObj = { ...application }

        tempObj.currentlyInIndia = value
        if (value === "No") {
            tempObj["abroadDocs"] = [...getDocumentMap()["InAbroad"]]
        } else {
            tempObj["abroadDocs"] = []
        }
        setApplication({ ...tempObj })
    }

    const handleCoAppLocation = (value) => {
        let tempObj = { ...application }

        tempObj.coApplicantInIndia = value
        if (value === "No") {
            tempObj["abroadCoAppDocs"] = [...getDocumentMap()["AbroadCoApplicant"]]
        } else {
            tempObj["abroadCoAppDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleExams = (value) => {
        setExamsWritten(value)

        let tempArray = []
        let tempObj = { ...application }
        for (const index in value) {
            let obj = {
                name: value[index],
                url: "",
                label: value[index]
            }
            tempArray.push(obj)
        }
        tempObj["examDocs"] = [...tempArray]
        setApplication({ ...tempObj })
    }

    return (
        <div>

            <div className={Styles.headerContainer}>

                <div>
                    <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>
                    <p style={{backgroundColor:"yellow"}}><span style={{fontWeight:500}}>{application.futureIntakeType}</span></p>
                </div>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div>
            </div>

            <div className={Styles.formContainer} style={{marginTop:40}}>
                <InputContainer value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                <InputContainer value={"91XXXXXXXXX"} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                <InputContainer value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Date of Birth</p>
                    <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Level of Education</p>
                    <Select style={{ width: "100%" }} value={application.levelOfStudy} onChange={(event) => { setApplication({ ...application, levelOfStudy: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value="UG">UG</MenuItem>
                        <MenuItem value="PG">PG</MenuItem>
                        <MenuItem value="Diploma">Diploma</MenuItem>
                        <MenuItem value="Double Master">Double Master</MenuItem>
                        <MenuItem value="PHD">PHD</MenuItem>
                    </Select>
                </div>

                <div style={{ width: "100%", gridColumn: "1/2" }}>
                    <p className={Styles.label}>Currently in India?</p>
                    <Select style={{ width: "100%" }} value={application.currentlyInIndia} onChange={(event) => { handleCurrentCountry(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </Select>
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Abroad Phone Number</p>
                    <input value={application.abroadPhoneNumber} onChange={(event) => { setApplication({ ...application, abroadPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Abroad Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

                <div style={{ width: "100%", gridColumn: "1/2" }}>
                    <p className={Styles.label}>Desired Country</p>
                    <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} placeholder="Desired Country" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Desired University</p>
                    <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} placeholder="Desired University" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Entrance Exams written</p>
                    <Select multiple style={{ width: "100%" }} value={examsWritten} onChange={(event) => { handleExams(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value="GRE">GRE</MenuItem>
                        <MenuItem value="TOFEL">TOFEL</MenuItem>
                        <MenuItem value="IELTS">IELTS</MenuItem>
                        <MenuItem value="Duolingo">Duolingo</MenuItem>
                        <MenuItem value="PTE">PTE</MenuItem>
                        <MenuItem value="Other Exam">Other Exam</MenuItem>
                    </Select>
                </div>

                <InputContainer value={application.requiredLoanAmount.replace(/[^0-9]+/g, "")} onChange={(event) => { setApplication({ ...application, requiredLoanAmount: event.target.value }) }} className={Styles.inputField} placeholder="Loan amount required" InputProps={{ disableUnderline: true }} variant="standard" />

                <div className={Styles.yesNoContainer}>
                    <p>Have you applied to any Bank?</p>
                    <p style={{ color: application.hasPreAppliedToBank === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "Yes" }) }}>Yes</p>
                    <p>|</p>
                    <p style={{ color: application.hasPreAppliedToBank === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "No" }) }}>No</p>
                </div>

                <InputContainer value={application.preAppliedBank} readOnly={application.hasPreAppliedToBank !== "Yes"} onChange={(event) => { setApplication({ ...application, preAppliedBank: event.target.value }) }} className={Styles.inputField} placeholder="Bank Name" InputProps={{ disableUnderline: true }} variant="standard" />

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Work experience?</p>
                    <Select style={{ width: "100%" }} value={application.hasWorkExperience} onChange={(event) => { handleWorkExperience(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value="Yes">Currently Working</MenuItem>
                        <MenuItem value="Past">Used to work</MenuItem>
                        <MenuItem value="No">No work experience</MenuItem>
                    </Select>
                </div>

                <div className={Styles.yesNoContainer}>
                    <p>Have your own house?</p>
                    <p style={{ color: application.hasOwnHouse === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("Yes") }}>Yes</p>
                    <p>|</p>
                    <p style={{ color: application.hasOwnHouse === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("No") }}>No</p>
                </div>

                <div style={{ width: "100%", gridColumn: "1/2" }}>
                    <p className={Styles.label}>Who will be your co-applicant?</p>
                    <Select style={{ width: "100%" }} value={application.coApplicant} onChange={(event) => { handleCoApplicantSelect(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        {
                            Object.keys(relationMap).map((item, index) => {
                                return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
                {
                    application.coApplicant !== ""
                    &&
                    <>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Co-applicant's Profession?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicantProfession} onChange={(event) => { handleProfession(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Salaried">Salaried</MenuItem>
                                <MenuItem value="Self Employed - Professional">Self Employed - Professional</MenuItem>
                                <MenuItem value="Self Employed - Non Professional">Self Employed - Non Professional</MenuItem>
                                <MenuItem value="Farmer">Farmer</MenuItem>
                                <MenuItem value="Pensioner">Pensioner</MenuItem>
                                <MenuItem value="Avanse Global">Avanse Global</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Co-applicant's Age?</p>
                            <input value={application.coApplicantAge} onChange={(event) => { setApplication({ ...application, coApplicantAge: event.target.value }) }} className={Styles.inputField} placeholder="Co-applicant's Age" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Co-applicant in India?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicantInIndia} onChange={(event) => { handleCoAppLocation(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                    </>
                }
            </div>
            <div className={Styles.buttonsContainer}>
                <Button onClick={() => { checkDialogContent() }} className={Styles.button} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>Interested</Button>
            </div>

            <Modal open={accountModal} onClose={() => { setAccountModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p>Are you sure you want to mark this lead <span style={{ fontWeight: 700 }}>Interested</span>?</p>
                        <p style={{ marginTop: 15 }}>An account will automatically be created for <span style={{ fontWeight: 600 }}>{application.name}</span> linked to Email ID - <span style={{ fontWeight: 600 }}>{application.emailId}</span>.</p>

                        <div className={Styles.modalButtonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setAccountModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryButton}>Proceed</Button>
                        </div>
                    </div>


                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
       </div>
    )
}

export default FutureIntakeLeadDetail