import React, { useState } from "react";
import Styles from "./NbfcManagerNewLeads.module.css"
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"
import { IoMdInformationCircle } from "react-icons/io"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";

const NbfcManagerNewLeads = ({ activeList }) => {

    const [infoModal, setInfoModal] = useState(false)
    const [advisorDetail, setAdvisorDetail] = useState({})

    const handleAdvisorInfo = (application) => {
        onValue(ref(firebaseDatabase, `USER_ARCHIVE/${application.assignedAdvisorUid}`), (snap) => {
            if (snap.exists()) {
                setAdvisorDetail(snap.val())
                setInfoModal(true)
            }
        }, {onlyOnce:true})
    }

    return (
        <div className={Styles.testBox}>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Passport</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>SC Advisor</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Executive</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.customerId}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.sentToVendorDate.split(" ")[0]}</TableCell>
                                            <TableCell>{item.panNumber}</TableCell>
                                            <TableCell><HiDocumentCheck onClick={()=>{window.open(item.passportUrl, "_blank")}} color={"#352F6C"} size={25} style={{cursor:"pointer"}} /></TableCell>
                                            <TableCell><div style={{display:"flex", flexDirection:"row", alignItems:"center"}}><p>{item.assignedAdvisorName}</p><IoMdInformationCircle size={20} color="#352F6C" onClick={()=>{handleAdvisorInfo(item)}} style={{cursor:"pointer"}} /></div></TableCell>
                                            <TableCell>{item.assignedRmName}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Modal open={infoModal} onClose={() => { setInfoModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Advisor Detail</p>
                        <div className={Styles.infoContainer}>
                            <p className={Styles.infoItem}>Name</p>
                            <p className={Styles.infoItem}>{advisorDetail.name}</p>

                            <p className={Styles.infoItem}>Phone Number</p>
                            <p className={Styles.infoItem}>{advisorDetail.phoneNumber}</p>

                            <p className={Styles.infoItem}>Email-Id</p>
                            <p className={Styles.infoItem}>{advisorDetail.emailId}</p>
                        </div>

                        <Button onClick={() => {setInfoModal(false)}} variant='contained' className={Styles.primaryButton}>Okay</Button>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default NbfcManagerNewLeads