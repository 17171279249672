const getDocumentMap = () => {
    const documentMap = {
        "StudentNonWorking": [
            {
                name: "Aadhar Card",
                url: "",
                label: "StudentNonWorking_AadharCard"
            },
            {
                name: "Pan Card",
                url: "",
                label: "StudentNonWorking_PanCard"
            },
            {
                name: "Passport",
                url: "",
                label: "StudentNonWorking_Passport"
            },
            {
                name: "10th Mark Sheet",
                url: "",
                label: "StudentNonWorking_10thMarkSheet"
            },
            {
                name: "12th Mark Sheet",
                url: "",
                label: "StudentNonWorking_12thMarkSheet"
            },
            {
                name: "Graduation Mark Sheet",
                url: "",
                label: "StudentNonWorking_GradMarkSheet"
            },
            {
                name: "Transcript",
                url: "",
                label: "StudentNonWorking_Transcript"
            },
            {
                name: "Provisional Certificate",
                url: "",
                label: "StudentNonWorking_PC"
            },
            {
                name: "University Offer Letter",
                url: "",
                label: "StudentNonWorking_UniOfferLetter"
            },
            {
                name: "Fee Structure",
                url: "",
                label: "StudentNonWorking_FeeStructure"
            }
        ],
        "StudentWorking": [
            {
                name: "Aadhar Card",
                url: "",
                label: "StudentWorking_AadharCard"
            },
            {
                name: "Pan Card",
                url: "",
                label: "StudentWorking_PanCard"
            },
            {
                name: "Passport",
                url: "",
                label: "StudentWorking_Passport"
            },
            {
                name: "10th Mark Sheet",
                url: "",
                label: "StudentWorking_10thMarkSheet"
            },
            {
                name: "12th Mark Sheet",
                url: "",
                label: "StudentWorking_12thMarkSheet"
            },
            {
                name: "Graduation Mark Sheet",
                url: "",
                label: "StudentWorking_GradMarkSheet"
            },
            {
                name: "Transcript",
                url: "",
                label: "StudentWorking_Transcript"
            },
            {
                name: "Provisional Certificate",
                url: "",
                label: "StudentWorking_PC"
            },
            {
                name: "University Offer Letter",
                url: "",
                label: "StudentWorking_UniOfferLetter"
            },
            {
                name: "Fee Structure",
                url: "",
                label: "StudentWorking_FeeStructure"
            },
            {
                name: "Last 3 Month Payslips",
                url: "",
                label: "StudentWorking_3MonthPayslip"
            },
            {
                name: "Last 6 month bank statement Savings Account",
                url: "",
                label: "StudentWorking_6MonthBSSavings"
            },
            {
                name: "Last 6 month bank statement Salary Account",
                url: "",
                label: "StudentWorking_6MonthBSSalary"
            },
            {
                name: "Last 2 year Form 16",
                url: "",
                label: "StudentWorking_2YearForm16"
            }
        ],
        "StudentUsedToWork": [
            {
                name: "Aadhar Card",
                url: "",
                label: "StudentUsedToWork_AadharCard"
            },
            {
                name: "Pan Card",
                url: "",
                label: "StudentUsedToWork_PanCard"
            },
            {
                name: "Passport",
                url: "",
                label: "StudentUsedToWork_Passport"
            },
            {
                name: "10th Mark Sheet",
                url: "",
                label: "StudentUsedToWork_10thMarkSheet"
            },
            {
                name: "12th Mark Sheet",
                url: "",
                label: "StudentUsedToWork_12thMarkSheet"
            },
            {
                name: "Graduation Mark Sheet",
                url: "",
                label: "StudentUsedToWork_GradMarkSheet"
            },
            {
                name: "Transcript",
                url: "",
                label: "StudentUsedToWork_Transcript"
            },
            {
                name: "Provisional Certificate",
                url: "",
                label: "StudentUsedToWork_PC"
            },
            {
                name: "University Offer Letter",
                url: "",
                label: "StudentUsedToWork_UniOfferLetter"
            },
            {
                name: "Fee Structure",
                url: "",
                label: "StudentUsedToWork_FeeStructure"
            },
            {
                name: "Last 6 month bank statement Savings Account",
                url: "",
                label: "StudentUsedToWork_6MonthBSSavings"
            },
            {
                name: "Joining/Relieving Letter",
                url: "",
                label: "StudentUsedToWork_JoiningLetter"
            }
        ],
        "InAbroad": [
            {
                name: "Visa",
                url: "",
                label: "InAbroad_Visa"
            },
            {
                name: "Abroad university mark sheet",
                url: "",
                label: "InAbroad_AbroadUniversityMarkSheet"
            },
            {
                name: "Abroad Address Proof",
                url: "",
                label: "InAbroad_AbroadAddressProof"
            },
            {
                name: "Fees Receipt",
                url: "",
                label: "InAbroad_FeesReceipt"
            },
        ],
        "Direct": {
            "Salaried": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "DirectSalaried_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "DirectSalaried_PanCard"
                },
                {
                    name: "Last 2 Year Form 16",
                    url: "",
                    label: "DirectSalaried_Form16"
                },
                {
                    name: "Last 3 month payslip",
                    url: "",
                    label: "DirectSalaried_3MonthPayslip"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "DirectSalaried_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Salary Account",
                    url: "",
                    label: "DirectSalaried_6MonthBSSalary"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "DirectSalaried_PropertyDocuments"
                }
            ],
            "Self Employed - Professional": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "DirectSelfEmployedProfessional_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "DirectSelfEmployedProfessional_PanCard"
                },
                {
                    name: "Last 2 year ITR",
                    url: "",
                    label: "DirectSelfEmployedProfessional_2YearITR"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "DirectSelfEmployedProfessional_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Current Account",
                    url: "",
                    label: "DirectSelfEmployedProfessional_6MonthBSCurrent"
                },
                {
                    name: "AOA",
                    url: "",
                    label: "DirectSelfEmployedProfessional_AOA"
                },
                {
                    name: "MOA",
                    url: "",
                    label: "DirectSelfEmployedProfessional_MOA"
                },
                {
                    name: "COI",
                    url: "",
                    label: "DirectSelfEmployedProfessional_COI"
                },
                {
                    name: "Company Pan",
                    url: "",
                    label: "DirectSelfEmployedProfessional_CompanyPan"
                },
                {
                    name: "Business Proof",
                    url: "",
                    label: "DirectSelfEmployedProfessional_BusinessProof"
                },
                {
                    name: "1st Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedProfessional_1stPic"
                },
                {
                    name: "2nd Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedProfessional_2ndPic"
                },
                {
                    name: "3rd Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedProfessional_3rdPic"
                },
                {
                    name: "4th Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedProfessional_4thPic"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "DirectSelfEmployedProfessional_PropertyDocuments"
                }
            ],
            "Self Employed - Non Professional": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_PanCard"
                },
                {
                    name: "Last 2 year ITR",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_ITR"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Current Account",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_6MonthBSCurrent"
                },
                {
                    name: "Business Proof",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_BusinessProof"
                },
                {
                    name: "1st Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_1stPic"
                },
                {
                    name: "2nd Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_2ndPic"
                },
                {
                    name: "3rd Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_3rdPic"
                },
                {
                    name: "4th Picture of Business",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_4thPic"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "DirectSelfEmployedNonProfessional_PropertyDocuments"
                }
            ],
            "Farmer": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "DirectFarmer_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "DirectFarmer_PanCard"
                },
                {
                    name: "Patta Passbook",
                    url: "",
                    label: "DirectFarmer_PattaPassbook"
                },
                {
                    name: "Income Certificate",
                    url: "",
                    label: "DirectFarmer_IncomeCertificate"
                },
                {
                    name: "6 month bank statement savings account till date",
                    url: "",
                    label: "DirectFarmer_6MonthBSSavings"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "DirectFarmer_PropertyDocuments"
                }
            ],
            "Pensioner": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "DirectPensioner_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "DirectPensioner_PanCard"
                },
                {
                    name: "PPO letter",
                    url: "",
                    label: "DirectPensioner_PPO"
                },
                {
                    name: "Pension payslip of past 3 months",
                    url: "",
                    label: "DirectPensioner_3MonthPayslip"
                },
                {
                    name: "6 month bank statement savings account till date",
                    url: "",
                    label: "DirectPensioner_6MonthBSSavings"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "DirectPensioner_PropertyDocuments"
                }
            ],
            "Avanse Global": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "AvanseGlobal_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "AvanseGlobal_PanCard"
                },
                {
                    name: "PermanentAddress Proof",
                    url: "",
                    label: "AvanseGlobal_PermanentAddressProof"
                }
            ]
        },
        "InDirect": {
            "Salaried": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "InDirectSalaried_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "InDirectSalaried_PanCard"
                },
                {
                    name: "Last 2 Year Form 16",
                    url: "",
                    label: "InDirectSalaried_Form16"
                },
                {
                    name: "Last 3 month payslip",
                    url: "",
                    label: "InDirectSalaried_3MonthPayslip"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "InDirectSalaried_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Salary Account",
                    url: "",
                    label: "InDirectSalaried_6MonthBSSalary"
                },
                {
                    name: "Permanent Address Proof",
                    url: "",
                    label: "InDirectSalaried_PermanentAddProof"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "InDirectSalaried_PropertyDocuments"
                }
            ],
            "Self Employed - Professional": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_PanCard"
                },
                {
                    name: "Last 2 year ITR",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_2YearITR"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Current Account",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_6MonthBSCurrent"
                },
                {
                    name: "AOA",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_AOA"
                },
                {
                    name: "MOA",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_MOA"
                },
                {
                    name: "COI",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_COI"
                },
                {
                    name: "Company Pan",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_CompanyPan"
                },
                {
                    name: "Business Proof",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_BusinessProof"
                },
                {
                    name: "1st Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_1stPic"
                },
                {
                    name: "2nd Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_2ndPic"
                },
                {
                    name: "3rd Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_3rdPic"
                },
                {
                    name: "4th Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_4thPic"
                },
                {
                    name: "Permanent Address Proof",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_PermanentAddProof"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "InDirectSelfEmployedProfessional_PropertyDocuments"
                }
            ],
            "Self Employed - Non Professional": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_PanCard"
                },
                {
                    name: "Last 2 year ITR",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_ITR"
                },
                {
                    name: "Last 6 month Bank statement Savings Account",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_6MonthBSSavings"
                },
                {
                    name: "Last 6 month Bank statement Current Account",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_6MonthBSCurrent"
                },
                {
                    name: "Business Proof",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_BusinessProof"
                },
                {
                    name: "1st Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_1stPic"
                },
                {
                    name: "2nd Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_2ndPic"
                },
                {
                    name: "3rd Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_3rdPic"
                },
                {
                    name: "4th Picture of Business",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_4thPic"
                },
                {
                    name: "Permanent Address Proof",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_PermanentAddProof"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "InDirectSelfEmployedNonProfessional_PropertyDocuments"
                }
            ],
            "Farmer": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "InDirectFarmer_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "InDirectFarmer_PanCard"
                },
                {
                    name: "Patta Passbook",
                    url: "",
                    label: "InDirectFarmer_PattaPassbook"
                },
                {
                    name: "Income Certificate",
                    url: "",
                    label: "InDirectFarmer_IncomeCertificate"
                },
                {
                    name: "6 month bank statement savings account till date",
                    url: "",
                    label: "InDirectFarmer_6MonthBSSavings"
                },
                {
                    name: "Permanent Address Proof",
                    url: "",
                    label: "InDirectFarmer_PermanentAddProof"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "InDirectFarmer_PropertyDocuments"
                }
            ],
            "Pensioner": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "InDirectPensioner_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "InDirectPensioner_PanCard"
                },
                {
                    name: "PPO letter",
                    url: "",
                    label: "InDirectPensioner_PPO"
                },
                {
                    name: "Pension payslip of past 3 months",
                    url: "",
                    label: "InDirectPensioner_3MonthPayslip"
                },
                {
                    name: "6 month bank statement savings account till date",
                    url: "",
                    label: "InDirectPensioner_6MonthBSSavings"
                },
                {
                    name: "Permanent Address Proof",
                    url: "",
                    label: "InDirectPensioner_PermanentAddProof"
                },
                {
                    name: "Property Documents",
                    url: "",
                    label: "InDirectPensioner_PropertyDocuments"
                }
            ],
            "Avanse Global": [
                {
                    name: "Aadhar Card",
                    url: "",
                    label: "AvanseGlobal_AadharCard"
                },
                {
                    name: "Pan Card",
                    url: "",
                    label: "AvanseGlobal_PanCard"
                },
                {
                    name: "PermanentAddress Proof",
                    url: "",
                    label: "AvanseGlobal_PermanentAddressProof"
                }
            ]
        },
        "AbroadCoApplicant": [
            {
                name: "Abroad Address Proof",
                url: "",
                label: "AbroadCoApplicant_AbroadAddressProof"
            },
            {
                name: "Passport",
                url: "",
                label: "AbroadCoApplicant_Passport"
            },
            {
                name: "Visa",
                url: "",
                label: "AbroadCoApplicant_Visa"
            },
            {
                name: "Last 6 Months Abroad Salary Account Statement",
                url: "",
                label: "AbroadCoApplicant_SixMonthAbroadBankStatement"
            },
            {
                name: "Experian Report",
                url: "",
                label: "AbroadCoApplicant_ExperianReport"
            }
        ],
        "NonFinancial": [
            {
                name: "Aadhar Card",
                url: "",
                label: "NonFinancial_AadharCard"
            },
            {
                name: "Pan Card",
                url: "",
                label: "NonFinancial_PanCard"
            },
            {
                name: "Permanent Address Proof",
                url: "",
                label: "NonFinancial_AddressProof"
            }
        ],
        "LocationGuarantor": [
            {
                name: "Aadhar Card",
                url: "",
                label: "LocationGuarantor_AadharCard"
            },
            {
                name: "Pan Card",
                url: "",
                label: "LocationGuarantor_PanCard"
            },
            {
                name: "Permanent Address Proof",
                url: "",
                label: "LocationGuarantor_AddressProof"
            }
        ],
        "DeadFather": [
            {
                name: "Death Certificate",
                url: "",
                label: "DeadFather_DeathCertificate"
            }
        ],
        "SeparatedFather": [
            {
                name: "Divorce Certificate",
                url: "",
                label: "SeparatedFather_DivorceCertificate"
            }
        ],
        "DeadMother": [
            {
                name: "Death Certificate",
                url: "",
                label: "DeadMother_DeathCertificate"
            }
        ],
        "SeparatedMother": [
            {
                name: "Divorce Certificate",
                url: "",
                label: "SeparatedMother_DivorceCertificate"
            }
        ]
    }
    return documentMap
}


export default getDocumentMap;