import React, { useContext, useEffect, useState } from "react";
import Styles from "./SbpPurchaseRecords.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall, IoDocument, IoInformation, IoInformationCircleSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import moment from "moment";

const SbpPurchaseRecords = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)

        let objStatus = ""
        let temp = []
        let temp2 = []

        switch (newValue) {
            case 0: {
                objStatus = "Operations"
                break
            }
            case 1: {
                objStatus = "Link Sent"
                break
            }
            case 2: {
                objStatus = "Policy Generated"
                break
            }
            default: {}
        }

        setLoading(true)
        onValue(ref(firebaseDatabase, `HI_SBP_APPLICATIONS`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if ((userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2" || userData.uid === "vuc57CqdO8dJCCXFtiQtJoH9VD53") && item.planDetail.name.includes("Prima") && item.status === objStatus) {
                        temp.push(item)
                    } 
                    if (userData.uid === "0uH2KMkUD7TlfEV3yXL7ZfQGEbh2" && item.planDetail.name === "SC Gold Plus" && item.status === objStatus) {
                        temp.push(item)
                    }
                    if (userData.position === "Manager" && item.status === objStatus) {
                        temp.push(item)
                    }
                    
                }



                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
        
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>New Leads</h1>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="New" {...a11yProps(0)} />
                    <Tab label="Link Sent" {...a11yProps(1)} />
                    <Tab label="Policy Generated" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Coverage Start Date</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Travel Date</TableCell>
                                        {
                                            value !== 0
                                            &&
                                            <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Payment Proof</TableCell>
                                        }
                                        {
                                            value === 2
                                            &&
                                            <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Policy Doc</TableCell>
                                        }
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.sentToOperationsDate?item.sentToOperationsDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.coverageStartDate} days</TableCell>
                                                    <TableCell>{item.travelDate}</TableCell>
                                                    {
                                                        value !== 0
                                                        &&
                                                        <>
                                                            {
                                                                item.paymentProofDoc
                                                                ?
                                                                <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.paymentProofDoc, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                                                :
                                                                <TableCell>-</TableCell>
                                                            }
                                                        </>
                                                        
                                                    }
                                                    {
                                                        value === 2
                                                        &&
                                                        <>
                                                            {
                                                                item.policyDoc
                                                                ?
                                                                <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.policyDoc, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                                                :
                                                                <TableCell>-</TableCell>
                                                            }
                                                        </>
                                                    }
                                                    <TableCell><IoInformationCircleSharp size={20} color="#352F6C" onClick={()=>{navigate("/health-insurance/sbp-purchase-record/sbp-purchase-detail", {state:{item:item}})}} style={{cursor:"pointer"}} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default SbpPurchaseRecords