import React, { useContext, useEffect, useState } from "react";
import Styles from "./OperationsCompleteDetailIncognito.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, MenuItem, Select, Snackbar, Tab, Tabs, Modal, Box, Input } from "@mui/material";
import UserContext from "../../context/UserData.context";
import relationMap from "../../workload/CoSignerRelationMap";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseAdmin, firebaseStorage } from "../../backend/firebaseHandler";
import moment from "moment";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import getDocumentMap from "../../workload/DocumentSet";
import { AiFillCloseSquare } from "react-icons/ai"
import InputContainer from "../InputContainer/InputContainer.component";
import getMailList from "../../workload/MailList";
import ProdigyUniversityList from "../../workload/ProdigyUniversityList";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete.component";

const OperationsCompleteDetailIncognito = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [application, setApplication] = useState(location.state.item)
    const [caseDetail, setCaseDetail] = useState(application.caseDetail)
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const [examsWritten, setExamsWritten] = useState([])

    const [managerList, setManagerList] = useState([])
    const [selectedManager, setSelectedManager] = useState({})

    const [docNameModal, setDocNameModal] = useState(false)
    const [docName, setDocName] = useState("")
    const [managerModal, setManagerModal] = useState(false)

    const [pincodeModal, setPincodeModal] = useState(false)
    const [pincode, setPincode] = useState("")
    const [selectedPincodeBank, setSelectedPincodeBank] = useState("")

    const [ubListModal, setUbListModal] = useState(false)
    const [selectedListBank, setSelectedListBank] = useState("")
    const [searchWord, setSearchWord] = useState("")
    const [matchingResult, setMatchingResult] = useState([])
    const [mailList, setMailList] = useState(getMailList())
    const [prodigyModal, setProdigyModal] = useState(false)
    const [prodigyUniversity, setProdigyUniversity] = useState("")
    const [prodigyResultObject, setProdigyResultObject] = useState({})
    const [prodigySchool, setProdigySchool] = useState("")
    
    useEffect(() => {
        if (application.examsWritten) {
            setExamsWritten(application.examsWritten)
        }

        if (application.mailList) {
            setMailList(application.mailList)
        }else {
            setMailList(getMailList());
        }
        let tempListObj = []
        onValue(ref(firebaseDatabase, `NBFC_LIST`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let tempObj = snap.child(key).val()
                    if (tempObj.nbfcUserType === "Manager" && tempObj.nbfcName === application.incognitoType) {
                        let rmObj = {
                            "assignedNbfcManagerName": tempObj.name,
                            "assignedNbfcManagerPhoneNumber": tempObj.phoneNumber,
                            "assignedNbfcManagerUid": tempObj.uid
                        }
                        tempListObj.push(rmObj)
                    }
                }
                setManagerList([...tempListObj])
            }
        }, { onlyOnce: true })

        if (application.status === "Operations") {
            let dbRef = ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`)
            onValue(dbRef, (snap) => {
                if (snap.exists()) {

                } else {
                    navigate(-1)
                }
            }, { onlyOnce: false })

            return () => {
                off(dbRef, "value")
            }
        }

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleCoApplicantSelect = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicant = value
        tempObj.coApplicantProfession = ""
        tempObj.coApplicantAge = ""
        tempObj.coApplicantDocs = null
        tempObj.whyFatherNotFin = ""
        tempObj.whyMotherNotFin = ""
        tempObj.nonFinancialCoApplicants = [...relationMap[value]]

        let tempDocs = []
        for (const index in tempObj.nonFinancialCoApplicants) {
            tempDocs.push(getDocumentMap()["NonFinancial"])
        }

        tempObj.nonFinancialDocs = [...tempDocs]
        setApplication({ ...tempObj })
    }

    const handleProfession = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicantProfession = value

        let searchWord = ""

        if (["Father", "Mother", "Spouse", "Father-in-law", "Mother-in-law", "Sister", "Brother"].includes(application.coApplicant)) {
            searchWord = "Direct"
        } else {
            searchWord = "InDirect"
        }
        tempObj.coApplicantDocs = [...getDocumentMap()[searchWord][value]]
        setApplication({ ...tempObj })
    }

    const handleWorkExperience = (value) => {
        setApplication({ ...application, hasWorkExperience: value })

        let tempObj = { ...application }
        tempObj.hasWorkExperience = value

        if (value === "Yes") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentWorking"]]
        } else if (value === "No") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentNonWorking"]]
        } else {
            tempObj.applicantDocs = [...getDocumentMap()["StudentUsedToWork"]]
        }

        setApplication({ ...tempObj })
    }

    const handleFatherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyFatherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["SeparatedFather"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["DeadFather"]]
        }

        setApplication({ ...tempObj })
    }

    const handleMotherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyMotherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["SeparatedMother"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["DeadMother"]]
        }

        setApplication({ ...tempObj })
    }

    const handleExams = (value) => {
        setExamsWritten(value)

        let tempArray = []
        let tempObj = { ...application }
        for (const index in value) {
            let obj = {
                name: value[index],
                url: "",
                label: value[index]
            }
            tempArray.push(obj)
        }
        tempObj["examDocs"] = [...tempArray]
        setApplication({ ...tempObj })
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleNestedDocumentUpload = (item, folderName, index, index2, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index][index2] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handleChangeNF = (value, label, index) => {
        let array = [...caseDetail.nfInformation]
        let obj = { ...array[index] }
        obj[label] = value
        array[index] = { ...obj }
        caseDetail.nfInformation = [...array]

        setCaseDetail({ ...caseDetail })
    }

    const handleSave = async () => {
        setSubmitting(true)
        application.caseDetail = { ...caseDetail }
        application.examsWritten = [...examsWritten]

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                return
            }
        }
        await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${application.assignedAdvisorUid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
        setSubmitting(false)
    }

    const handleRecheck = async () => {
        setSubmitting(true)
        application.caseDetail = { ...caseDetail }
        application.examsWritten = [...examsWritten]
        application.subStatus = "Re-check"
        application.operationRecheckDate = moment().format("DD-MM-YYYY h:mm a")

        await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${application.assignedAdvisorUid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Operations",
                    subStatus: "Re-check",
                    date: application.operationRecheckDate,
                    ref: "INCOGNITO_WITH_OPERATIONS_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                setApplication({ ...application })
                setSnackBar({ visibility: true, severity: "success", message: "Advisor notified!" })
                setTimeout(() => {
                    setSubmitting(false)
                    navigate(-1)
                    
                }, 3000)
            }
        }, { onlyOnce: true })
    }

    const handleSendToVendors = async () => {

        if (!selectedManager.assignedNbfcManagerName) {
            alert("Please select a manager first.")
            return
        }

        if (application.incognitoType === "" || !application.incognitoType === "") {
            alert("Something doesn't seem right")
            return
        }

        setSubmitting(true)

        
        application.caseDetail = { ...caseDetail }
        let tempObj = { ...application }

        tempObj.status = "Vendor Status"
        tempObj.subStatus = "New"
        tempObj.sentToVendorDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.examsWritten = [...examsWritten]

        // // Potential Cross-Sale Leads
        // const potentialCrossSaleObject = { ...tempObj };
        // await writePotentialCrossSale(potentialCrossSaleObject);

        onValue(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                alert ("Sorry! This file is already with Vendor via SC Calling.")
            } else {
                await set(ref(firebaseDatabase, `INCOGNITO_WITH_VENDORS_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_VENDORS_LEADS/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj);

                let nbfcStatusObj = {
                    uid: application.incognitoType,
                    name: application.incognitoType,
                    status: "Pool",
                    subStatus: "",
                    roi: "",
                    pf: "",
                    insuranceOffered: "",
                    sanctionedAmount: "",
                    comment: "",
                    appId: "",
                    statusDate: tempObj.sentToVendorDate,
                    assignedNbfcManagerName: selectedManager.assignedNbfcManagerName,
                    assignedNbfcManagerPhoneNumber: selectedManager.assignedNbfcManagerPhoneNumber,
                    assignedNbfcManagerUid: selectedManager.assignedNbfcManagerUid,
                    assignedRmName: selectedManager.assignedNbfcManagerName,
                    assignedRmPhoneNumber: selectedManager.assignedNbfcManagerPhoneNumber,
                    assignedRmUid: selectedManager.assignedNbfcManagerUid,
                    type: "Incognito"
                }

                let vendorFileObject = {}
                vendorFileObject.assignedAdvisorUid = tempObj.assignedAdvisorUid
                vendorFileObject.assignedAdvisorName = tempObj.assignedAdvisorName
                vendorFileObject.assignedAdvisorPhoneNumber = tempObj.assignedAdvisorPhoneNumber
                vendorFileObject.assignedNbfcManagerName = selectedManager.assignedNbfcManagerName
                vendorFileObject.assignedNbfcManagerUid = selectedManager.assignedNbfcManagerUid
                vendorFileObject.assignedNbfcManagerPhoneNumber = selectedManager.assignedNbfcManagerPhoneNumber
                vendorFileObject.assignedRmName = selectedManager.assignedNbfcManagerName
                vendorFileObject.assignedRmPhoneNumber = selectedManager.assignedNbfcManagerPhoneNumber
                vendorFileObject.assignedRmUid = selectedManager.assignedNbfcManagerUid
                vendorFileObject.status = "Vendor Status"
                vendorFileObject.subStatus = "New"
                vendorFileObject.sentToVendorDate = moment().format("DD-MM-YYYY h:mm a")
                vendorFileObject.name = tempObj.name
                vendorFileObject.phoneNumber = tempObj.phoneNumber
                vendorFileObject.emailId = tempObj.emailId
                vendorFileObject.customerId = tempObj.customerId
                vendorFileObject.panNumber = tempObj.caseDetail.panNumber
                vendorFileObject.passportUrl = tempObj.applicantDocs[2].url
                vendorFileObject.key = tempObj.key
                vendorFileObject.type = "Incognito"

                await set(ref(firebaseDatabase, `VENDOR_FILE_POOL/${nbfcStatusObj.uid}/${tempObj.phoneNumber}`), vendorFileObject)
            
                onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                    if (snap.exists()) {
                        let lifelineObj = snap.val()
                        lifelineObj.status = "Vendor Status"
                        let array = [...lifelineObj.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Vendor Status",
                            subStatus: "New",
                            date: tempObj.sentToVendorDate,
                            ref: "INCOGNITO_WITH_VENDORS_LEADS"
                        }
                        array.push(arrayItem)
                        lifelineObj.lifeline = [...array]
                        await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                        await set(ref(firebaseDatabase, `INCOGNITO_NBFC_LEAD_STATUS/${tempObj.phoneNumber}/${application.incognitoType}`), nbfcStatusObj)
                        await remove(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${tempObj.assignedAdvisorUid}/${tempObj.phoneNumber}`))
                        await remove(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${tempObj.phoneNumber}`))
                        setSubmitting(false)
                        setSnackBar({ visibility: true, severity: "success", message: "Lead sent to Vendors!" })
                        setTimeout(() => {
                            setSubmitting(false)
                            navigate(-1)
                        }, 1500)
                    }
                }, { onlyOnce: true })
            }
        }, {onlyOnce:true})

        
    }

    const handleSaveForVendor = async () => {
        setSubmitting(true)
        application.caseDetail = { ...caseDetail }
        application.examsWritten = [...examsWritten]

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                return
            }
        }
        await set(ref(firebaseDatabase, `INCOGNITO_WITH_VENDORS_LEADS/${application.assignedAdvisorUid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_VENDORS_LEADS/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
        setSubmitting(false)
    }

    const handleLGDocs = (value) => {
        let tempObj = { ...application }

        tempObj.hasOwnHouse = value
        if (value === "No") {
            tempObj["locationGuarantorDocs"] = [...getDocumentMap()["LocationGuarantor"]]
        } else {
            tempObj["locationGuarantorDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleCurrentCountry = (value) => {
        let tempObj = { ...application }

        tempObj.currentlyInIndia = value
        if (value === "No") {
            tempObj["abroadDocs"] = [...getDocumentMap()["InAbroad"]]
        } else {
            tempObj["abroadDocs"] = []
        }
        setApplication({ ...tempObj })
    }

    const handleCoAppLocation = (value) => {
        let tempObj = { ...application }

        tempObj.coApplicantInIndia = value
        if (value === "No") {
            tempObj["abroadCoAppDocs"] = [...getDocumentMap()["AbroadCoApplicant"]]
        } else {
            tempObj["abroadCoAppDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleDocumentNameAdd = () => {
        if (docName.includes("/") || docName.includes("$") || docName.includes("[") || docName.includes("]") || docName.includes("#") || docName.includes(".")) {
            setSnackBar({ visibility: true, severity: "warning", message: "Sorry. File name can not include `/`, `.`, `[`, `]`, `#`, `$`" })
            return
        }
        let tempObj = { ...application }
        let addDocArray = tempObj.additionalDocuments ? tempObj.additionalDocuments : []
        let docObject = {
            name: docName,
            label: docName,
            url: ""
        }

        addDocArray.push(docObject)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
        setDocName("")
    }

    const handleRemoveAdditionalDoc = (item, index) => {
        let tempObj = { ...application }
        let addDocArray = [...tempObj.additionalDocuments]
        addDocArray.splice(index, 1)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
    }

    const handleCheckPincode = () => {

        let dbRef = null
        if (selectedPincodeBank === "Credila") {
            dbRef = ref(firebaseDatabaseAdmin, `CREDILA_PINCODES/${pincode}`)
        } else if (selectedPincodeBank === "IDFC") {
            dbRef = ref(firebaseDatabaseAdmin, `IDFC_PINCODES/${pincode}`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                alert("Area functional.")
            } else {
                alert("Area not functional.")
            }
        }, { onlyOnce: true })
    }

    const handleCheckUniversity = () => {
        let arr = []

        let dbRef = null

        if (selectedListBank === "Union Bank") {
            dbRef = ref(firebaseDatabaseAdmin, `UNION_BANK_LIST`)
        } else if (selectedListBank === "Avanse (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_STEM_LIST`)
        } else if (selectedListBank === "Avanse (MBA)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_MBA_LIST`)
        } else if (selectedListBank === "Axis MS (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_MS_STEM`)
        } else if (selectedListBank === "Axis Other Master (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_OTHER_MASTER_STEM`)
        } 

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    if (snap.child(key).val().toLowerCase().includes(searchWord.toLowerCase())) {
                        arr.push(snap.child(key).val())
                    }
                }
                setMatchingResult(arr)
            }
        }, { onlyOnce: true })
    }

    const handleProdigyUniversity = (value) => {
        setProdigySchool("")
        setProdigyUniversity(value)
        onValue(ref(firebaseDatabaseAdmin, `PRODIGY_SUPPORTED_UNIVERSITIES/${value}`), (snap) => {
            if (snap.exists()) {
                setProdigyResultObject(snap.val())
            } else {
                alert("Something went wrong")
            }
        }, {onlyOnce:true})
    }

    return (
        <div>
            <div className={Styles.headerContainer}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:15}}>
                    <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>
                    <p style={{backgroundColor:"#c1ff8f", color:"#51b302", padding:"0 15px", borderRadius:5, fontWeight:600}}>{application.incognitoType}</p>
                </div>

                {/* <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={application.phoneNumber} viewBox={`0 0 256 256`} />
                    </div>
                </div>   */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Case Details" {...a11yProps(1)} />
                    <Tab label="Applicant Docs" {...a11yProps(2)} />
                    <Tab label="Co-Applicant Docs" {...a11yProps(3)} />
                    <Tab label="Non Financial Docs" {...a11yProps(4)} />
                </Tabs>
            </div>

            <div className={Styles.formListContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={"91XXXXXXXXX"} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Level of Education</p>
                            <Select style={{ width: "100%" }} value={application.levelOfStudy} onChange={(event) => { setApplication({ ...application, levelOfStudy: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Double Master">Double Master</MenuItem>
                                <MenuItem value="PHD">PHD</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Currently in India?</p>
                            <Select style={{ width: "100%" }} value={application.currentlyInIndia} onChange={(event) => { handleCurrentCountry(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Abroad Phone Number</p>
                            <input value={application.abroadPhoneNumber} onChange={(event) => { setApplication({ ...application, abroadPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Abroad Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} placeholder="Desired Country" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University of Choice</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} placeholder="Desired University" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Entrance Exams written</p>
                            <Select multiple style={{ width: "100%" }} value={examsWritten} onChange={(event) => { handleExams(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="GRE">GRE</MenuItem>
                                <MenuItem value="TOFEL">TOFEL</MenuItem>
                                <MenuItem value="IELTS">IELTS</MenuItem>
                                <MenuItem value="Duolingo">Duolingo</MenuItem>
                                <MenuItem value="PTE">PTE</MenuItem>
                                <MenuItem value="Other Exam">Other Exam</MenuItem>
                            </Select>
                        </div>

                        <InputContainer value={application.requiredLoanAmount} onChange={(event) => { setApplication({ ...application, requiredLoanAmount: event.target.value }) }} className={Styles.inputField} placeholder="Loan amount required" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.yesNoContainer}>
                            <p>Have you applied to any Bank?</p>
                            <p style={{ color: application.hasPreAppliedToBank === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasPreAppliedToBank === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "No" }) }}>No</p>
                        </div>

                        <InputContainer value={application.preAppliedBank} readOnly={application.hasPreAppliedToBank !== "Yes"} onChange={(event) => { setApplication({ ...application, preAppliedBank: event.target.value }) }} className={Styles.inputField} placeholder="Bank Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Work experience?</p>
                            <Select style={{ width: "100%" }} value={application.hasWorkExperience} onChange={(event) => { handleWorkExperience(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Currently Working</MenuItem>
                                <MenuItem value="Past">Used to work</MenuItem>
                                <MenuItem value="No">No work experience</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.yesNoContainer}>
                            <p>Have your own house?</p>
                            <p style={{ color: application.hasOwnHouse === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("Yes") }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasOwnHouse === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("No") }}>No</p>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Who will be your co-applicant?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicant} onChange={(event) => { handleCoApplicantSelect(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    Object.keys(relationMap).map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        {
                            application.coApplicant !== ""
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Profession?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantProfession} onChange={(event) => { handleProfession(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Salaried">Salaried</MenuItem>
                                        <MenuItem value="Self Employed - Professional">Self Employed - Professional</MenuItem>
                                        <MenuItem value="Self Employed - Non Professional">Self Employed - Non Professional</MenuItem>
                                        <MenuItem value="Farmer">Farmer</MenuItem>
                                        <MenuItem value="Pensioner">Pensioner</MenuItem>
                                        <MenuItem value="Avanse Global">Avanse Global</MenuItem>
                                    </Select>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Age?</p>
                                    <input value={application.coApplicantAge} onChange={(event) => { setApplication({ ...application, coApplicantAge: event.target.value }) }} className={Styles.inputField} placeholder="Co-applicant's Age" InputProps={{ disableUnderline: true }} variant="standard" />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant in India?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantInIndia} onChange={(event) => { handleCoAppLocation(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </div>
                            </>
                        }

                        <div style={{ gridColumn: "1/4" }}>
                            {
                                relationMap[application.coApplicant].map((item, index) => {
                                    return (
                                        <div className={Styles.nonFinListContainer}>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label} style={{ color: "rgba(0,0,0,0)" }}>Number</p>
                                                <input value={"Non Financial Applicant " + (index + 1)} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label}>Relation</p>
                                                <input value={item} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            {
                                                item === "Father" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyFatherNotFin} onChange={(event) => { handleFatherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                            {
                                                item === "Mother" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyMotherNotFin} onChange={(event) => { handleMotherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {
                            application.isSpecialCase
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>This has been marked as a Special Case</p>
                                <input value={application.specialCaseDescription} readOnly className={Styles.inputFieldSpecial} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }
                    </div>
                }

                {
                    value === 1
                    &&
                    <>
                        <p className={Styles.cdTabTitle}>Student Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={application.phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.fatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, fatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.motherName} onChange={(event) => { setCaseDetail({ ...caseDetail, motherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.requiredLoanAmount} readOnly className={Styles.inputField} placeholder="Loan Amount Required" InputProps={{ disableUnderline: true }} variant="standard" />

                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Total cost of education</p>
                                <input value={caseDetail.costOfEducation} onChange={(event) => { setCaseDetail({ ...caseDetail, costOfEducation: event.target.value }) }} className={Styles.inputField} placeholder="Total cost of education" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Marital Status</p>
                                <Select style={{ width: "100%" }} value={caseDetail.maritalStatus} onChange={(event) => { setCaseDetail({ ...caseDetail, maritalStatus: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                </Select>
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Pan Number</p>
                                <input value={caseDetail.panNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, panNumber: event.target.value }) }} className={Styles.inputField} placeholder="Pan Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.currentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, currentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.permanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, permanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Academic Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.tenth} onChange={(event) => { setCaseDetail({ ...caseDetail, tenth: event.target.value }) }} className={Styles.inputField} placeholder="10th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.twelfth} onChange={(event) => { setCaseDetail({ ...caseDetail, twelfth: event.target.value }) }} className={Styles.inputField} placeholder="12th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.graduation} onChange={(event) => { setCaseDetail({ ...caseDetail, graduation: event.target.value }) }} className={Styles.inputField} placeholder="Graduation" InputProps={{ disableUnderline: true }} variant="standard" />

                            {
                                application.examsWritten
                                &&
                                application.examsWritten.map((item, index) => {
                                    return (
                                        <InputContainer value={caseDetail[item]} onChange={(event) => { setCaseDetail({ ...caseDetail, [item]: event.target.value }) }} className={Styles.inputField} placeholder={item} InputProps={{ disableUnderline: true }} variant="standard" />
                                    )
                                })
                            }

                        </div>

                        <p className={Styles.cdTabTitle}>Reference Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.referenceName} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceName: event.target.value }) }} style={{ gridColumn: "1/2" }} className={Styles.inputField} placeholder="Student Reference Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceNumber: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.referenceAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Student Reference Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Co-Applicant Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.coAppName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppName: event.target.value }) }} className={Styles.inputField} placeholder="Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppEmailId} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppEmailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppFatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppFatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppMotherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppMotherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppQualification} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppQualification: event.target.value }) }} className={Styles.inputField} placeholder="Qualification" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppNoOfDependents} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppNoOfDependents: event.target.value }) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppYearsInCurrentHouse} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentHouse: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppCurrentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppCurrentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppYearsInCurrentOffice} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentOffice: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current office" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "2/4" }}>
                                <InputContainer value={caseDetail.coAppOfficeAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppOfficeAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Office Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppRefName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefName: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{ gridColumn: "1/4" }}>
                                <InputContainer value={caseDetail.coAppRefPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Reference's Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Non Financial Applicant Detail</p>
                        <div className={Styles.subFormContainer}>
                            {
                                application.nonFinancialCoApplicants.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                item === "Father" && (application.whyFatherNotFin === "Divorced" || application.whyFatherNotFin === "Deceased")
                                                    ?
                                                    null
                                                    :
                                                    item === "Mother" && (application.whyMotherNotFin === "Divorced" || application.whyMotherNotFin === "Deceased")
                                                        ?
                                                        null
                                                        :
                                                        <>
                                                            <p className={Styles.nfRelationLabel}>{item}</p>
                                                            <div className={Styles.formContainer} style={{ marginBottom: 20 }}>
                                                                <InputContainer value={caseDetail.nfInformation[index].name} onChange={(event) => { handleChangeNF(event.target.value, "name", index) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].phoneNumber} onChange={(event) => { handleChangeNF(event.target.value, "phoneNumber", index) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].emailId} onChange={(event) => { handleChangeNF(event.target.value, "emailId", index) }} className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <InputContainer value={caseDetail.nfInformation[index].fatherName} onChange={(event) => { handleChangeNF(event.target.value, "fatherName", index) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].motherName} onChange={(event) => { handleChangeNF(event.target.value, "motherName", index) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].qualification} onChange={(event) => { handleChangeNF(event.target.value, "qualification", index) }} className={Styles.inputField} placeholder="Occupation" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <InputContainer value={caseDetail.nfInformation[index].noOfDependents} onChange={(event) => { handleChangeNF(event.target.value, "noOfDependents", index) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                <InputContainer value={caseDetail.nfInformation[index].noOfYearInCurrentHouse} onChange={(event) => { handleChangeNF(event.target.value, "noOfYearInCurrentHouse", index) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                                                                <div style={{ gridColumn: "1/4" }}>
                                                                    <InputContainer value={caseDetail.nfInformation[index].currentAddress} onChange={(event) => { handleChangeNF(event.target.value, "currentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                </div>

                                                                <div style={{ gridColumn: "1/4" }}>
                                                                    <InputContainer value={caseDetail.nfInformation[index].permanentAddress} onChange={(event) => { handleChangeNF(event.target.value, "permanentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                                </div>
                                                            </div>
                                                        </>
                                            }

                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                !application.applicantDocs && !application.examDocs && !application.currentlyInIndia
                                    ?
                                    <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                                    :
                                    application.applicantDocs
                                        ?
                                        application.applicantDocs.map((item, index) => {
                                            return (
                                                <div className={Styles.documentItem}>
                                                    <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                    <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                </div>
                                            )
                                        })
                                        :
                                        null
                            }

                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.abroadDocs
                                &&
                                application.abroadDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "abroadDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.examDocs
                                &&
                                application.examDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "examDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 3
                    &&
                    <div className={Styles.documentListContainer}>
                        {
                            application.coApplicantDocs
                                ?
                                application.coApplicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "coApplicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                        {
                            application.abroadCoAppDocs
                            &&
                            application.abroadCoAppDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "abroadCoAppDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    value === 4
                    &&
                    <div>
                        {
                            application.nonFinancialCoApplicants
                                ?
                                <>
                                    {
                                        application.nonFinancialCoApplicants.map((item, index) => {
                                            return (
                                                <div>
                                                    <p className={Styles.nonFinRelationLabel}>{item}</p>

                                                    <div className={Styles.documentListContainer}>
                                                        {
                                                            application.nonFinancialDocs[index].map((item2, index2) => {
                                                                return (
                                                                    <div className={Styles.documentItem}>
                                                                        <p onClick={() => { handleNestedDocumentUpload(item2, "Non Financial Applicant Docs", index, index2, "nonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div>
                                        <p className={Styles.nonFinRelationLabel}>Location Guarantor Documents</p>
                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.locationGuarantorDocs
                                                    ?
                                                    application.locationGuarantorDocs.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Location Guarantor Docs", index, "locationGuarantorDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        application.status === "Vendor Status"
                                        &&
                                        <div>
                                            <p className={Styles.nonFinRelationLabel}>Consent Mail</p>
                                            <div className={Styles.documentListContainer}>
                                                {
                                                    mailList.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <p className={Styles.nonFinRelationClickableLabel} onClick={() => { setDocNameModal(true) }}>Additional Documents</p>

                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.additionalDocuments
                                                    ?
                                                    application.additionalDocuments.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Additional Docs", index, "additionalDocuments") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                <AiFillCloseSquare color="red" size={20} style={{ cursor: "pointer" }} onClick={() => { handleRemoveAdditionalDoc(item, index) }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                    </div>
                }

            </div>

            <div className={Styles.buttonsTypesContainer}>
                {
                    application.outstandingDate
                    ?
                    null
                    :
                    <>
                        {
                            application.status === "Operations"
                            &&
                            <>
                                <div className={Styles.interestedButtonContainer}>
                                    <InputContainer value={application.noteFromOperations} onChange={(event) => { setApplication({ ...application, noteFromOperations: event.target.value }) }} placeholder="Please specify the problem with the file" className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                    <Button onClick={() => { handleRecheck() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Re-check</Button>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                                    <div>
                                        <p onClick={() => { setPincodeModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved Pincode</p>
                                        <p onClick={() => { setUbListModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved University</p>
                                        <p onClick={() => { setProdigyModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Prodigy Courses</p>
                                    </div>

                                    <div className={Styles.twoButtonContainer}>
                                        <Button onClick={() => { handleSave() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                                        <Button onClick={() => { setManagerModal(true) }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Send to Vendors</Button>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            application.status === "Vendor Status"
                            &&
                            <div className={Styles.singleButtonContainer}>
                                <Button onClick={() => { handleSaveForVendor() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                            </div>
                        }
                    </>
                }
            </div>

            {/* Additional Document Modal */}
            <Modal open={docNameModal} onClose={() => { setDocNameModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the Document Name below</p>
                        <input value={docName} onChange={(event) => { setDocName(event.target.value) }} className={Styles.inputField} placeholder="Document Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setDocNameModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleDocumentNameAdd() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={managerModal} onClose={() => { setManagerModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please select the managers to share the file with</p>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 30, width: "100%", flex: 1, justifyContent: "space-between" }}>
                            <p>{application.incognitoType}</p>
                            <Select style={{ width: 200 }} value={selectedManager.assignedNbfcManagerName} onChange={(event) => {setSelectedManager(event.target.value)}}>
                                {
                                    managerList
                                    &&
                                    managerList.map((item2, index2) => {
                                        return (
                                            <MenuItem key={index2} value={item2}>{item2.assignedNbfcManagerName}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setManagerModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleSendToVendors() }} variant='contained' className={Styles.primaryButton}>Send</Button>
                        </div>
                    </div>
                </Box>
            </Modal>


            <Modal open={pincodeModal} onClose={() => { setPincodeModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}  style={{ alignItems: "flex-start" }}>
                        <p>Select Bank</p>
                        <Select value={selectedPincodeBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedPincodeBank(event.target.value) }}>
                            {
                                ["IDFC"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop:30 }}>Please enter the student's pincode below</p>
                        <input value={pincode} onChange={(event) => { setPincode(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPincodeModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckPincode() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={ubListModal} onClose={() => { setUbListModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer} style={{ alignItems: "flex-start" }}>

                        <p>Select Bank</p>
                        <Select value={selectedListBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedListBank(event.target.value) }}>
                            {
                                ["Union Bank", "Avanse (STEM)", "Avanse (MBA)", "Axis MS (STEM)", "Axis Other Master (STEM)"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop: 30 }}>Please enter the student's university below</p>
                        <input readOnly={selectedListBank === ""} value={searchWord} onChange={(event) => { setSearchWord(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />


                        <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 30, color: "green" }}>Matching Result</p>
                        {
                            matchingResult.length === 0
                            ?
                            <p style={{color:"#ccc", fontStyle:"italic", fontWeight:500}}>No matching universities</p>
                            :
                            <>
                                <div style={{ display: "flex", flexDirection: 'row', gap: 10, maxWidth: 600, flexWrap: "wrap" }}>
                                    {
                                        matchingResult.slice(0, 5).map((item) => {
                                            return (
                                                <p style={{ backgroundColor: "#a6f7aa", padding: "2px 10px 2px 10px", borderRadius: 10, fontWeight: 400, color: "#02b50b" }}>{item}</p>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    matchingResult.length > 5
                                    &&
                                    <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 10, color: "green" }}>...and {matchingResult.length - 5} more!</p>
                                }
                            </>
                        }


                        <div className={Styles.buttonsContainer} style={{ width: "100%", justifyContent: "center" }}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setUbListModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckUniversity() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={prodigyModal} onClose={() => { setProdigyModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer} style={{ alignItems: "flex-start", justifyContent:"flex-start" }}>

                        <p>Select University</p>
                        <CustomAutoComplete
                            className={Styles.inputField}
                            label="Select University"
                            variant="standard"
                            value={prodigyUniversity}
                            InputProps={{ disableUnderline: true }}
                            onChange={(event, value) => {handleProdigyUniversity(value)}}
                            options={ProdigyUniversityList}
                        />  

                        {
                            prodigyUniversity !== ""
                            &&
                            <>
                                <p>Select School</p>
                                <Select value={prodigySchool} onChange={(event)=>{setProdigySchool(event.target.value)}} style={{width:'100%'}}>
                                    {
                                        Object.keys(prodigyResultObject).map((item, index) => {
                                            return(
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </>
                        }
                        

                        {
                            prodigySchool !== ""
                            &&
                            <>
                                <p>Courses Approved</p>
                                <Select style={{width:'100%'}}>
                                    {
                                        prodigyResultObject[prodigySchool]
                                        &&
                                        prodigyResultObject[prodigySchool].map((item, index) => {
                                            return(
                                                <MenuItem>{item}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </>
                        }

                        

                        <div className={Styles.buttonsContainer} style={{ width: "100%", justifyContent: "center" }}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => {setProdigyModal(false); setProdigySchool(""); setProdigyUniversity("") }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            {/* <Button onClick={() => { handleProdigyUniversity() }} variant='contained' className={Styles.primaryButton}>Fetch Courses</Button> */}
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default OperationsCompleteDetailIncognito