import axios from "axios";
import Endpoints from "./Endpoints";
const endpoint = Endpoints;

const sendWhatsappNotification = {
    actionMarkedInterested: async (phoneNumber, studentName, advisorName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, advisorName],
                templateId: "fc0da0e4-3510-4ec9-9338-4c7086c35a3e"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionMarkedRescheduled: async (phoneNumber, studentName, newTime) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, newTime],
                templateId: "fd068181-5f58-4cdf-9753-d2ec6954acc6"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionMarkedNoResponseAfterStudentInterested: async (phoneNumber, studentName, advisorName, advisorPhoneNumber) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, advisorName, advisorPhoneNumber],
                templateId: "5ece574d-6825-4323-83ca-d88e580849ea"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionLodComeplete: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "a5e31aa1-4044-4ec0-8311-3bc1d74e14de"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionLodVerified: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "6d060d1e-4e42-44cb-a8d3-7e5be6fe901e"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionCaseDetailSubmitted: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "d4ce9056-1ec9-4cd8-aab0-4db32b9d0b59"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionSentToVendors: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "1d508d09-d5d3-4534-94c1-286c4d122654"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionRecheckFromVendors: async (phoneNumber, studentName, vendorName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, vendorName],
                templateId: "811f05c6-c11e-4862-bfce-9f8537cf90a5"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionAcceptedByVendors: async (phoneNumber, studentName, vendorName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, vendorName],
                templateId: "3cd60a43-0397-435f-889a-154fca558ba8"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionRejectedByVendors: async (phoneNumber, studentName, vendorName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, vendorName],
                templateId: "b97dff95-b981-44b7-aea9-bd771c74dfba"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionConfirmVendor: async (phoneNumber, studentName, vendorName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, vendorName],
                templateId: "931bdc9a-6bec-4135-83e9-7340aca36ab4"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionSLUploaded: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "527114a4-f128-4fe3-a088-8d5f02045646"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionDisburementAmount: async (phoneNumber, studentName, destinationCountry) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName, destinationCountry],
                templateId: "447f3e94-bc33-422e-a0a3-241f51b64644"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    actionNoResponseRandomCalling: async (phoneNumber) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [],
                templateId: "b9074b88-fb4c-4f65-9f11-72c934003c87"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },
    
    // Added 16th July, 2024
    actionRecheckFromOperations: async (phoneNumber, fileId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [fileId],
                templateId: "451872ce-be5c-4b72-8846-c182e31f4f9f"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    resubmissionByAdvisor: async (phoneNumber, fileId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [fileId],
                templateId: "ec0142c3-4f62-4d6f-a3c1-5ca39f2c2b8d"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    fileReceivedInPool: async (phoneNumber) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [],
                templateId: "0b11d0d4-0533-40f9-a916-9ee059c1870b"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    fileReceivedByExecutive: async (phoneNumber, appId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [appId],
                templateId: "f1c7a4a1-fa6d-49a8-b5fb-0e8c93a7aedb"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    fileUpdatedByAdvisor: async (phoneNumber, appId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [appId],
                templateId: "9a93f1a4-ec72-4f66-a30d-d06c84349867"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    pfUploadedByStudentStudentNotified: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "16267db5-4f12-4b24-8401-b4ba362243f5"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    pfUploadedByStudentAdvisorNotified: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "87456f03-4b46-421a-a2a9-5eb756cf4813"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    pfUploadedByStudentVendorNotified: async (phoneNumber, studentName) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [studentName],
                templateId: "222cb9de-f391-43d3-aa4d-20b58725f22d"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    sentToVendorOperationToAdvisor: async (phoneNumber, customerId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [customerId],
                templateId: "9ea991f5-a895-401c-9c2c-941915fa46a8"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },

    recheckFromVendorsVendorToAdvisor: async (phoneNumber, appId) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [appId],
                templateId: "7198282e-07ff-42e6-b991-3ddb0beb9545"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },
    actionRecheckFromVendorForAdmin: async (phoneNumber, vendorName, studentName, studentPhoneNumber, advisorName, RMName, managerName, status) => {
        try {

            const requestURL = `${endpoint}/messaging/send-whatsapp-template`;
            const data = {
                phoneNumber,
                variables: [vendorName,studentName,studentPhoneNumber, advisorName, RMName, managerName ,status],
                templateId: "0747c362-1251-4760-9e1e-94477186fc7c"
            }
            const response = await axios.post(requestURL, data);
            const responseData = await response.data;
            return responseData;
        } catch (err) {
            return false
        }
    },
}

export default sendWhatsappNotification;