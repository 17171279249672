import React, { useEffect, useState } from "react";
import Styles from "./HiAdminCompleteLeadDetail.module.css"
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Tab, Tabs } from "@mui/material";
import InputContainer from "../InputContainer/InputContainer.component";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { toast } from "react-toastify";

const HiAdminCompleteLeadDetail = () => {

    const location = useLocation()

    const [application, setApplication] = useState(location.state.item);
    const [address, setAddress] = useState({ line1: "", line2: "", city: "", state: "", pincode: "" })
    const [value, setValue] = useState(0)

    useEffect(() => {
        let tempObj = { ...application }

        tempObj.coverageStartDate = (!tempObj.coverageStartDate || tempObj.coverageStartDate === "") ? "" : moment(tempObj.coverageStartDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.coverageEndDate = (!tempObj.coverageEndDate || tempObj.coverageEndDate === "") ? "" : moment(tempObj.coverageEndDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.dob = (!tempObj.dob || tempObj.dob === "") ? "" : moment(tempObj.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.nomineeDOB = (!tempObj.nomineeDOB || tempObj.nomineeDOB === "") ? "" : moment(tempObj.nomineeDOB, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.travelDate = (!tempObj.travelDate || tempObj.travelDate === "") ? "" : moment(tempObj.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "DD-MM-YYYY").format("YYYY-MM-DD")

        setApplication({ ...tempObj })

        if (tempObj.indiaAddress !== "") {
            let addressArray = tempObj.indiaAddress.split(", ")
            setAddress({ line1: addressArray[0], line2: addressArray[1], city: addressArray[2], state: addressArray[3], pincode: addressArray[4] })
        }

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleHealthDeclaration = () => {
        if (!application.name) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.travelDate) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.coverageStartDate) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.coverageEndDate) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.nomineeName) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.nomineeRelation) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }
        if (!application.nomineeDOB) {
            toast.warn("Sorry! Complete information to generate the Health Declaration is not yet available")
            return
        }

        let travelDate = moment(application.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let startDate = moment(application.coverageStartDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let endDate = moment(application.coverageEndDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        let nomineeDOB = moment(application.nomineeDOB, "YYYY-MM-DD").format("DD-MM-YYYY")
        let requestDate = moment().format("DD-MM-YYYY")
        let url = `https://sc-srm-api-nine.vercel.app/pdf/generate-health-declaration?name=${application.name}&travelDate=${travelDate}&effDate=${startDate}&toDate=${endDate}&requestDate=${requestDate}&nomineeName=${application.nomineeName}&nomineeDob=${nomineeDOB}&nomineeRelation=${application.nomineeRelation}&provider=${application.planDetail.provider}`
        window.open(url, "_blank")
    }

    return (
        <div className={Styles.hiAdminCompLeadDetailContainer}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>

                {
                    application.planDetail
                    &&
                    <table style={{ backgroundColor: "#98fab2", padding: '16px 0', borderRadius: "12px" }}>
                        <tr>
                            <th style={{ fontSize: '0.9rem' }}>Current Plan</th>
                            <th style={{ fontSize: '0.9rem' }}>{application.planDetail.name === "SC Gold Plus" ? "Sum Insured" : "Deductable"}</th>
                            <th style={{ fontSize: '0.9rem' }}>Premium</th>
                        </tr>
                        <tr>
                            <td style={{ padding: "0 24px " }}>{application.planDetail.name}</td>
                            <td style={{ padding: "0 24px " }}>₹ {application.planDetail.deductible === "a" ? "--" : application.planDetail.deductible}</td>
                            <td style={{ padding: "0 24px " }}>₹ {application.planDetail.premium}</td>
                        </tr>
                    </table>
                }

                {/* <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div> */}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Applicant Details" {...a11yProps(0)} />
                    <Tab label="More Details" {...a11yProps(1)} />
                    <Tab label="Upload App Docs" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.formDocContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} className={Styles.inputField} disabled placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} className={Styles.inputField} disabled InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email-Id</p>
                            <input value={application.emailId} className={Styles.inputField} disabled placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Visa Category</p>
                            <Select style={{ width: "100%" }} readOnly value={application.visaCategory || ""} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"International Student on F1 Visa"} >International Student on F1 Visa</MenuItem>
                                <MenuItem value={"F-1 Student on OPT"} >F-1 Student on OPT</MenuItem>
                                <MenuItem value={"Student on J1 Visa"} >Student on J1 Visa</MenuItem>
                                <MenuItem value={"Non Student"} >Non Student</MenuItem>
                                <MenuItem value={"Dependents on F2/J2"} >Dependents on F2/J2</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Gender</p>
                            <Select style={{ width: "100%" }} readOnly value={application.gender || ""} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Male"} >Male</MenuItem>
                                <MenuItem value={"Female"} >Female</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                        <div style={{ gridColumn: "1/2" }}>
                            <InputContainer value={application.phoneNumber} onChange={(event) => { }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Is this whatsapp number?</p>
                            <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >Yes</p>
                            <p>|</p>
                            <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >No</p>
                        </div>

                        {
                            application.isThisWhatsappNumber === "No"
                            &&
                            <InputContainer value={application.whatsappNumber} onChange={(event) => { setApplication({ ...application, whatsappNumber: event.target.value }) }} className={Styles.inputField} placeholder="Whatsapp Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        }

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} disabled onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University</p>
                            <input value={application.desiredUniversity} disabled onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Tentative Travel Date</p>
                            <input type="date" value={application.travelDate} disabled onChange={(event) => { setApplication({ ...application, travelDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Period</p>
                            <input value={application.coveragePeriod} disabled className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Start Date</p>
                            <input type="date" value={application.coverageStartDate} disabled onChange={(event) => { setApplication({ ...application, coverageStartDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage End Date</p>
                            <input type="date" value={application.coverageEndDate} disabled onChange={(event) => { setApplication({ ...application, coverageEndDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Current location?</p>
                            <Select style={{ width: "100%" }} value={application.currentCountry} disabled disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"India"} >India</MenuItem>
                                <MenuItem value={"United States"} >United States</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                    </div>
                }

                {
                    value === 1
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>PAN number</p>
                            <input value={application.panNumber} disabled onChange={(event) => { setApplication({ ...application, panNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Sponser name</p>
                            <input value={application.sponsorName} disabled onChange={(event) => { setApplication({ ...application, sponsorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Emergency contact number</p>
                            <input value={application.emergencyNumber} disabled onChange={(event) => { setApplication({ ...application, emergencyNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Alternate email-id</p>
                            <input value={application.alternateEmail} disabled onChange={(event) => { setApplication({ ...application, alternateEmail: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Mother's name</p>
                            <input value={application.motherName} disabled onChange={(event) => { setApplication({ ...application, motherName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Passport number</p>
                            <input value={application.passportNumber} disabled onChange={(event) => { setApplication({ ...application, passportNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee Name</p>
                            <input value={application.nomineeName} disabled onChange={(event) => { setApplication({ ...application, nomineeName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee relation</p>
                            <input value={application.nomineeRelation} disabled onChange={(event) => { setApplication({ ...application, nomineeRelation: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee dob</p>
                            <input value={application.nomineeDOB} disabled type="date" onChange={(event) => { setApplication({ ...application, nomineeDOB: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address</p>
                            <input value={application.indiaAddress} disabled onChange={(event) => { setAddress({ ...address, line1: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        {/* <div style={{ width: "100%", gridColumn: "1/4" }}>
                            <p className={Styles.label}>Address line 2</p>
                            <input value={address.line2} onChange={(event) => { setAddress({ ...address, line2: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>City</p>
                            <input value={address.city} onChange={(event) => { setAddress({ ...address, city: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>State</p>
                            <input value={address.state} onChange={(event) => { setAddress({ ...address, state: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Pincode</p>
                            <input value={address.pincode} onChange={(event) => { setAddress({ ...address, pincode: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div> */}

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Do you have a US address?</p>
                            <p style={{ color: application.haveUsAddress === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >Yes</p>
                            <p>|</p>
                            <p style={{ color: application.haveUsAddress === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >No</p>
                        </div>

                        {
                            application.haveUsAddress === "Yes"
                            &&
                            <div style={{ width: "100%", gridColumn: "1/4" }}>
                                <p className={Styles.label}>US Address</p>
                                <input value={application.usAddress} disabled onChange={(event) => { setApplication({ ...application, usAddress: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                application.applicantDocs
                                &&
                                application.applicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        
                        {
                            application.currentCountry !== "India"
                            &&
                            <div className={Styles.downloadButton} onClick={()=>{handleHealthDeclaration()}}>
                                <p>Download Health Declaration</p>
                                <MdOutlineFileDownload color="white" />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default HiAdminCompleteLeadDetail