const educationLoan = {
    key: "",
    customerId: "",
    uidSC: "",
    phoneNumber: "",
    name: "",
    emailId: "",
    desiredCountry: "",
    desiredUniversity: "",
    source: "",
    sourceDescription: "",
    type: "",
    date: "",
    status: "",
    subStatus: "",
    product: "",
    receivedFrom: { position: "", name: "", phoneNumber: "", emailId: "" },
    advisorPool: [],
    dob: "",
    levelOfStudy: "",
    requiredLoanAmount: "",
    examsWritten: [],
    hasPreAppliedToBank: "",
    preAppliedBank: "",
    hasWorkExperience: "",
    hasOwnHouse: "",
    coApplicant: "",
    coApplicantProfession: "",
    coApplicantAge: "",
    lastCallDate: "",
    nextCallDateTime: ""
}

export default educationLoan