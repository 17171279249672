import React, { useState } from "react";
import Styles from "./TiClaimDetail.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { Button } from "@mui/material";
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { firebaseDatabase, firebaseStorage } from "../../../backend/firebaseHandler";
import moment from "moment";
import { ref, set } from "firebase/database";

const TiClaimDetail = () => {

    const location = useLocation()
    const application = location.state.item;
    const type = location.state.type
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const [submitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const handlePolicyDoc = () => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `FREE_TI_CLAIMS_DOCS/${application.phoneNumber}/${type}/Policy Document.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    
                    let tempObj = {...application}
                    tempObj.policyDocument = url
                    tempObj.policyUploadDate = moment().format("DD-MM-YYYY h:mm a")
                    tempObj.status = "Policy Generated"

                    if (!application.phoneNumber) {
                        alert ("Something doesn't seem right.")
                        return
                    }
                    if (type === 0) {
                        await set(ref(firebaseDatabase, `SC_FREE_TI_CLAIMS/${application.phoneNumber}`), tempObj)
                    } else {
                        await set(ref(firebaseDatabase, `FIDESURE_FREE_TI_CLAIMS/${application.phoneNumber}`), tempObj)
                    }
                    setSubmitting(false)
                    alert("Policy Document Uploaded")
                    navigate(-1)
                    
                })
            }
        }
        inputElement.click();
    }

    return (
        <div className={Styles.tiCLaimDetailContainer}>
            <h1 style={{ color: "#352F6C" }}>New Lead Detail</h1>

            {
                type === 0
                ?
                <div className={Styles.formContainer}>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Name</p>
                        <input value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Phone Number</p>
                        <input value={application.phoneNumber} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Email-Id</p>
                        <input value={application.emailId} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Date of Birth</p>
                        <input value={application.dob} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Travel Start Date</p>
                        <input value={application.travelStartDate} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Travel End Date</p>
                        <input value={application.travelEndDate} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Nominee Name</p>
                        <input value={application.nomineeName} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Nominee Relation</p>
                        <input value={application.nomineeRelation} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>
                    
                    <div className={Styles.documentItem} style={{gridColumn:"1/2"}}>
                        <p style={{ color:"#12b004" }} className={Styles.documentName}>Passport</p>
                        <FaExternalLinkSquareAlt onClick={() => { window.open(application.passportUrl, "_blank") }} color={"#12b004"} style={{ cursor: "pointer" }} />
                    </div>

                    {
                        application.status === "Policy Generated"
                        &&
                        <div className={Styles.documentItem}>
                            <p style={{ color:"#12b004" }} className={Styles.documentName}>Policy Document</p>
                            <FaExternalLinkSquareAlt onClick={() => { window.open(application.policyDocument, "_blank") }} color={"#12b004"} style={{ cursor: "pointer" }} />
                        </div>
                    }

                </div>
                :
                <div className={Styles.formContainer}>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Name</p>
                        <input value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Phone Number</p>
                        <input value={application.phoneNumber} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Email-Id</p>
                        <input value={application.emailId} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Plan Purchased</p>
                        <input value={application.planPurchased} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Travel Start Date</p>
                        <input value={application.travelStartDate} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Travel End Date</p>
                        <input value={application.travelEndDate} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Nominee Name</p>
                        <input value={application.nomineeName} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Nominee Relation</p>
                        <input value={application.nomineeRelation} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>

                    <div className={Styles.documentItem} style={{gridColumn:"1/2"}}>
                        <p style={{ color:"#12b004" }} className={Styles.documentName}>Passport</p>
                        <FaExternalLinkSquareAlt onClick={() => { window.open(application.passportDocument, "_blank") }} color={"#12b004"} style={{ cursor: "pointer" }} />
                    </div>

                    <div className={Styles.documentItem}>
                        <p style={{ color:"#12b004" }} className={Styles.documentName}>i20</p>
                        <FaExternalLinkSquareAlt onClick={() => { window.open(application.i20Document, "_blank") }} color={"#12b004"} style={{ cursor: "pointer" }} />
                    </div>

                    {
                        application.status === "Policy Generated"
                        &&
                        <div className={Styles.documentItem}>
                            <p style={{ color:"#12b004" }} className={Styles.documentName}>Policy Document</p>
                            <FaExternalLinkSquareAlt onClick={() => { window.open(application.policyDocument, "_blank") }} color={"#12b004"} style={{ cursor: "pointer" }} />
                        </div>
                    }
                    
                </div>
            }

            {
                application.status === "New"
                &&
                <Button onClick={()=>{handlePolicyDoc()}} variant='contained' style={{marginTop:20, alignSelf:"flex-end"}} disabled={submitting} className={Styles.button}>Upload Policy Document</Button>
            }
        </div>
    )
}

export default TiClaimDetail