import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiManagerPolicyGeneratedLeads.module.css"
import moment from "moment";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IoDocument } from "react-icons/io5";
import { HiDocumentDownload } from "react-icons/hi";
import exportFromJSON from "export-from-json";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";

const HiManagerPolicyGeneratedLeads = ({activeList}) => {

    const [visibleList, setVisibleList] = useState([])
    const navigate = useNavigate()
    const [userData] = useContext(UserContext)
    
    useEffect (() => {
        let temp = []
        for (const index in activeList) {
            let item = activeList[index]
            let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            item.travelAge = moment(travelDate).diff(moment(), 'days')
            temp.push(item)
        }
        setVisibleList(temp)
    }, [])

    const handleExport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")
        for (const [index, item] of activeList.entries()) {
            let tempObj = {
                "Customer Name": item.name || "",
                "Phone Number": item.phoneNumber || "",
                "Email ID": item.emailId || "",
                "Country": item.desiredCountry || "",
                "University": item.desiredUniversity || "",
                "Travel Date": item.travelDate || "",
                "Plan": item.planDetail.name || "",
                "Premium": item.planDetail.premium || "",
                "SC Advisor": item.assignedAdvisorName || "",
                "Source": item.source || "",
                "Source Description": item.sourceDescription || "",
                "Policy Document": item.policyUrl || ""
            }
            data.push(tempObj)
        }
        const fileName = 'All HI Link Sent Leads: ' + date
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
    }

    const handleClick = (item) => {
        if (userData.position === "Manager") {
            navigate("/health-insurance/lead-status/complete-lead-detail", {state:{item:item}})
        } else {
            navigate("/all-hi-lead-status/complete-lead-detail", {state:{item:item}})
        }
    }

    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <Button onClick={() => { handleExport() }} startIcon={<HiDocumentDownload />} style={{ backgroundColor: "#8069B4", alignSelf:"flex-end" }} size='medium' variant='contained'>Export New Leads</Button>>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Generated on</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Travel Date</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Plan Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Premium</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Payment Proof</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Policy Document</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                visibleList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.desiredUniversity}</TableCell>
                                            <TableCell>{item.paymentConfirmedDate?item.paymentConfirmedDate.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.travelDate}</TableCell>
                                            <TableCell>{item.planDetail?item.planDetail.name:""}</TableCell>
                                            <TableCell>{item.planDetail?item.planDetail.premium:""}</TableCell>
                                            <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.proofDocumentUrl, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                            <TableCell><IoDocument size={20} color="#352F6C" onClick={()=>{window.open(item.policyUrl, "_blank")}} style={{cursor:"pointer"}} /></TableCell>
                                            <TableCell>{item.userType==="From Website"?"Website":item.assignedAdvisorName}</TableCell>
                                            <TableCell><Button onClick={()=>{handleClick(item)}} variant="contained" style={{backgroundColor:"#352F6C"}}>View</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default HiManagerPolicyGeneratedLeads