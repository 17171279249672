import React from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoFolderOpenSharp } from "react-icons/io5";

const NbfcManagerRejectedLeads = ({ activeList }) => {

    const navigate = useNavigate()

    return (
        <div>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>App ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>File</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Executive</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.applicationId}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell><IoFolderOpenSharp  size={20} color="#352F6C" onClick={()=>{navigate("/vendor-view-file", { state: { item: item } })}} style={{cursor:"pointer"}} /></TableCell>
                                            <TableCell>{item.assignedRmName}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default NbfcManagerRejectedLeads