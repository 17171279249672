import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorCompleteLeadDetail.module.css"
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import InputContainer from "../InputContainer/InputContainer.component";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Switch, Tab, Tabs } from "@mui/material";
import moment from "moment";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseStorage } from "../../backend/firebaseHandler";
import CryptoJS from "crypto-js";
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import getHiDocumentMap from "../../workload/HiDocumentSet";
import { RiPushpinFill, RiUnpinFill } from "react-icons/ri"

const HiAdvisorCompleteLeadDetail = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [userData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);
    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState(0)
    const [qrLink, setQrLink] = useState("")
    const [followUpDateModal, setFollowUpDateModal] = useState(false)
    const [address, setAddress] = useState({line1:"", line2:"", city:"", state:"", pincode:""})
    const [isCloseVisible, setIsCloseVisible] = useState(false)
    const [competitorModal, setCompetitorModal] = useState(false)
    const [purchaseFromUsModal, setPurchasedFromUsModal] = useState(false)

    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })

    useEffect(() => {
        setQrLink(encrypt(`HI_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
        
        
        let tempObj = { ...application }

        // tempObj.startDate = (!tempObj.startDate||tempObj.startDate==="")?"":moment(tempObj.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        // tempObj.endDate = (!tempObj.endDate||tempObj.endDate==="")?"":moment(tempObj.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.dob = (!tempObj.dob||tempObj.dob==="")?"":moment(tempObj.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.travelDate = moment(tempObj.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "DD-MM-YYYY").format("YYYY-MM-DD")

        if (!application.applicantDocs) {
            tempObj.applicantDocs = [...getHiDocumentMap()["StudentInIndiaICICI"]]
        }
        setApplication({ ...tempObj })
        
        if (tempObj.indiaAddress) {
            let addressArray = tempObj.indiaAddress.split(", ")
            setAddress({line1:addressArray[0], line2:addressArray[1], city:addressArray[2], state:addressArray[3], pincode:addressArray[4]})
        }

        const dbRef = ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`)
        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                let tempObj = snap.val()

                tempObj.startDate = (!tempObj.startDate||tempObj.startDate==="")?"":moment(tempObj.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                tempObj.endDate = (!tempObj.endDate||tempObj.endDate==="")?"":moment(tempObj.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                tempObj.dob = (!tempObj.dob||tempObj.dob==="")?"":moment(tempObj.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
                tempObj.travelDate = moment(tempObj.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "DD-MM-YYYY").format("YYYY-MM-DD")

                if (!application.applicantDocs) {
                    tempObj.applicantDocs = [...getHiDocumentMap()["StudentInIndiaICICI"]]
                }
                setApplication({ ...tempObj })
                
                if (tempObj.indiaAddress) {
                    let addressArray = tempObj.indiaAddress.split(", ")
                    setAddress({line1:addressArray[0], line2:addressArray[1], city:addressArray[2], state:addressArray[3], pincode:addressArray[4]})
                }
                setApplication({...tempObj})
            }
        }, {onlyOnce:false})

        return () => {
            off(dbRef, "value")
        }
        
    }, [])

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');

        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `HEALTH_INSURANCE_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();
    }

    const handelInIndia = (value) => {
        let tempObj = { ...application }

        tempObj.inIndia = value
        tempObj.applicantDocs = value === "Yes" ? [...getHiDocumentMap()["StudentInIndiaICICI"]] : [...getHiDocumentMap()["StudentNotInIndia"]]

        setApplication({ ...tempObj })
    }

    const handleSave = async () => {
        let tempObj = {...application}

        // Object.values(address)
        tempObj.indiaAddress = Object.values(address).join(", ")
        // tempObj.startDate = tempObj.startDate===""?"":moment(tempObj.startDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        // tempObj.endDate = tempObj.endDate===""?"":moment(tempObj.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.dob = tempObj.dob===""?"":moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.travelDate = moment(tempObj.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime, "YYYY-MM-DD").format("DD-MM-YYYY")

        setSubmitting(true)
        await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)
        setSubmitting(false)
        navigate(-1)
    }

    const handleSubmit = async () => {
        if (application.status === "Not Interested") {
            switch (application.subStatus) {
                case "Have purchased from a competitor": {
                    setCompetitorModal(true)
                    break
                }
                case "Would be purchasing from university": {
                    moveToClosed()
                    break
                }
                case "Would be purchasing from competitor": {
                    setCompetitorModal(true)
                    break
                }
                case "No plans of traveling": {
                    moveToClosed()
                    break
                }

                default: {}
            }
        } else if (application.status === "Plan not accepted in the University") {
            moveToClosed()
        } else if (application.status === "Visa Rejected") {
            switch (application.subStatus) {
                case "Will re-apply": {
                    if (application.travelDate === "") {
                        setSnackBar({ visibility: true, severity: "warning", message: "Please enter the tentative travel date" })
                        return
                    }
                    moveToClosed ()
                    break;
                }
                case "Will not re-apply": {
                    moveToClosed ()
                    break;
                }
                default: {}
            }
        } else if (application.status === "No Response") {
            moveToClosed()
        } else if (application.status === "DND") {
            moveToClosed()
        } else if (application.status === "Already purchased from us") {
            setPurchasedFromUsModal(true)
        }
    }

    const moveToClosed = async () => {
        let tempObj = { ...application }

        if (application.subStatus === "Have purchased from a competitor" || application.subStatus === "Would be purchasing from competitor") {
            if (application.competitorName === "") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the competitor name" })
                return
            }
        }

        if (application.status === "Already purchased from us") {
            if (!application.purchasedPlanName) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the plan name" })
                return
            }
        }

        setSubmitting(true)

        tempObj.status = "Closed"
        tempObj.subStatus = application.status + " - " + application.subStatus
        tempObj.closedDate = moment().format("DD-MM-YYYY h:mm a")

        if (tempObj.travelDate !== "") {
            tempObj.travelDate = moment(tempObj.travelDate, "YYYY-MM-DD").format("DD-MM-YYYY")
        }
        if (tempObj.dob !== "") {
            tempObj.dob = moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        }

        if (application.status === "Plan not accepted in the University" || application.status === "Already purchased from us" || application.status === "DND") {
            tempObj.subStatus = application.status
        }

        await set(ref(firebaseDatabase, `HI_CLOSED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Closed"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Closed",
                    subStatus: tempObj.subStatus,
                    date: tempObj.closedDate,
                    ref: "HI_CLOSED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)

                await remove(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                setSubmitting(false)
                navigate(-1)
                setSubmitting(false)
            }
        }, { onlyOnce: true })        
    }

    const handlePin = async () => {
        if (application.isPinned) {
            setSubmitting(true)
            await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}/isPinned`), false)
            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}/isPinned`), false)
            setSubmitting(false)
            setApplication({...application, isPinned:false})
        } else {
            setSubmitting(true)
            await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}/isPinned`), true)
            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}/isPinned`), true)
            setSubmitting(false)
            setApplication({...application, isPinned:true})
        }
    }

    return (
        <div className={Styles.hiInterestedLeadDetailContainer}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Applicant Details" {...a11yProps(0)} />
                    <Tab label="More Details" {...a11yProps(1)} />
                    <Tab label="Upload App Docs" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.formDocContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email-Id</p>
                            <input value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Visa Category</p>
                            <Select style={{ width: "100%" }} value={application.visaCategory || ""} onChange={(event) => { setApplication({ ...application, visaCategory: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"International Student on F1 Visa"} >International Student on F1 Visa</MenuItem>
                                <MenuItem value={"F-1 Student on OPT"} >F-1 Student on OPT</MenuItem>
                                <MenuItem value={"Student on J1 Visa"} >Student on J1 Visa</MenuItem>
                                <MenuItem value={"Non Student"} >Non Student</MenuItem>
                                <MenuItem value={"Dependents on F2/J2"} >Dependents on F2/J2</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Gender</p>
                            <Select style={{ width: "100%" }} value={application.gender || ""} onChange={(event) => { setApplication({ ...application, gender: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Male"} >Male</MenuItem>
                                <MenuItem value={"Female"} >Female</MenuItem>
                                <MenuItem value={"Other"} >Other</MenuItem>
                            </Select>
                        </div>

                        <div style={{ gridColumn: "1/2" }}>
                            <InputContainer value={application.phoneNumber} readOnly onChange={(event) => { }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Is this whatsapp number?</p>
                            <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => {  }}>No</p>
                        </div>

                        {
                            application.isThisWhatsappNumber === "No"
                            &&
                            <InputContainer value={application.whatsappNumber} onChange={(event) => { setApplication({ ...application, whatsappNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        }

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Tentative Travel Date</p>
                            <input type="date" value={application.travelDate} onChange={(event) => { setApplication({ ...application, travelDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", gridColumn:"1/4" }}>
                            <p className={Styles.label}>Remark</p>
                            <input value={application.remarkFromAdvisor} onChange={(event) => { setApplication({ ...application, remarkFromAdvisor: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        {/* <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage Start Date</p>
                            <input readOnly type="date" value={application.startDate} onChange={(event) => { setApplication({ ...application, startDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Coverage End Date</p>
                            <input readOnly type="date" value={application.endDate} onChange={(event) => { setApplication({ ...application, endDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div> */}

                        {/* <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Currently in India?</p>
                            <Select readOnly style={{ width: "100%" }} value={application.inIndia} onChange={(event) => { handelInIndia(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Yes"} >Yes</MenuItem>
                                <MenuItem value={"No"} >No</MenuItem>
                            </Select>
                        </div> */}

                    </div>
                }

                {
                    value === 1
                    &&
                    <div className={Styles.formContainer}>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>PAN number</p>
                            <input readOnly value={application.panNumber} onChange={(event) => { setApplication({ ...application, panNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Sponser name</p>
                            <input readOnly value={application.sponsorName} onChange={(event) => { setApplication({ ...application, sponsorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Emergency contact number</p>
                            <input readOnly value={application.emergencyNumber} onChange={(event) => { setApplication({ ...application, emergencyNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Alternate email-id</p>
                            <input readOnly value={application.alternateEmail} onChange={(event) => { setApplication({ ...application, alternateEmail: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Mother's name</p>
                            <input readOnly value={application.motherName} onChange={(event) => { setApplication({ ...application, motherName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Passport number</p>
                            <input readOnly value={application.passportNumber} onChange={(event) => { setApplication({ ...application, passportNumber: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee Name</p>
                            <input readOnly value={application.nomineeName} onChange={(event) => { setApplication({ ...application, nomineeName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Nominee relation</p>
                            <input readOnly value={application.nomineeRelation} onChange={(event) => { setApplication({ ...application, nomineeRelation: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn:"1/4" }}>
                            <p className={Styles.label}>Address line 1</p>
                            <input readOnly value={address.line1} onChange={(event) => { setAddress({ ...address, line1: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", gridColumn:"1/4" }}>
                            <p className={Styles.label}>Address line 2</p>
                            <input readOnly value={address.line2} onChange={(event) => { setAddress({ ...address, line2: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>City</p>
                            <input readOnly value={address.city} onChange={(event) => { setAddress({ ...address, city: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>State</p>
                            <input readOnly value={address.state} onChange={(event) => { setAddress({ ...address, state: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Pincode</p>
                            <input readOnly value={address.pincode} onChange={(event) => { setAddress({ ...address, pincode: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.yesNoContainer} style={{ gridColumn: "1/2" }}>
                            <p>Do you have a US address?</p>
                            <p style={{ color: application.haveUsAddress === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => {  }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.haveUsAddress === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { }}>No</p>
                        </div>

                        {
                            application.haveUsAddress === "Yes"
                            &&
                            <div style={{ width: "100%", gridColumn:"1/4" }}>
                                <p className={Styles.label}>US Address</p>
                                <input readOnly value={application.usAddress} onChange={(event) => { setApplication({ ...application, usAddress: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                    </div>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                application.applicantDocs
                                &&
                                application.applicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </>
                }

                
            </div>

            {
                isCloseVisible
                &&
                <div style={{display:"grid", gridTemplateColumns:"repeat(3, 1fr)", gap:20, marginTop:20}}>
                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Call Status</p>
                        <Select style={{ width: "100%" }} value={application.status} onChange={(event) => {setApplication({...application, status:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            <MenuItem value={"Not Interested"} >Not Interested</MenuItem>
                            <MenuItem value={"Plan not accepted in the University"} >Plan not accepted in the University</MenuItem>
                            <MenuItem value={"Visa Rejected"} >Visa Rejected</MenuItem>
                            <MenuItem value={"No Response"} >No Response</MenuItem>
                            <MenuItem value={"DND"} >DND</MenuItem>
                            <MenuItem value={"Already purchased from us"} >Already purchased from us</MenuItem>
                        </Select>
                    </div>
                    {
                        application.status === "Not Interested"
                        &&
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Please specify</p>
                            <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Have purchased from a competitor"} >Have purchased from a competitor</MenuItem>
                                <MenuItem value={"Would be purchasing from university"} >Would be purchasing from university</MenuItem>
                                <MenuItem value={"Would be purchasing from competitor"} >Would be purchasing from competitor</MenuItem>
                                <MenuItem value={"No plans of traveling"} >No plans of traveling</MenuItem>
                            </Select>
                        </div>
                    }
                    {
                        application.status === "No Response"
                        &&
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Please specify</p>
                            <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Not answered"} >Not answered</MenuItem>
                                <MenuItem value={"Not reachable"} >Not reachable</MenuItem>
                                <MenuItem value={"Incoming calls not active"} >Incoming calls not active</MenuItem>
                                <MenuItem value={"Call disconnected"} >Call disconnected</MenuItem>
                            </Select>
                        </div>
                    }
                    {
                        application.status === "Visa Rejected"
                        &&
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Please specify</p>
                            <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Will re-apply"} >Will re-apply</MenuItem>
                                <MenuItem value={"Will not re-apply"} >Will not re-apply</MenuItem>
                            </Select>
                        </div>
                    }
                </div>
            }

            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <Button onClick={() => { handlePin() }} startIcon={application.isPinned?<RiUnpinFill />:<RiPushpinFill  />} style={{ backgroundColor: "#8069B4", width:200 }} sx={{marginTop:'40px'}} size='medium' variant='contained'>{application.isPinned?"Un-pin Lead":"Pin Lead"}</Button>
                <div className={Styles.buttonsContainer}>
                    {
                        isCloseVisible
                        ?
                        <>
                            <Button disabled={submitting} onClick={() => { setIsCloseVisible(false) }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { handleSubmit() }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Submit</Button>
                        </>
                        :
                        <>
                            <Button disabled={submitting} onClick={() => { setIsCloseVisible(true) }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Close lead</Button>
                            <Button disabled={submitting} onClick={() => { setFollowUpDateModal(true) }} sx={{ marginTop: '40px' }} variant='contained' className={Styles.button}>Update Follow-up</Button>
                        </>
                    }
                </div>
            </div>

            {/* Follow-up date modal */}
            <Modal open={followUpDateModal} onClose={() => { setFollowUpDateModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up date</p>
                            <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up mode</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode || ""} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Call"} >Call</MenuItem>
                                <MenuItem value={"Mail"} >Mail</MenuItem>
                                <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setFollowUpDateModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { handleSave() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Competitor modal */}
            <Modal open={competitorModal} onClose={() => { setCompetitorModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Competitor's name</p>
                            <input value={application.competitorName} onChange={(event) => { setApplication({ ...application, competitorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setCompetitorModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToClosed() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Purchased from us modal */}
            <Modal open={purchaseFromUsModal} onClose={() => { setPurchasedFromUsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Plan name</p>
                            <input value={application.purchasedPlanName} onChange={(event) => { setApplication({ ...application, purchasedPlanName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Advisor name</p>
                            <input value={application.purchasedAdvisorName} onChange={(event) => { setApplication({ ...application, purchasedAdvisorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                    
                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPurchasedFromUsModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToClosed() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default HiAdvisorCompleteLeadDetail