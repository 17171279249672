import React, { useContext, useEffect, useState } from "react";
import Styles from "./ManageUsers.module.css"
import { useNavigate } from "react-router-dom";
import UserContext from "../../../context/UserData.context";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { Box, Button, CircularProgress, Collapse, Divider, Input, MenuItem, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoAddCircle } from 'react-icons/io5';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdArrowDropDown } from "react-icons/md"
import UserCollapseRow from "../../UserCollapseRow/UserCollapseRow.component";

const ManageUsers = () => {
    const [value, setValue] = React.useState(0);
    const [departmentFilter, setDepartmentFilter] = useState("")
    const [positionFilter, setPositionFilter] = useState("")
    const [searchWord, setSearchWord] = useState("")
    const navigate = useNavigate()
    const [userList, setUserList] = useState([])
    const [activeList, setActiveList] = useState([])
    const [userData, setUserData] = useContext(UserContext)

    useEffect(() =>{
        let tempActive = []
        let tempAll = []
        setDepartmentFilter("")
        setPositionFilter("")
        onValue(ref(firebaseDatabase, "USER_ARCHIVE"), (snapShot) => {
            if (snapShot.exists()) {
                tempActive = []
                tempAll = []
                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val()
                    if (item.status === "Active") {
                        tempActive.push(item)
                    }
                    tempAll.push(item)
                }
                setActiveList([...tempActive])
                setUserList([...tempAll])
            }
        })
    }, [])

    const handleFilter = (position, type) => {
        let temp = []
        let positionTemp = ""
        let departmentTemp = ""
        
        if (type === "Department") {
            setDepartmentFilter(position)
            departmentTemp = position
            positionTemp = positionFilter
        } else {
            setPositionFilter(position)
            departmentTemp = departmentFilter
            positionTemp = position
        }

        for (const index in userList) {
            let item = userList[index]
            
            if (item.position.includes(positionTemp) && item.department.includes(departmentTemp)) {
                temp.push(item)
            } 
        }        
        setActiveList([...temp])
    }

    const handleSearch = (keyCode) => {
        let visibleTemp = [...activeList]
        if (keyCode === 13) {
            let temp = []
            for (const index in visibleTemp) {
                let item = visibleTemp[index]
                if (item.name.toLowerCase().includes(searchWord.toLowerCase()) || item.phoneNumber.toLowerCase().includes(searchWord.toLowerCase()) || item.emailId.toLowerCase().includes(searchWord.toLowerCase())) {
                    temp.push(item)
                } 
            }
            setActiveList([...temp])
        }
    }

   

    return (
        <div className={Styles.manageUserContainer}>
            <div className={Styles.tabHederContainer}>
                <h1 style={{color:"#352F6C"}}>Current Users</h1>
                {
                    userData.rights.includes("AddUser")
                    &&
                    <Button onClick={()=>{navigate("/manage-users/add-user")}} startIcon={<IoAddCircle />} style={{backgroundColor:"#8069B4"}} size='medium' variant='contained'>Add New User</Button>
                }
            </div>

            <Divider style={{marginTop:40, marginBottom:40}} />

            <div>
                
                <Stack direction={'row'} alignItems='center' justifyContent='space-between' className={Styles.filterContainer}>
                    <h2 style={{color:"#352F6C"}}>{activeList.length} Users</h2>

                    <Stack gap={2} direction='row' >
                        <h4 style={{color:"#352F6C", margin:"auto"}}>Filter By: </h4>
                        <Select disableUnderline={true} style={{ width: 200, backgroundColor:"white", borderRadius:5, padding:5 }} value={departmentFilter} onChange={event => {handleFilter(event.target.value, "Department")}} defaultValue="Filter One" variant='standard' size='small'>
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"Education Loan"}>Education Loan</MenuItem>
                            <MenuItem value={"Health Insurance"}>Health Insurance</MenuItem>
                            <MenuItem value={"Travel Insurance"}>Travel Insurance</MenuItem>
                            <MenuItem value={"Dental Insurance"}>Dental Insurance</MenuItem>
                            <MenuItem value={"Admission Counselling"}>Admission Counselling</MenuItem>
                        </Select>
                        <Select disableUnderline={true} style={{ width: 200, backgroundColor:"white", borderRadius:5, padding:5 }} value={positionFilter} onChange={event => {handleFilter(event.target.value, "Position")}} defaultValue="Filter One" variant='standard' size='small'>
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"Advisor"}>Advisors</MenuItem>
                            <MenuItem value={"Manager"}>Managers</MenuItem>
                            <MenuItem value={"NBFC"}>NBFCs </MenuItem>
                        </Select>
                        <Input disableUnderline={true} style={{backgroundColor:"white", borderRadius:5, padding:5}} value={searchWord} onKeyUp={(event)=>{handleSearch(event.keyCode)}} onChange={(event)=>{setSearchWord(event.target.value)}} startAdornment={<AiOutlineSearch style={{ marginRight: 12 }} size={24} />} size='small' placeholder='Name, Ph no, Email' />
                    </Stack>
                </Stack>

                <Box>
                    <TableContainer style={{marginTop:40}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>User Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>Department</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>Position</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>Phone Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>Email Id</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>
                                        <div style={{display:"flex", flexDirection:"row", cursor:"pointer", alignItems:"center", gap:5}}>
                                            <p>Status</p>
                                            <MdArrowDropDown size={25} />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C"}}>Last Login</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    activeList.map((item, index) => {
                                        return (
                                            <UserCollapseRow item={item} index={index} />
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default ManageUsers