import React, { useEffect, useState } from "react";
import Styles from './AdvisorLeads.module.css';
import { motion } from "framer-motion";
import moment from "moment/moment";
import axios from "axios";
import { CircularProgress, Input } from "@mui/material";
import stc from 'string-to-color';
import _ from 'lodash'

const AdvisorLeads = ({ uid }) => {

    const [selectedLead, setSelectedLead] = useState({ metric: "New Lead Performance", selectedDate: moment().format("YYYY-MM-DD") });
    const [stats, setStats] = useState({ data: [], loading: true, nothingToShow: false })
    useEffect(() => {
        setStats(stats => ({ ...stats, loading: true }))
        if (selectedLead.metric === "New Lead Performance") {
            const baseURL = "https://sc-srm-api-nine.vercel.app/reporting/advisor-stats-on-uploaded-date"
            axios.get(baseURL, {
                params: {
                    assignedAdvisorUid: uid,
                    newFromAdminDate: moment(selectedLead.selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                }
            }).then((response) => {
                const data = response.data;
                setStats({ data, loading: false })
            })

        }

        if (selectedLead.metric === "Daily Performance") {
            const baseURL = "https://sc-srm-api-nine.vercel.app/reporting/advisor-stats-on-selected-date"
            axios.get(baseURL, {
                params: {
                    assignedAdvisorUid: uid,
                    selectedDate: moment(selectedLead.selectedDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                }
            }).then((response) => {
                const data = response.data;
                setStats({ data, loading: false })
            })
        }
    }, [selectedLead])

    const handlePerformanceSelect = metric => {
        setSelectedLead(state => ({ ...state, metric }))
    }

    return (
        <div className={Styles.advisorLeadsContainer}>
            <div className={Styles.performanceTypeContainer}>
                <motion.p onClick={_ => handlePerformanceSelect("New Lead Performance")} className={selectedLead.metric === "New Lead Performance" ? Styles.selectedPerformanceButton : Styles.unselectedPerformanceButton}>
                    New Lead Performance
                </motion.p>
                <motion.p onClick={_ => handlePerformanceSelect("Daily Performance")} className={selectedLead.metric === "Daily Performance" ? Styles.selectedPerformanceButton : Styles.unselectedPerformanceButton}>
                    Daily Performance
                </motion.p>
                <Input className={Styles.datePicker} type="date" value={selectedLead.selectedDate} onChange={event => setSelectedLead({ ...selectedLead, selectedDate: event.target.value })} />
            </div>
            <div>
                <ColorBar stats={stats.data} loading={stats.loading} nothingToShow={stats.nothingToShow} />
            </div>
        </div>
    )
}

const ColorBar = ({ stats, loading, nothingToShow }) => {
    console.log(stats)


    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "200px" }}>
                <CircularProgress size={20} />
            </div>
        )
    }

    if (Object.keys(stats).length === 0) {
        return (
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "200px", flexDirection: 'column' }}>
                <h3>No data to show here</h3>
                <p>Please select a different day</p>
            </div>
        )
    }

    const totalValue = _.reduce(Object.values(stats), (sum, n) => sum + n)
    console.log(totalValue)

    return (
        <div style={{ minHeight: '200px' }} >
            <div className={Styles.barsContainer}>
                {
                    Object.keys(stats).map(item => {
                        return <div style={{ backgroundColor: stc(item), width: `${(stats[item] / totalValue) * 100}%` }} title={item} className={Styles.bars} />
                    })
                }
            </div>

            <div className={Styles.legendContainer}>
                <p>Legend</p>
                <div className={Styles.legendGrid}>
                    {
                        Object.keys(stats).map(item => {
                            return (
                                <div style={{ display: 'flex', alignItems: "center", gap: '12px' }}>
                                    <div style={{ backgroundColor: stc(item), width: '10px', height: '10px' }} />
                                    <p>{item} - {stats[item]}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default AdvisorLeads;