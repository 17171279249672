import React, { useState } from "react";
import Styles from "./UserCollapseRow.module.css"
import { Button, Collapse, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserCollapseRow = ( {item, index} ) => {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <TableRow key={index.toString()}>
                <TableCell>{(index + 1).toString()}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.department}</TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{item.emailId}</TableCell>
                <TableCell>{item.currently}</TableCell>
                <TableCell>{item.lastLogin}</TableCell>
                <TableCell><Button variant="contained" style={{width:100, fontSize:10, backgroundColor:"#352F6C", height:30}} onClick={()=>{setOpen(!open)}}>{open?"View Less":"View More"}</Button></TableCell>
            </TableRow>
            <TableRow  key={index.toString()}>
                <TableCell colSpan={9} style={{padding:0}}>
                    <Collapse in={open} >
                        <div className={Styles.detailContainer}>
                            <div>
                                <p className={Styles.label}>Uid</p>
                                <p className={Styles.value}>{item.uid}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Name</p>
                                <p className={Styles.value}>{item.name}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Phone Number</p>
                                <p className={Styles.value}>{item.phoneNumber}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Email Id</p>
                                <p className={Styles.value}>{item.emailId}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Languages</p>
                                <p className={Styles.value}>{item.languages?item.languages.join(", "):"-"}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Address</p>
                                <p className={Styles.value}>{item.mailingAddress}</p>
                            </div>
                            <div>
                                <p className={Styles.label}>Created On</p>
                                <p className={Styles.value}>{item.addedOnDate}</p>
                            </div>
                            <Button onClick={()=>{navigate("/manage-users/edit-profile", {state:{item:item}})}} variant="contained" style={{width:150, alignSelf:"end", marginLeft:"auto", backgroundColor:"#352F6C", height:40}}>Edit Profile</Button>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserCollapseRow