import { onValue, push, ref, set } from "firebase/database";
import React, { useState } from "react";
import { firebaseDatabase } from "../../backend/firebaseHandler";
import { chain } from "lodash";

const ForTesting = () => {

    let [array, setArray] = useState([])

    const handleUpdate = async () => {

        // let temp = []
        // for (let i = 0 ; i<83 ; i++) {
        //     let key = push(ref(firebaseDatabase, `HI_OP_USER_POLICY_GENERATED_LEADS`)).key
        //     temp.push(key)
        // }
        // setArray(temp)

        // await set(ref(firebaseDatabase, "VENDOR_RE_LOGIN_LEADS/Auxilo/919664778267/assignedRmName"), "Vishwas")
        // await set(ref(firebaseDatabase, "VENDOR_RE_LOGIN_LEADS/Auxilo/919664778267/assignedRmPhoneNumber"), "9403001832")
        // await set(ref(firebaseDatabase, "VENDOR_RE_LOGIN_LEADS/Auxilo/919664778267/assignedRmUid"), "eClGgEiHlBVsj7W501dSuzUaP5f2")

        // await set(ref(firebaseDatabase, "NBFC_LEAD_STATUS/919664778267/Auxilo/assignedRmName"), "Vishwas")
        // await set(ref(firebaseDatabase, "NBFC_LEAD_STATUS/919664778267/Auxilo/assignedRmPhoneNumber"), "9403001832")
        // await set(ref(firebaseDatabase, "NBFC_LEAD_STATUS/919664778267/Auxilo/assignedRmUid"), "eClGgEiHlBVsj7W501dSuzUaP5f2")

        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919991433788/assignedAdvisorName"), "Manisha Kumari")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919991433788/assignedAdvisorPhoneNumber"), "9267984374")
        // await set(ref(firebaseDatabase, "VENDOR_FILE_POOL/UnionBank/919991433788/assignedAdvisorUid"), "uaQIP4t6pQdxXMZlIBiZXGx7kOf2")
    
        // handleTransfer()
    }

    

    const handleTransfer = () => {
        onValue(ref(firebaseDatabase, `DISBURSED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    for (const number in snap.child(uid).val()) {
                        console.log(snap.child(uid).child(number).child("assignedAdvisorName").val())
                    }
                }
            }
        }, {onlyOnce:true})
    }

    return (
        <div style={{padding:50}}>
            <h1 onClick={()=>{handleUpdate()}}>Update</h1>

            {
                array.map((item) => {return(
                    <p>{item}</p>
                )})
            }
        </div>
    )
}

export default ForTesting