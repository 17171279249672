import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdvisorCaseDetailIncognito.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import QRCode from "react-qr-code";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Tab, Tabs } from "@mui/material";
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseAdmin } from "../../backend/firebaseHandler";
import moment from "moment";
import CryptoJS from "crypto-js";
import InputContainer from "../InputContainer/InputContainer.component";

const AdvisorCaseDetailIncognito = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [userData, setUserData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item)
    const [caseDetail, setCaseDetail] = useState(application.caseDetail ? application.caseDetail : {})
    const [value, setValue] = React.useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const qrLink = encrypt(`INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${location.state.item.phoneNumber}`, "CutriPlingPutri")
    const [outstandingModal, setOutstandingModal] = useState(false)

    const [pincodeModal, setPincodeModal] = useState(false)
    const [pincode, setPincode] = useState("")
    const [selectedPincodeBank, setSelectedPincodeBank] = useState("")

    const [ubListModal, setUbListModal] = useState(false)
    const [selectedListBank, setSelectedListBank] = useState("")
    const [searchWord, setSearchWord] = useState("")
    const [matchingResult, setMatchingResult] = useState([])

    useEffect(() => {
        caseDetail.name = application.name
        caseDetail.phoneNumber = application.phoneNumber
        caseDetail.emailId = application.emailId
        caseDetail.requiredLoanAmount = application.requiredLoanAmount

        if (!caseDetail.nfInformation) {
            let array = []
            for (const index in application.nonFinancialCoApplicants) {
                let tempObj = {
                    name: "",
                    phoneNumber: "",
                    emailId: "",
                    fatherName: "",
                    motherName: "",
                    qualification: "",
                    noOfDependents: "",
                    noOfYearInCurrentHouse: "",
                    currentAddress: "",
                    permanentAddress: ""
                }
                array.push(tempObj)
            }
            caseDetail.nfInformation = [...array]
        }

        setCaseDetail({ ...caseDetail })
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleSave = async () => {
        setSubmitting(true)
        application.caseDetail = { ...caseDetail }
        await set(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
        setSubmitting(false)
    }

    const handleSendToOp = async () => {
        if (application.isLodVerified === "No") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please verify the LOD before moving this to Operations!" })
            return
        }
        setSubmitting(true)

        let tempObj = { ...application }

        tempObj.status = "Operations"
        tempObj.subStatus = "New"
        tempObj.withOperationsDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.caseDetail = { ...caseDetail }

        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Operations"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Operations",
                    subStatus: "New",
                    date: tempObj.withOperationsDate,
                    ref: "INCOGNITO_WITH_OPERATIONS_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_OP_USER_WITH_OPERATIONS_LEADS/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                await remove(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`))
                setApplication({ ...tempObj })
                setSnackBar({ visibility: true, severity: "success", message: "Lead sent to Operations!" })
                setTimeout(() => {
                    setSubmitting(false)
                    navigate(-1)
                }, 3000)
            }
        }, { onlyOnce: true })
    }

    const handleChangeNF = (value, label, index) => {
        let array = [...caseDetail.nfInformation]
        let obj = { ...array[index] }
        obj[label] = value
        array[index] = { ...obj }
        caseDetail.nfInformation = [...array]

        setCaseDetail({ ...caseDetail })
    }

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    const handleOutstandingCase = async () => {
        if (!application.outstandingReason) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the reason!" })
            return
        }
        setSubmitting(true)
        application.caseDetail = { ...caseDetail }
        application.outstandingDate = moment().format("DD-MM-YYYY h:mm a")
        
        await set(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Marked outstanding!" })
        setOutstandingModal(false)
    }

    const handleCheckPincode = () => {

        let dbRef = null

        if (selectedPincodeBank === "Credila") {
            dbRef = ref(firebaseDatabaseAdmin, `CREDILA_PINCODES/${pincode}`)
        } else if (selectedPincodeBank === "IDFC") {
            dbRef = ref(firebaseDatabaseAdmin, `IDFC_PINCODES/${pincode}`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                alert("Area functional.")
            } else {
                alert("Area not functional.")
            }
        }, { onlyOnce: true })
    }

    const handleCheckUniversity = () => {
        let arr = []

        let dbRef = null

        if (selectedListBank === "Union Bank") {
            dbRef = ref(firebaseDatabaseAdmin, `UNION_BANK_LIST`)
        } else if (selectedListBank === "Avanse (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_STEM_LIST`)
        } else if (selectedListBank === "Avanse (MBA)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_MBA_LIST`)
        } else if (selectedListBank === "Axis MS (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_MS_STEM`)
        } else if (selectedListBank === "Axis Other Master (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_OTHER_MASTER_STEM`)
        } else if (selectedListBank === "Prodigy") {
            dbRef = ref(firebaseDatabaseAdmin, `PRODIGY_SUPPORTED_UNIVERSITIES`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    if (snap.child(key).val().toLowerCase().includes(searchWord.toLowerCase())) {
                        console.log("here")
                        arr.push(snap.child(key).val())
                    }
                }
                setMatchingResult(arr)
            }
        }, { onlyOnce: true })
    }

    return (
        <div className={Styles.advisorCaseDetailContainer}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>Case Detail</h1>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Student Details" {...a11yProps(0)} />
                    <Tab label="Academic Details" {...a11yProps(1)} />
                    <Tab label="Co-Applicant Details" {...a11yProps(2)} />
                    <Tab label="Non-Financial Applicants" {...a11yProps(3)} />
                </Tabs>
            </div>

            {
                (application.reAssignmentDetail && application.reAssignmentDetail.reason === "Advior In-active")
                &&
                <div style={{ backgroundColor: "#98fab2", padding: 15, borderRadius: 10, marginTop: 20, marginBottom: 20 }}>
                    <p>This lead was forwarded to you from <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.previousAdvisorName}</span>. The reason for reassignemnt is <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.reason}</span>.</p>
                </div>
            }

            <div className={Styles.formListContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={caseDetail.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                        <InputContainer value={caseDetail.fatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, fatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.motherName} onChange={(event) => { setCaseDetail({ ...caseDetail, motherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.requiredLoanAmount} readOnly className={Styles.inputField} placeholder="Loan Amount Required" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Total cost of education</p>
                            <input value={caseDetail.costOfEducation ? caseDetail.costOfEducation.replace(/[^0-9]+/g, "") : ""} onChange={(event) => { setCaseDetail({ ...caseDetail, costOfEducation: event.target.value }) }} className={Styles.inputField} placeholder="Total cost of education" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Marital Status</p>
                            <Select style={{ width: "100%" }} value={caseDetail.maritalStatus} onChange={(event) => { setCaseDetail({ ...caseDetail, maritalStatus: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Divorced">Divorced</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Pan Number</p>
                            <input value={caseDetail.panNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, panNumber: event.target.value }) }} className={Styles.inputField} placeholder="Pan Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ gridColumn: "1/4" }}>
                            <InputContainer value={caseDetail.currentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, currentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ gridColumn: "1/4" }}>
                            <InputContainer value={caseDetail.permanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, permanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                    </div>
                }

                {
                    value === 1
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={caseDetail.tenth} onChange={(event) => { setCaseDetail({ ...caseDetail, tenth: event.target.value }) }} className={Styles.inputField} placeholder="10th" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.twelfth} onChange={(event) => { setCaseDetail({ ...caseDetail, twelfth: event.target.value }) }} className={Styles.inputField} placeholder="12th" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.graduation} onChange={(event) => { setCaseDetail({ ...caseDetail, graduation: event.target.value }) }} className={Styles.inputField} placeholder="Graduation" InputProps={{ disableUnderline: true }} variant="standard" />

                        {
                            application.examsWritten
                            &&
                            application.examsWritten.map((item, index) => {
                                return (
                                    <InputContainer value={caseDetail[item]} onChange={(event) => { setCaseDetail({ ...caseDetail, [item]: event.target.value }) }} className={Styles.inputField} placeholder={item} InputProps={{ disableUnderline: true }} variant="standard" />
                                )
                            })
                        }

                        <InputContainer value={caseDetail.referenceName} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceName: event.target.value }) }} style={{ gridColumn: "1/2" }} className={Styles.inputField} placeholder="Student Reference Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.referenceNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceNumber: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.referenceOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.referenceAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Student Reference Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                    </div>
                }

                {
                    value === 2
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={caseDetail.coAppName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppName: event.target.value }) }} className={Styles.inputField} placeholder="Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppEmailId} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppEmailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                        <InputContainer value={caseDetail.coAppFatherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppFatherName: event.target.value }) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppMotherName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppMotherName: event.target.value }) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppQualification} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppQualification: event.target.value }) }} className={Styles.inputField} placeholder="Qualification" InputProps={{ disableUnderline: true }} variant="standard" />

                        <InputContainer value={caseDetail.coAppNoOfDependents} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppNoOfDependents: event.target.value }) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppYearsInCurrentHouse} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentHouse: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ gridColumn: "1/4" }}>
                            <InputContainer value={caseDetail.coAppCurrentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppCurrentAddress: event.target.value }) }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ gridColumn: "1/4" }}>
                            <InputContainer value={caseDetail.coAppPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <InputContainer value={caseDetail.coAppYearsInCurrentOffice} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppYearsInCurrentOffice: event.target.value }) }} className={Styles.inputField} placeholder="No. of years in current office" InputProps={{ disableUnderline: true }} variant="standard" />
                        <div style={{ gridColumn: "2/4" }}>
                            <InputContainer value={caseDetail.coAppOfficeAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppOfficeAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Office Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <InputContainer value={caseDetail.coAppRefName} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefName: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppRefPhoneNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={caseDetail.coAppRefOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Reference's Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                        <div style={{ gridColumn: "1/4" }}>
                            <InputContainer value={caseDetail.coAppRefPermanentAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, coAppRefPermanentAddress: event.target.value }) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Reference's Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                    </div>
                }

                {
                    value === 3
                    &&
                    <div className={Styles.subFormContainer}>
                        {
                            application.nonFinancialCoApplicants.map((item, index) => {
                                return (
                                    <>
                                        {
                                            item === "Father" && (application.whyFatherNotFin === "Divorced" || application.whyFatherNotFin === "Deceased")
                                                ?
                                                null
                                                :
                                                item === "Mother" && (application.whyMotherNotFin === "Divorced" || application.whyMotherNotFin === "Deceased")
                                                    ?
                                                    null
                                                    :
                                                    <>
                                                        <p className={Styles.nfRelationLabel}>{item}</p>
                                                        <div className={Styles.formContainer} style={{ marginBottom: 20 }}>
                                                            <InputContainer value={caseDetail.nfInformation[index].name} onChange={(event) => { handleChangeNF(event.target.value, "name", index) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            <InputContainer value={caseDetail.nfInformation[index].phoneNumber} onChange={(event) => { handleChangeNF(event.target.value, "phoneNumber", index) }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            <InputContainer value={caseDetail.nfInformation[index].emailId} onChange={(event) => { handleChangeNF(event.target.value, "emailId", index) }} className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />

                                                            <InputContainer value={caseDetail.nfInformation[index].fatherName} onChange={(event) => { handleChangeNF(event.target.value, "fatherName", index) }} className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            <InputContainer value={caseDetail.nfInformation[index].motherName} onChange={(event) => { handleChangeNF(event.target.value, "motherName", index) }} className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            <InputContainer value={caseDetail.nfInformation[index].qualification} onChange={(event) => { handleChangeNF(event.target.value, "qualification", index) }} className={Styles.inputField} placeholder="Occupation" InputProps={{ disableUnderline: true }} variant="standard" />

                                                            <InputContainer value={caseDetail.nfInformation[index].noOfDependents} onChange={(event) => { handleChangeNF(event.target.value, "noOfDependents", index) }} className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            <InputContainer value={caseDetail.nfInformation[index].noOfYearInCurrentHouse} onChange={(event) => { handleChangeNF(event.target.value, "noOfYearInCurrentHouse", index) }} className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />

                                                            <div style={{ gridColumn: "1/4" }}>
                                                                <InputContainer value={caseDetail.nfInformation[index].currentAddress} onChange={(event) => { handleChangeNF(event.target.value, "currentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            </div>

                                                            <div style={{ gridColumn: "1/4" }}>
                                                                <InputContainer value={caseDetail.nfInformation[index].permanentAddress} onChange={(event) => { handleChangeNF(event.target.value, "permanentAddress", index) }} style={{ gridColumn: "1/4" }} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                            </div>
                                                        </div>
                                                    </>
                                        }

                                    </>
                                )
                            })
                        }
                    </div>
                }
            </div>

            <div className={Styles.buttonsTypesContainer}>
                {
                    !application.outstandingDate
                    &&
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <div>
                            <p onClick={() => { setPincodeModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved Pincode</p>
                            <p onClick={() => { setUbListModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved University</p>
                        </div>
                        <div className={Styles.buttonsContainer}>
                            <Button onClick={() => { setOutstandingModal(true) }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Mark Outstanding</Button>
                            <Button onClick={() => { handleSave() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                            <Button onClick={() => { handleSendToOp() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Send to Operations</Button>
                        </div>
                    </div>
                }
            </div>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>

            {/* Outstanding Modal */}
            <Modal open={outstandingModal} onClose={() => { setOutstandingModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the reason</p>
                        <input value={application.outstandingReason} onChange={(event) => { setApplication({ ...application, outstandingReason: event.target.value }) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setOutstandingModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleOutstandingCase() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={pincodeModal} onClose={() => { setPincodeModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}  style={{ alignItems: "flex-start" }}>
                        <p>Select Bank</p>
                        <Select value={selectedPincodeBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedPincodeBank(event.target.value) }}>
                            {
                                ["IDFC"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop:30 }}>Please enter the student's pincode below</p>
                        <input value={pincode} onChange={(event) => { setPincode(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPincodeModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckPincode() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={ubListModal} onClose={() => { setUbListModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer} style={{ alignItems: "flex-start" }}>

                        <p>Select Bank</p>
                        <Select value={selectedListBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedListBank(event.target.value) }}>
                            {
                                ["Union Bank", "Avanse (STEM)", "Avanse (MBA)", "Axis MS (STEM)", "Axis Other Master (STEM)", "Prodigy"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop: 30 }}>Please enter the student's university below</p>
                        <input readOnly={selectedListBank === ""} value={searchWord} onChange={(event) => { setSearchWord(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />


                        <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 30, color: "green" }}>Matching Result</p>
                        {
                            matchingResult.length === 0
                            ?
                            <p style={{color:"#ccc", fontStyle:"italic", fontWeight:500}}>No matching universities</p>
                            :
                            <>
                                <div style={{ display: "flex", flexDirection: 'row', gap: 10, maxWidth: 600, flexWrap: "wrap" }}>
                                    {
                                        matchingResult.slice(0, 5).map((item) => {
                                            return (
                                                <p style={{ backgroundColor: "#a6f7aa", padding: "2px 10px 2px 10px", borderRadius: 10, fontWeight: 400, color: "#02b50b" }}>{item}</p>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    matchingResult.length > 5
                                    &&
                                    <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 10, color: "green" }}>...and {matchingResult.length - 5} more!</p>
                                }
                            </>
                        }

                        <div className={Styles.buttonsContainer} style={{ width: "100%", justifyContent: "center" }}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setUbListModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckUniversity() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default AdvisorCaseDetailIncognito