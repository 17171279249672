import React, { useContext, useEffect, useState } from "react";
import Styles from "./FutureIntake.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Input, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { ref, onValue, query, orderByChild, limitToFirst, limitToLast } from 'firebase/database'
import { IoCall, IoSearch } from "react-icons/io5"
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { animate, useMotionValue, useTransform, motion } from "framer-motion";
import _ from "lodash";

const FutureIntake = () => {

    const [visibleList, setVisibleList] = useState([]);
    const [userData, setUserData] = useContext(UserContext);
    const [value, setValue] = React.useState(0);
    const [subValue, setSubValue] = React.useState(0);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([])
    const [activeList, setActiveList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [incognitoList, setIncognitoList] = useState([])


    useEffect(() => {
        let temp = []
        let temp2 = []

        onValue(ref(firebaseDatabase, `NEW_LEADS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if (item.subStatus === "Future Intake") {
                        temp.push(item)
                        if (item.type === "INBOUND") {
                            temp2.push(item)
                        }
                    }
                }
                setActiveList(temp2)
                setList(temp)
                setLoading(false)
            }
        }, {onlyOnce:true})
        
    }, [])

    const handleChange = async (event, newValue) => {
        setValue(newValue)

        if (newValue === 0) {
            setSubValue(0);

            let temp = []
            for (const index in list) {
                let item = list[index]

                if (item.type === "INBOUND") {
                    temp.push(item)
                } 
            }

            setActiveList(temp)
        } else {
            if (isLoaded) {
                setActiveList(incognitoList)
            } else {
                let temp = []
    
                onValue(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}`), (snap) => {
                    if (snap.exists()) {
                        for (const key in snap.val()) {
                            let item = snap.child(key).val()
                            if (item.subStatus === "Future Intake") {
                                temp.push(item)
                            }
                        }
                        setActiveList(temp)
                        setIncognitoList(temp)
                        setIsLoaded(true)
                        setLoading(false)
                    }
                }, {onlyOnce:true})
            }
        }
    }

    const handleSubChange = async (event, newValue) => {
        setSubValue(newValue);

        let temp = []
        for (const index in list) {
            let item = list[index]

            if ((newValue === 0 && item.type === "INBOUND") || (newValue === 1 && item.type === "OUTBOUND")) {
                temp.push(item)
            } 
        }

        setActiveList(temp)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C" }}>Future Intake Leads</h1>

            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{marginTop:40}} >
                <Tab label="Calling Details" {...a11yProps(0)} />
                <Tab label="Incognito Leads" {...a11yProps(1)} />
            </Tabs>

            {
                value === 0
                &&
                <Tabs value={subValue} onChange={handleSubChange} aria-label="basic tabs example" style={{marginTop:40}} >
                    <Tab label="Inbound Lead" {...a11yProps(0)} />
                    <Tab label="Outbound Leads" {...a11yProps(1)} />
                </Tabs>
            }

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>UID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Country</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Last Call Date</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Assg. Date</TableCell>
                                        <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Intake Type</TableCell>
                                        <TableCell style={{ backgroundColor: "rgba(1, 1, 1, 0)" }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ backgroundColor: "white" }}>
                                    {
                                        activeList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{item.key}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredCountry}</TableCell>
                                                    <TableCell>{item.lastCallDate}</TableCell>
                                                    <TableCell>{item.newFromAdminDate.split(" ")[0]}</TableCell>
                                                    <TableCell>{item.futureIntakeType}</TableCell>
                                                    <TableCell><IoCall size={20} color="#352F6C" onClick={() => { navigate("/future-intake/future-intake-lead-detail", { state: { item: item } }) }} style={{ cursor: "pointer" }} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default FutureIntake