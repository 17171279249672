import React from "react";
import Styles from "./Notifications.module.css";
import { GoDotFill } from "react-icons/go";
import moment from "moment/moment";
import _ from 'lodash';
import { FaRegCircleCheck } from "react-icons/fa6";


const Notifications = ({ notificationList, loading, noDataFound }) => {

    const notificationGroup = {}
    _.forEach(notificationList, (item) => {
        const date = moment(item.timeStamp).format("DD-MM-YYYY")
        notificationGroup[date]
            ?
            notificationGroup[date].push(item)
            :
            notificationGroup[date] = [item]
    })


    return (
        <div className={Styles.notificationsSection}>
            <h1 className={Styles.sectionTitle}>Notifications</h1>

            <div className={Styles.notificationListContainer}>
                {
                    Object.keys(notificationGroup).map((item, index) => {
                        return <DayWiseNotificationBlock key={item} notifications={notificationGroup[item]} date={item} />
                    })
                }
                {
                    noDataFound
                    &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', gap: '12px', transform: "translateY(-30px)" }}>
                        <FaRegCircleCheck size={50} />

                        <h3>All Caught Up</h3>
                    </div>
                }

            </div>

        </div>
    )
}

const DayWiseNotificationBlock = ({ notifications, date }) => {

    return (
        <div className={Styles.dayWiseNotificatonBlockContainer}>
            <p>{date}</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '4px' }}>
                {
                    notifications.map(item => {
                        return (
                            <div className={Styles.notificationContainer}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '12px', flexDirection: "row" }}>
                                    <GoDotFill size={12} />
                                    <p>{item.message}</p>
                                </div>
                                <p className={Styles.timeStamp}>{moment(item.timeStamp).format("DD-MM-YYYY h:mm a")}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Notifications;