import React, { useContext, useEffect, useState } from "react";
import Styles from "./VendorOnHoldLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, Snackbar, Alert, Stack, Select, MenuItem, CircularProgress } from "@mui/material";
import { off, onValue, ref, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { HiDocumentCheck } from "react-icons/hi2"
import { IoMdInformationCircle } from "react-icons/io"
import moment from "moment";

const VendorOnHoldLeads = () => {

    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const navigate = useNavigate()
    const [infoModal, setInfoModal] = useState(false)
    const [advisorDetail, setAdvisorDetail] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect (() => {
        
        let onValueRef = ref(firebaseDatabase, `VENDOR_FRESH_LEADS/${userData.nbfcName}`)
        let temp = []
        let temp2 = []

        onValue(onValueRef, async (snap) => {
            if (snap.exists()) {
                temp = []
                for (const key in snap.val()) {
                    let obj = snap.child(key).val()
                    if (obj.assignedRmUid === userData.uid && obj.status === "On-hold") {
                        temp.push(obj)
                    }
                }
                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.rmAssignmentDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.rmAssignmentDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }
            setVisibleList(temp2)
            setLoading(false)
        })

        return () => {
            off(onValueRef, "value")
        }
    }, [])

    const handleAdvisorInfo = (application) => {
        onValue(ref(firebaseDatabase, `USER_ARCHIVE/${application.assignedAdvisorUid}`), (snap) => {
            if (snap.exists()) {
                setAdvisorDetail(snap.val())
                setInfoModal(true)
            }
        }, {onlyOnce:true})
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>On-hold Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>PAN Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>SC Advisor</TableCell>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.customerId}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.rmAssignmentDate.split(" ")[0]}</TableCell>
                                                    <TableCell>{item.panNumber}</TableCell>
                                                    <TableCell><div style={{display:"flex", flexDirection:"row", alignItems:"center"}}><p>{item.assignedAdvisorName}</p><IoMdInformationCircle size={20} color="#352F6C" onClick={()=>{handleAdvisorInfo(item)}} style={{cursor:"pointer"}} /></div></TableCell>
                                                    <TableCell><HiDocumentCheck onClick={()=>{navigate("/vendor-on-hold-leads/vendor-complete-detail", {state:{item:item}})}} color={"#352F6C"} size={25} style={{cursor:"pointer", display:item.applicationId?"none":"flex"}} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>

            <Modal open={infoModal} onClose={() => { setInfoModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Advisor Detail</p>
                        <div className={Styles.infoContainer}>
                            <p className={Styles.infoItem}>Name</p>
                            <p className={Styles.infoItem}>{advisorDetail.name}</p>

                            <p className={Styles.infoItem}>Phone Number</p>
                            <p className={Styles.infoItem}>{advisorDetail.phoneNumber}</p>

                            <p className={Styles.infoItem}>Email-Id</p>
                            <p className={Styles.infoItem}>{advisorDetail.emailId}</p>
                        </div>

                        <Button onClick={() => {setInfoModal(false)}} variant='contained' className={Styles.primaryButton}>Okay</Button>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default VendorOnHoldLeads