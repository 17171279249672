import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiManagerNewLeads.module.css"
import UserContext from "../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { Box, Button, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { HiDocumentDownload } from "react-icons/hi";

const HiManagerNewLeads = ({activeList}) => {

    const [visibleList, setVisibleList] = useState([])
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserContext)

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        let temp = []

        if (newValue === 0) {
            
            for (const index in activeList) {
                let obj = activeList[index]

                if (obj.type === "INBOUND") {
                    temp.push(obj)
                }
            }
        } else if (newValue === 1) {
            for (const index in activeList) {
                let obj = activeList[index]

                if (obj.type === "OUTBOUND") {
                    temp.push(obj)
                }
            }
        } 

        setVisibleList(temp)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleExport = () => {
        let data = []
        let date = moment().format("DD-MM-YYYY")
        for (const [index, item] of activeList.entries()) {
            let tempObj = {
                "Customer Name": item.name || "",
                "Phone Number": item.phoneNumber || "",
                "Email ID": item.emailId || "",
                "Country": item.desiredCountry || "",
                "University": item.desiredUniversity || "",
                "SC Advisor": item.assignedAdvisorName || "",
                "Upload Date": item.date || "",
                "Assignment Date": item.newFromAdminDate || "",
                "Status": item.status || "",
                "Sub-status": item.subStatus || "",
                "Source": item.source || "",
                "Source Description": item.sourceDescription || ""
            }
            data.push(tempObj)
        }
        
        const fileName = 'All HI New Leads: ' + date
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
    }

    const handleClick = (item) => {
        if (userData.position === "Manager") {
            navigate("/health-insurance/lead-status/complete-lead-detail", {state:{item:item}})
        } else {
            navigate("/all-hi-lead-status/complete-lead-detail", {state:{item:item}})
        }
    }

    return (
        <div style={{display:"flex", flexDirection:"column"}}>
            <Button onClick={() => { handleExport() }} startIcon={<HiDocumentDownload />} style={{ backgroundColor: "#8069B4", alignSelf:"flex-end" }} size='medium' variant='contained'>Export New Leads</Button>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Inbound Lead" {...a11yProps(0)} />
                    <Tab label="Outbound Leads" {...a11yProps(1)} />
                </Tabs>
            </div>

            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Upload Date</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last Call</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Next Call</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor Name</TableCell>
                                <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{backgroundColor:"white"}}>
                            {
                                visibleList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.desiredUniversity}</TableCell>
                                            <TableCell>{item.newFromAdminDate?item.newFromAdminDate.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.lastCallDate?item.lastCallDate.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.nextCallDateTime?item.nextCallDateTime.split(" ")[0]:""}</TableCell>
                                            <TableCell>{item.assignedAdvisorName}</TableCell>
                                            <TableCell><Button onClick={()=>{handleClick(item)}} variant="contained" style={{backgroundColor:"#352F6C"}}>View</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default HiManagerNewLeads