import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiAdvisorRescheduledLeadDetail.module.css"
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import InputContainer from "../InputContainer/InputContainer.component";
import { Alert, Box, Button, MenuItem, Modal, Select, Snackbar, Switch, Tab, Tabs } from "@mui/material";
import moment from "moment";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseFidesure, firebaseDatabaseSC } from "../../backend/firebaseHandler";
import CryptoJS from "crypto-js";
import CustomAutoComplete from "../CustomAutoComplete/CustomAutoComplete.component";

const HiAdvisorRescheduledLeadDetail = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [userData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);
    const [submitting, setSubmitting] = useState(false)
    const [webinarList, setWebinarList] = useState([])
    const [planListModal, setPlanListModal] = useState(false)
    const [followUpDateModal, setFollowUpDateModal] = useState(false)
    const [moreInformationModal, setMoreInformationModal] = useState(false)
    const [webinarModal, setWebinarModal] = useState(false)
    const [parentsModal, setParentsModal] = useState(false)
    const [competitorModal, setCompetitorModal] = useState(false)
    const [callBackModal, setCallBackModal] = useState(false)
    const [languageModal, setLanguageModal] = useState(false)
    const [purchaseFromUsModal, setPurchasedFromUsModal] = useState(false)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const languageList = ["Hindi", "English", "Punjabi", "Marathi", "Kannada", "Tamil", "Telugu"]
    const [language, setLanguage] = useState("")
    const [qrLink, setQrLink] = useState("")
    const [universities, setUniversities] = useState([])
    const [scList, setScList] = useState([])
    const [fidesureList, setFidesureList] = useState([])

    useEffect(() => {
        setQrLink(encrypt(`HI_RESCHEDULED_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
    
        const dbRef = ref(firebaseDatabase, `HI_RESCHEDULED_LEADS/${userData.uid}/${application.phoneNumber}`)
        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                let item = snap.val()

                item.dob = (!item.dob||item.dob==="")?"":moment(item.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
                item.travelDate = (!item.travelDate||item.travelDate==="")?"":moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                item.nextCallDateTime = moment(item.nextCallDateTime, "DD-MM-YYYY").format("YYYY-MM-DD")

                setApplication({...item})
            }
        }, {onlyOnce:false})

        return () => {
            off(dbRef, "value")
        }
    }, [])

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    const handleSubmit = async () => {
        if (application.status === "Interested") {
            if (application.name === "") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the customer's name" })
                return
            }
            if (application.emailId === "") {
                setApplication({...application, emailId:"dummy_placeholder@studentcover.in"})
            }
            if (application.travelDate === "") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the tentative travel date" })
                return
            }

            switch (application.subStatus) {
                case "Ready to purchase": {
                    if (application.desiredCountry === "") {
                        setSnackBar({ visibility: true, severity: "warning", message: "Please select the country" })
                        return
                    }

                    if (application.desiredUniversity === "") {
                        setSnackBar({ visibility: true, severity: "warning", message: "Please select the university" })
                        return
                    }
                    setPlanListModal(true)
                    break;
                }

                case "Would purchase before travel": {
                    setFollowUpDateModal(true)
                    break
                }

                case "Would purchase after travel": {
                    setFollowUpDateModal(true)
                    break
                }

                case "More Information Required": {
                    setMoreInformationModal(true)
                    break
                }

                case "Would attend a webinar and decide": {
                    fetchWebinars()
                    break
                }

                case "Wants to connect with their parents": {
                    setParentsModal(true)
                    break
                }

                case "Would purchase in a group": {
                    setFollowUpDateModal(true)
                    break
                }

                default: {}
            }
        } else if (application.status === "Not Interested") {
            switch (application.subStatus) {
                case "Have purchased from a competitor": {
                    setCompetitorModal(true)
                    break
                }
                case "Would be purchasing from university": {
                    moveToClosed()
                    break
                }
                case "Would be purchasing from competitor": {
                    setCompetitorModal(true)
                    break
                }
                case "No plans of traveling": {
                    moveToClosed()
                    break
                }

                default: {}
            }
        } else if (application.status === "Plan not accepted in the University") {
            moveToClosed()
        } else if (application.status === "Visa Rejected") {
            switch (application.subStatus) {
                case "Will re-apply": {
                    if (application.travelDate === "") {
                        setSnackBar({ visibility: true, severity: "warning", message: "Please enter the tentative travel date" })
                        return
                    }
                    moveToClosed ()
                    break;
                }
                case "Will not re-apply": {
                    moveToClosed ()
                    break;
                }
                default: {}
            }
        } else if (application.status === "No Response") {
            moveToClosed()
        } else if (application.status === "Call Back Requested") {
            setCallBackModal(true)
        } else if (application.status === "Invalid/Wrong Number") {
            moveToClosed()
        } else if (application.status === "DND") {
            moveToClosed()
        } else if (application.status === "Language Barrier") {
            setLanguageModal(true)
        } else if (application.status === "Already purchased from us") {
            setPurchasedFromUsModal(true)
        }
    }

    const fetchWebinars = async () => {

        setSubmitting (true)

        let temp = []

        const todaysDateMoment = moment();

        onValue((ref(firebaseDatabaseSC, "EVENTS")), (snapShot) => {
            if (snapShot.exists()) {

                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val();
                    const eventDateMoment = moment(item.eventDate[0], "MMMM Do YYYY")
                    if (item.status === "Live" && eventDateMoment.diff(todaysDateMoment, "days") >= 0) {
                        temp.push(item.eventTitle + " " + item.eventName)
                    } 
                }

                setWebinarList(temp)
                setSubmitting(false)
                setWebinarModal(true)
            }
        }, {onlyOnce: true})

    }

    const moveToInterested = async () => {

        let tempObj = { ...application }

        if (application.subStatus === "Ready to purchase") {
            // if (application.selectedPlanName === "") {
            //     setSnackBar({ visibility: true, severity: "warning", message: "Please select the plan name" })
            //     return
            // }
        }

        if (application.subStatus === "More Information Required") {
            // if (!application.meetingWithManagerDate) {
            //     setSnackBar({ visibility: true, severity: "warning", message: "Please enter the date for meeting with manager" })
            //     return
            // }

            // if (!application.meetingWithManagerTime) {
            //     setSnackBar({ visibility: true, severity: "warning", message: "Please enter the time for meeting with manager" })
            //     return
            // }

            tempObj.meetingWithManagerDate = tempObj.meetingWithManagerDate?moment(tempObj.meetingWithManagerDate).format("DD-MM-YYYY"):""
        }

        if (application.subStatus === "Wants to connect with their parents") {
            if (!application.parentsMeetingInfo || !application.parentsMeetingInfo.date) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the date for meeting with parents" })
                return
            }
            if (!application.parentsMeetingInfo || !application.parentsMeetingInfo.time) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the time for meeting with parents" })
                return
            }
            if (!application.parentsMeetingInfo || !application.parentsMeetingInfo.name) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter parent`s name" })
                return
            }
            if (!application.parentsMeetingInfo || !application.parentsMeetingInfo.phoneNumber) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter parent`s phone number" })
                return
            }

            tempObj.parentsMeetingInfo.date = moment(tempObj.parentsMeetingInfo.date).format("DD-MM-YYYY")
        }

        if (application.nextCallDateTime === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please select the follow-up date" })
            return
        }

        if (application.nextCallMode === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please select the follow-up mode" })
            return
        }

        setSubmitting(true)

        // console.log(application)
        
        tempObj.interestedDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime).format("DD-MM-YYYY")
        tempObj.travelDate = moment(tempObj.travelDate).format("DD-MM-YYYY")
        if (tempObj.dob!=="") {
            tempObj.dob = moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        }

        await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = application.status

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: application.status,
                    subStatus: application.subStatus,
                    date: tempObj.interestedDate,
                    ref: "HI_INTERESTED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]

                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)
                await remove(ref(firebaseDatabase, `HI_RESCHEDULED_LEADS/${userData.uid}/${tempObj.phoneNumber}`))

                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const moveToClosed = async () => {
        let tempObj = { ...application }

        if (application.subStatus === "Have purchased from a competitor" || application.subStatus === "Would be purchasing from competitor") {
            if (application.competitorName === "") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the competitor name" })
                return
            }
        }

        if (application.status === "Already purchased from us") {
            if (!application.purchasedPlanName) {
                setSnackBar({ visibility: true, severity: "warning", message: "Please enter the plan name" })
                return
            }
        }

        setSubmitting(true)

        tempObj.status = "Closed"
        tempObj.subStatus = application.status + " - " + application.subStatus
        tempObj.closedDate = moment().format("DD-MM-YYYY h:mm a")

        if (application.status === "Plan not accepted in the University" || application.status === "Invalid/Wrong Number" || application.status === "DND" || application.status === "Already purchased from us") {
            tempObj.subStatus = application.status
        }

        if (application.status === "Language Barrier") {
            tempObj.subStatus = application.status + " - " + language
        }

        await set(ref(firebaseDatabase, `HI_CLOSED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Closed"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Closed",
                    subStatus: tempObj.subStatus,
                    date: tempObj.closedDate,
                    ref: "HI_CLOSED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)

                await remove(ref(firebaseDatabase, `HI_RESCHEDULED_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                setSubmitting(false)
                navigate(-1)
                setSubmitting(false)
            }
        }, { onlyOnce: true })        
    }

    const moveToCallBack = async () => {
        let tempObj = { ...application }

        if (application.nextCallDateTime === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please select the call back date" })
            return
        }

        if (application.nextCallMode === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please select the call back mode" })
            return
        }

        setSubmitting(true)

        tempObj.status = "Rescheduled"
        tempObj.rescheduledDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.nextCallDateTime = moment(tempObj.nextCallDateTime).format("DD-MM-YYYY")
        if (tempObj.dob!=="") {
            tempObj.dob = moment(tempObj.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
        }

        await set(ref(firebaseDatabase, `HI_RESCHEDULED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)

        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Rescheduled"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Rescheduled",
                    subStatus: tempObj.nextCallDateTime,
                    date: tempObj.rescheduledDate,
                    ref: "HI_RESCHEDULED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)
                setSubmitting(false)
                navigate(-1)
                setSubmitting(false)
            }
        }, { onlyOnce: true })        
    }

    const handleLanguageSelect = async () => {
        let userList = []

        onValue(ref(firebaseDatabase, `USER_ARCHIVE`), async (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let user = snap.child(key).val()

                    if (user.position === "Advisor" && user.languages.includes(language) && user.department === "Health Insurance") {
                        userList.push(user)
                    }
                }

                if (userList.length !== 0) {
                    let newAdvisor = userList[Math.floor(Math.random() * userList.length)]
                    let tempObj = { ...application }
                    if (newAdvisor.uid !== userData.uid) {
                        tempObj.assignedAdvisorUid = newAdvisor.uid
                        tempObj.assignedAdvisorName = newAdvisor.name
                        tempObj.assignedAdvisorPhoneNumber = newAdvisor.phoneNumber
                        tempObj.status = "New"
                        tempObj.reAssigningDate = moment().format("DD-MM-YYYY h:mm a")
                        tempObj.subStatus = "Forwarded - " + language
                        tempObj.forwardedBy = { "position": "Advisor", "name": userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid }
                        await set(ref(firebaseDatabase, `HI_NEW_LEADS/${newAdvisor.uid}/${tempObj.phoneNumber}`), tempObj)
                        await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${application.phoneNumber}`), tempObj)
                        await remove(ref(firebaseDatabase, `HI_RESCHEDULED_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                    }

                    onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), async (snap) => {
                        if (snap.exists()) {
                            let lifelineObj = snap.val()

                            let array = [...lifelineObj.lifeline]
                            let arrayItem = {
                                actionBy: userData.uid,
                                status: "New",
                                subStatus: "Reassigned due to language - " + language,
                                date: moment().format("DD-MM-YYYY h:mm a"),
                                ref: "NEW_LEADS",
                                sentTo: tempObj.assignedAdvisorUid
                            }
                            array.push(arrayItem)
                            lifelineObj.lifeline = [...array]
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${application.product}/${application.phoneNumber}`), lifelineObj)
                            setSubmitting(false)
                            navigate(-1)
                        }
                    }, { onlyOnce: true })
                } else {
                    moveToClosed()
                }
            }
        }, { onlyOnce: true })
    }

    const handleTravelingTo = (value) => {
        let temp = []
        
        setApplication({...application, desiredCountry: value})
        setSubmitting(true)
        if (value) {
            onValue(ref(firebaseDatabaseSC, "COUNTRY_WISE_UNIVERSITIES/"+value), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        temp.push(snapShot.child(key).val())
                        
                    }
                    setUniversities(temp)
                    setSubmitting(false)
                }
            }, {onlyOnce:true})
        } else {
            setUniversities(temp)
            setSubmitting(false)
        }
    }

    const handleUniversity = (university) => {
        let temp = []
        let temp2 = []
        setApplication({...application, desiredUniversity: university})
        setSubmitting(true)
        
        if (university) {
            onValue(ref(firebaseDatabaseSC, `HEALTH_PLANS/${university}/plans`), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        temp.push(snapShot.child(key).val()) 
                    }
                    setScList(temp)
                    setSubmitting(false)
                } else {
                    setScList(temp)
                    setSubmitting(false)
                }
            }, {onlyOnce:true})

            onValue(ref(firebaseDatabaseFidesure, `UNIVERSITY_PLANS/${university}/plans`), (snapShot) => {
                console.log("in")
                console.log(snapShot.val())
                
                if (snapShot.exists()) {
                    console.log("if")
                    for (const key in snapShot.val()) {
                        console.log(key)
                        temp2.push(snapShot.child(key).child("name").val())
                    }
                    setFidesureList(temp2)
                    setSubmitting(false)
                } else {
                    console.log("else")
                    setFidesureList(temp2)
                    setSubmitting(false)
                }
            }, {onlyOnce:true})
        } else {
            setScList(temp)
            setFidesureList(temp2)
            setSubmitting(false)
        }
    }


    return (
        <div className={Styles.hiRescheduledLeadDetailContainer}>
            <div className={Styles.headerContainer}>

                <h1 style={{ color: "#352F6C" }}>New Lead Detail</h1>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                    </div>
                </div>
            </div>

            <div className={Styles.formContainer}>
            
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Name</p>
                    <input value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Date of Birth</p>
                    <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Email-Id</p>
                    <input value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Visa Category</p>
                    <Select style={{ width: "100%" }} value={application.visaCategory || ""} onChange={(event) => { setApplication({ ...application, visaCategory: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value={"International Student on F1 Visa"} >International Student on F1 Visa</MenuItem>
                        <MenuItem value={"F-1 Student on OPT"} >F-1 Student on OPT</MenuItem>
                        <MenuItem value={"Student on J1 Visa"} >Student on J1 Visa</MenuItem>
                        <MenuItem value={"Non Student"} >Non Student</MenuItem>
                        <MenuItem value={"Dependents on F2/J2"} >Dependents on F2/J2</MenuItem>
                    </Select>
                </div>

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Gender</p>
                    <Select style={{ width: "100%" }} value={application.gender || ""} onChange={(event) => { setApplication({ ...application, gender: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value={"Male"} >Male</MenuItem>
                        <MenuItem value={"Female"} >Female</MenuItem>
                        <MenuItem value={"Other"} >Other</MenuItem>
                    </Select>
                </div>

                <div style={{gridColumn:"1/2"}}>
                    <InputContainer value={application.phoneNumber} onChange={(event) => { }} className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

                <div className={Styles.yesNoContainer} style={{gridColumn:"1/2"}}>
                    <p>Is this whatsapp number?</p>
                    <p style={{ color: application.isThisWhatsappNumber === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "Yes" }) }}>Yes</p>
                    <p>|</p>
                    <p style={{ color: application.isThisWhatsappNumber === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, isThisWhatsappNumber: "No" }) }}>No</p>
                </div>

                {
                    application.isThisWhatsappNumber === "No"
                    &&
                    <InputContainer value={application.whatsappNumber} onChange={(event) => { setApplication({ ...application, whatsappNumber: event.target.value }) }} className={Styles.inputField} placeholder="Whatsapp Number" InputProps={{ disableUnderline: true }} variant="standard" />
                }

                <div style={{ width: "100%", gridColumn:"1/2" }}>
                    <p className={Styles.label}>Country of Study</p>
                    <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>University</p>
                    <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Tentative Travel Date</p>
                    <input type="date" value={application.travelDate} onChange={(event) => { setApplication({ ...application, travelDate: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Call Status</p>
                    <Select style={{ width: "100%" }} value={application.status} onChange={(event) => {setApplication({...application, status:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value={"Interested"} >Interested</MenuItem>
                        <MenuItem value={"Not Interested"} >Not Interested</MenuItem>
                        <MenuItem value={"Plan not accepted in the University"} >Plan not accepted in the University</MenuItem>
                        <MenuItem value={"Visa Rejected"} >Visa Rejected</MenuItem>
                        <MenuItem value={"No Response"} >No Response</MenuItem>
                        <MenuItem value={"Call Back Requested"} >Call Back Requested</MenuItem>
                        <MenuItem value={"Invalid/Wrong Number"} >Invalid/Wrong Number</MenuItem>
                        <MenuItem value={"DND"} >DND</MenuItem>
                        <MenuItem value={"Language Barrier"} >Language Barrier</MenuItem>
                        <MenuItem value={"Already purchased from us"} >Already purchased from us</MenuItem>
                    </Select>
                </div>

                {
                    application.status === "Interested"
                    &&
                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Please specify</p>
                        <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            <MenuItem value={"Ready to purchase"} >Ready to purchase</MenuItem>
                            <MenuItem value={"Would purchase before travel"} >Would purchase before travel</MenuItem>
                            <MenuItem value={"Would purchase after travel"} >Would purchase after travel</MenuItem>
                            <MenuItem value={"More Information Required"} >More Information Required</MenuItem>
                            <MenuItem value={"Would attend a webinar and decide"} >Would attend a webinar and decide</MenuItem>
                            <MenuItem value={"Wants to connect with their parents"} >Wants to connect with their parents</MenuItem>
                            <MenuItem value={"Would purchase in a group"} >Would purchase in a group</MenuItem>
                        </Select>
                    </div>
                }

                {
                    application.status === "Not Interested"
                    &&
                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Please specify</p>
                        <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            <MenuItem value={"Have purchased from a competitor"} >Have purchased from a competitor</MenuItem>
                            <MenuItem value={"Would be purchasing from university"} >Would be purchasing from university</MenuItem>
                            <MenuItem value={"Would be purchasing from competitor"} >Would be purchasing from competitor</MenuItem>
                            <MenuItem value={"No plans of traveling"} >No plans of traveling</MenuItem>
                        </Select>
                    </div>
                }

                {
                    application.status === "No Response"
                    &&
                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Please specify</p>
                        <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            <MenuItem value={"Not answered"} >Not answered</MenuItem>
                            <MenuItem value={"Not reachable"} >Not reachable</MenuItem>
                            <MenuItem value={"Incoming calls not active"} >Incoming calls not active</MenuItem>
                            <MenuItem value={"Call disconnected"} >Call disconnected</MenuItem>
                        </Select>
                    </div>
                }

                {
                    application.status === "Visa Rejected"
                    &&
                    <div style={{ width: "100%" }}>
                        <p className={Styles.label}>Please specify</p>
                        <Select style={{ width: "100%" }} value={application.subStatus} onChange={(event) => {setApplication({...application, subStatus:event.target.value})}} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            <MenuItem value={"Will re-apply"} >Will re-apply</MenuItem>
                            <MenuItem value={"Will not re-apply"} >Will not re-apply</MenuItem>
                        </Select>
                    </div>
                }

                <div style={{ width: "100%", gridColumn:"1/4" }}>
                    <p className={Styles.label}>Remark</p>
                    <input value={application.remarkFromAdvisor} onChange={(event) => { setApplication({ ...application, remarkFromAdvisor: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                </div>

            </div>

            <Button disabled={submitting} onClick={()=>{handleSubmit()}} sx={{marginTop:'40px'}} variant='contained' className={Styles.primaryButton}>Submit</Button>
        
            {/* Plan list modal */}
            <Modal open={planListModal} onClose={() => { setPlanListModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select students desired country of study and university</p>
                        
                        <div style={{display:"flex", flexDirection:"row", gap:20, width:"100%"}}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired Country</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Traveling to"
                                    variant="standard"
                                    value={application.desiredCountry}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleTravelingTo(value)}}
                                    options={["Australia", "Canada", "Germany", "Ireland", "Singapore", "UAE", "United Kingdom", "United States", "Others"]}
                                /> 
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Desired University</p>
                                <CustomAutoComplete
                                    className={Styles.inputField}
                                    label="Select Universities"
                                    variant="standard"
                                    value={application.desiredUniversity}
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(event, value) => {handleUniversity(value)}}
                                    options={universities}
                                /> 
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", gap:20, marginTop:20}}>
                            {
                                scList.length > 0
                                &&
                                <div>
                                    <p style={{fontWeight:500, color:"#352F6C", textAlign:"start"}}>Student Cover</p>

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        {
                                            scList.map((item, index) => {
                                                return (
                                                    <div key={JSON.stringify(item)} className={Styles.itemContainer}>
                                                        <p className={Styles.productName}>{item}</p>
                                                        <Switch value={item} checked={application.selectedPlanName===item} onChange={(event) => { if (event.target.checked) { setApplication({...application, selectedBusiness:"Stuent Cover", selectedPlanName:event.target.value}) } }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }

                            {
                                fidesureList.length > 0
                                &&
                                <div>
                                    <p style={{fontWeight:500, color:"#352F6C", textAlign:"start"}}>Fidesure</p>

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        {
                                            fidesureList.map((item, index) => {
                                                return (
                                                    <div key={JSON.stringify(item)} className={Styles.itemContainer}>
                                                        <p className={Styles.productName}>{item}</p>
                                                        <Switch value={item} checked={application.selectedPlanName===item} onChange={(event) => { if (event.target.checked) { setApplication({...application, selectedBusiness:"Fidesure", selectedPlanName:event.target.value}) } }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <div style={{display:"flex", flexDirection:"row", gap:20, width:"100%"}}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Follow-up date</p>
                                <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Follow-up mode</p>
                                <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value={"Call"} >Call</MenuItem>
                                    <MenuItem value={"Mail"} >Mail</MenuItem>
                                    <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPlanListModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Follow-up date modal */}
            <Modal open={followUpDateModal} onClose={() => { setFollowUpDateModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up date</p>
                            <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up mode</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Call"} >Call</MenuItem>
                                <MenuItem value={"Mail"} >Mail</MenuItem>
                                <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setFollowUpDateModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* More infromation modal */}
            <Modal open={moreInformationModal} onClose={() => { setMoreInformationModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the details for meeting with manager (<span style={{fontWeight:500}}>{application.receivedFrom.name}</span>)</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Meeting date</p>
                            <input value={application.meetingWithManagerDate} type="date" onChange={(event) => { setApplication({ ...application, meetingWithManagerDate: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Meeting time</p>
                            <input value={application.meetingWithManagerTime} type="time" onChange={(event) => { setApplication({ ...application, meetingWithManagerTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{width:"100%", height:1, backgroundColor:"#352F6C", marginTop:20}}></div>

                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Your follow-up date</p>
                            <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up mode</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Call"} >Call</MenuItem>
                                <MenuItem value={"Mail"} >Mail</MenuItem>
                                <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setMoreInformationModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Webinar modal */}
            <Modal open={webinarModal} onClose={() => { setWebinarModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the Webinar to be shared with the student</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Select webinar</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.webinarLink} onChange={(event) => { setApplication({ ...application, webinarLink: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    ["Webinar one", "Webinar two", "Webinar three"].map((item) => {return (
                                        <MenuItem value={item} >{item}</MenuItem>
                                    )})
                                }                                
                            </Select>
                        </div>

                        <div style={{width:"100%", height:1, backgroundColor:"#352F6C", marginTop:20}}></div>

                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up date</p>
                            <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Follow-up mode</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Call"} >Call</MenuItem>
                                <MenuItem value={"Mail"} >Mail</MenuItem>
                                <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setWebinarModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Parents modal */}
            <Modal open={parentsModal} onClose={() => { setParentsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{display:"flex", flexDirection:"row", gap:20, width:450}}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Date for call with parents</p>
                                <input value={application.parentsMeetingInfo?application.parentsMeetingInfo.date:""} type="date" onChange={(event) => { setApplication({ ...application, parentsMeetingInfo:{...application.parentsMeetingInfo, date:event.target.value} }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Time for call with parents</p>
                                <input value={application.parentsMeetingInfo?application.parentsMeetingInfo.time:""} type="time" onChange={(event) => { setApplication({ ...application, parentsMeetingInfo:{...application.parentsMeetingInfo, time:event.target.value} }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", gap:20, width:450}}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Parent's name</p>
                                <input value={application.parentsMeetingInfo?application.parentsMeetingInfo.name:""} onChange={(event) => { setApplication({ ...application, parentsMeetingInfo:{...application.parentsMeetingInfo, name:event.target.value} }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Parent's phone number</p>
                                <input value={application.parentsMeetingInfo?application.parentsMeetingInfo.phoneNumber:""} onChange={(event) => { setApplication({ ...application, parentsMeetingInfo:{...application.parentsMeetingInfo, phoneNumber:event.target.value} }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <div style={{width:"100%", height:1, backgroundColor:"#352F6C", marginTop:20}}></div>

                        <div style={{display:"flex", flexDirection:"row", gap:20, width:450}}>
                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Follow-up date</p>
                                <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                                <p className={Styles.label}>Follow-up mode</p>
                                <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value={"Call"} >Call</MenuItem>
                                    <MenuItem value={"Mail"} >Mail</MenuItem>
                                    <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setParentsModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Competitor modal */}
            <Modal open={competitorModal} onClose={() => { setCompetitorModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Competitor's name</p>
                            <input value={application.competitorName} onChange={(event) => { setApplication({ ...application, competitorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setCompetitorModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToClosed() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Call back modal */}
            <Modal open={callBackModal} onClose={() => { setCallBackModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Call back date</p>
                            <input value={application.nextCallDateTime} type="date" onChange={(event) => { setApplication({ ...application, nextCallDateTime: event.target.value }) }} className={Styles.inputField} placeholder="Email-Id" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Call back mode</p>
                            <Select style={{ width: "100%", textAlign:"start" }} value={application.nextCallMode} onChange={(event) => { setApplication({ ...application, nextCallMode: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value={"Call"} >Call</MenuItem>
                                <MenuItem value={"Mail"} >Mail</MenuItem>
                                <MenuItem value={"Whatsapp"} >Whatsapp</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setCallBackModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToCallBack() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Language modal */}
            <Modal open={languageModal} onClose={() => { setLanguageModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p>Please select customer's preferred language</p>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            {
                                languageList.map((item, index) => {
                                    return (
                                        <div key={JSON.stringify(item)} className={Styles.itemContainer}>
                                            <p className={Styles.productName}>{item}</p>
                                            <Switch value={language === item} checked={language === item} onChange={(event) => { if (event.target.checked) { setLanguage(item) } }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={Styles.modalButtonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setLanguageModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button onClick={() => { handleLanguageSelect() }} variant='contained' className={Styles.primaryModalButton}>Proceed</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Purchased from us modal */}
            <Modal open={purchaseFromUsModal} onClose={() => { setPurchasedFromUsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Enter the below details</p>
                        
                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Plan name</p>
                            <input value={application.purchasedPlanName} onChange={(event) => { setApplication({ ...application, purchasedPlanName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"flex-start", marginTop:20, flex:1 }}>
                            <p className={Styles.label}>Advisor name</p>
                            <input value={application.purchasedAdvisorName} onChange={(event) => { setApplication({ ...application, purchasedAdvisorName: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                    
                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPurchasedFromUsModal(false) }} variant='contained' className={Styles.secondaryModalButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { moveToClosed() }} variant='contained' className={Styles.primaryModalButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default HiAdvisorRescheduledLeadDetail