import React, { useContext, useEffect, useState } from "react";
import Styles from "./NumberRecords.module.css"
import { Box, Button, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import moment from "moment";
import UserContext from "../../../context/UserData.context";

const NumberRecords = () => {

    const [selectedSingleLead, setSelectedSingleLead] = useState({})
    const [visibleList, setVisibleList] = useState([])
    const [filteredAdvisors, setFilteredAdvisors] = useState([])
    const [singleLeadModal, setSingleLeadModal] = useState(false)
    const [selectedSingleAdvisor, setSelectedSingleAdvisor] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [userData] = useContext(UserContext)
    const [alreadyExistsModal, setAlreadyExistsModal] = useState(false)
    const [preExistingFile, setPreExistingFile] = useState({})

    useEffect(() => {
        fetchData()
    }, []) 

    const fetchData = () => {
        let temp = []
        setVisibleList([])

        onValue(ref (firebaseDatabase, `FOR_RECORDING_APPLICATIONS/${userData.uid}`), (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    temp.push(snap.child(key).val())
                }
                setVisibleList(temp)
            }
        }, {onlyOnce:true})
    }

    const filterAdvisors = (lead) => {
        let temp = []

        onValue(ref(firebaseDatabase, "USER_ARCHIVE"), (snapShot) => {
            if (snapShot.exists()) {
                for (const key in snapShot.val()) {
                    let item = snapShot.child(key).val()
                    if (lead.product === "Health Insurance") {
                        if (item.department === "Health Insurance" && (item.position === "Advisor" || item.position === "Manager") && item.status === "Active") {
                            temp.push(item)
                        }
                    } else if (lead.product === "Education Loan") {
                        if (item.department === "Education Loan" && item.position === "Advisor" && item.status === "Active") {
                            temp.push(item)
                        }
                    }
                }
                setFilteredAdvisors(temp)
                setSingleLeadModal(true)
            }
        }, { onlyOnce: true })
    }

    const handleSingleForward = async () => {

        let dbRef = ref(firebaseDatabase, `ALL_LEADS_STATUS/${selectedSingleLead.product}/${selectedSingleLead.phoneNumber}`)

        onValue(dbRef, async (snap) => {
            if (snap.exists()) {
                let status = snap.child("status").val()
                if (status === "Operations" || status === "Link Sent" || status === "Policy Generated") {
                    alert("Sorry! This number is already present in CRM under " + status + ".")
                } else {
                    handlePreExisting()
                }
                return
            } else {
                let item = { ...selectedSingleLead }
                item.status = "New"
                item.subStatus = "From Admin-Yet to Call"
                item.advisorPool = [selectedSingleAdvisor]
                item.assignedAdvisorUid = selectedSingleAdvisor.uid
                item.assignedAdvisorName = selectedSingleAdvisor.name
                item.assignedAdvisorPhoneNumber = selectedSingleAdvisor.phoneNumber
                item.newFromAdminDate = moment().format("DD-MM-YYYY h:mm a")
                item.type = "INBOUND"

                setSubmitting(true)

                console.log(item.product)
                console.log(item.assignedAdvisorUid)
                console.log(item.phoneNumber)
                console.log(item)
                if (item.product === "Education Loan") {
                    console.log("EL")
                    await set(ref(firebaseDatabase, `NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${item.phoneNumber}`), item)
                } else if (item.product === "Health Insurance") {
                    console.log("HI")
                    await set(ref(firebaseDatabase, `HI_NEW_LEADS/${item.assignedAdvisorUid}/${item.phoneNumber}`), item)
                    await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${item.phoneNumber}`), item)
                }

                //lifeline object
                let tempLifelineObj = {
                    status: "New",
                    lifeline: [{
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "From Admin-Yet to Call",
                        date: item.newFromAdminDate,
                        ref: "NEW_LEADS",
                        sentTo: item.assignedAdvisorUid
                    }]
                }


                console.log(tempLifelineObj)
                console.log(userData.uid)
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/${item.product}/${item.phoneNumber}`), tempLifelineObj)
                await remove(ref(firebaseDatabase, `FOR_RECORDING_APPLICATIONS/${userData.uid}/${item.phoneNumber}`))
                setSubmitting(false)
                setSingleLeadModal(false)
                fetchData()
            }
        }, { onlyOnce: true })
    }

    const handlePreExisting = () => {
        onValue(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${selectedSingleLead.phoneNumber}`), (snap) => {
            if (snap.exists()) {
                setPreExistingFile(snap.val())
                setAlreadyExistsModal(true)
            }
        }, {onlyOnce:true})
    }

    const handleReassign = () => {

        let pastRef = null
        switch (preExistingFile.status) {
            case "New": {
                pastRef = "HI_NEW_LEADS"
                break
            }
            case "Rescheduled": {
                pastRef = "HI_RESCHEDULED_LEADS"
                break
            }
            case "Interested": {
                pastRef = "HI_INTERESTED_LEADS"
                break
            }
            case "Closed": {
                pastRef = "HI_CLOSED_LEADS"
                break
            }
            default: {}
        }

        if (pastRef === "HI_INTERESTED_LEADS") {
            if (preExistingFile.assignedAdvisorUid === selectedSingleAdvisor.uid) {
                alert("The file is already in " + selectedSingleAdvisor.name + "'s Interested.")
                return
            } else {
                let newObject = {
                    ...preExistingFile,
                    assignedAdvisorUid: selectedSingleAdvisor.uid,
                    assignedAdvisorName: selectedSingleAdvisor.name,
                    assignedAdvisorPhoneNumber: selectedSingleAdvisor.phoneNumber,
                    reAssigningDate: moment().format("DD-MM-YYYY h:mm a"),
                    receivedFrom: {"position":userData.position, "name":userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid}
                }
        
                let hasBeenWithArray = preExistingFile.pastAdvisors?preExistingFile.pastAdvisors:[]
                hasBeenWithArray.push(preExistingFile.assignedAdvisorUid)
                newObject.pastAdvisors = hasBeenWithArray
        
                setSubmitting(true)
        
                onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), async (snap) => {
                    if (snap.exists()) {
                        let statusObject = snap.val()
        
                        let lifeLineArray = [...statusObject.lifeline]
                        let arrayItem = {
                            actionBy: userData.uid,
                            status: "Interested",
                            subStatus: "Reassigned by Admin - From Record",
                            date: moment().format("DD-MM-YYYY h:mm a"),
                            ref: "HI_INTERESTED_LEADS"
                        }
                        statusObject.status = "Interested"
                        lifeLineArray.push(arrayItem)
                        statusObject.lifeline = [...lifeLineArray]
        
                        if (newObject.phoneNumber && newObject.assignedAdvisorUid && userData.uid && preExistingFile.assignedAdvisorUid && preExistingFile.phoneNumber) {
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), statusObject)
                            await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${newObject.phoneNumber}`), newObject)
                            await set(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${newObject.assignedAdvisorUid}/${newObject.phoneNumber}`), newObject)
                            await remove(ref(firebaseDatabase, `FOR_RECORDING_APPLICATIONS/${userData.uid}/${newObject.phoneNumber}`))
                            await remove(ref(firebaseDatabase, `HI_INTERESTED_LEADS/${preExistingFile.assignedAdvisorUid}/${preExistingFile.phoneNumber}`))
    
                            setSubmitting(false)
                            setPreExistingFile({})
                            alert("Re-assigned successfully!")
                            setAlreadyExistsModal(false)
                            setSingleLeadModal(false)
                            fetchData()
                        } else {
                            alert("Something went wrong.")
                        }
                        
                    }
                }, {onlyOnce:true})
            }
        } else {
            let newObject = {
                ...preExistingFile,
                assignedAdvisorUid: selectedSingleAdvisor.uid,
                assignedAdvisorName: selectedSingleAdvisor.name,
                assignedAdvisorPhoneNumber: selectedSingleAdvisor.phoneNumber,
                reAssigningDate: moment().format("DD-MM-YYYY h:mm a"),
                newFromAdminDate: moment().format("DD-MM-YYYY h:mm a"),
                status: "New",
                subStatus: "From Admin-Yet to Call",
                product: "Health Insurance",
                firstCall: null,
                lastCallDate: "",
                receivedFrom: {"position":userData.position, "name":userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid}
            }
    
            let hasBeenWithArray = preExistingFile.pastAdvisors?preExistingFile.pastAdvisors:[]
            hasBeenWithArray.push(preExistingFile.assignedAdvisorUid)
            newObject.pastAdvisors = hasBeenWithArray
    
            setSubmitting(true)
    
            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    let statusObject = snap.val()
    
                    let lifeLineArray = [...statusObject.lifeline]
                    let arrayItem = {
                        actionBy: userData.uid,
                        status: "New",
                        subStatus: "Reassigned by Admin - From Record",
                        date: moment().format("DD-MM-YYYY h:mm a"),
                        ref: "HI_NEW_LEADS"
                    }
                    statusObject.status = "New"
                    lifeLineArray.push(arrayItem)
                    statusObject.lifeline = [...lifeLineArray]
    
                    await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Health Insurance/${newObject.phoneNumber}`), statusObject)
                    await set(ref(firebaseDatabase, `HEALTH_INSURANCE_APPLICATIONS/${newObject.phoneNumber}`), newObject)
                    await set(ref(firebaseDatabase, `HI_NEW_LEADS/${newObject.assignedAdvisorUid}/${newObject.phoneNumber}`), newObject)
                    await remove(ref(firebaseDatabase, `FOR_RECORDING_APPLICATIONS/${userData.uid}/${newObject.phoneNumber}`))
                    if ((preExistingFile.assignedAdvisorUid !== newObject.assignedAdvisorUid || pastRef!=="HI_NEW_LEADS")) {
                        await remove(ref(firebaseDatabase, `${pastRef}/${preExistingFile.assignedAdvisorUid}/${preExistingFile.phoneNumber}`))
                    }
                    setSubmitting(false)
                    setPreExistingFile({})
                    alert("Re-assigned successfully!")
                    setAlreadyExistsModal(false)
                    setSingleLeadModal(false)
                    fetchData()
                }
            }, {onlyOnce:true})
        }
    }

    return (
        <div>
            <div className={Styles.tabHederContainer}>
                <h1 style={{ color: "#352F6C" }}>Number Records</h1>
            </div>

            <Box>
                <TableContainer style={{marginTop:40}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>#</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Name</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Phone Number</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Email ID</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Upload Date</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Country</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>University</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Source</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C" }}>Department</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "white" }}>
                            {
                                visibleList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()}>
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.date}</TableCell>
                                            <TableCell>{item.desiredCountry}</TableCell>
                                            <TableCell>{item.desiredUniversity}</TableCell>
                                            <TableCell>{item.sourceDescription}</TableCell>
                                            <TableCell>{item.product}</TableCell>
                                            <TableCell><Button variant="contained" onClick={() => { filterAdvisors(item); setSelectedSingleLead(item) }}>Assign</Button></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Modal open={singleLeadModal} onClose={() => { setSingleLeadModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Select the advisor to forward the file to.</p>
                        <Select style={{ width: "100%" }} value={selectedSingleAdvisor} onChange={(event) => { setSelectedSingleAdvisor(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            {
                                filteredAdvisors.map((item) => {
                                    return (
                                        <MenuItem value={item}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <div className={Styles.buttonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: submitting ? "#ccc" : "rgba(53, 47, 108, 0.5)" }} onClick={() => { if (!submitting) { setSingleLeadModal(false) } }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { if (!submitting) { handleSingleForward() } }} variant='contained' style={{ backgroundColor: submitting ? "#ccc" : null }} className={Styles.primaryButton}>Assign</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={alreadyExistsModal} onClose={() => { setAlreadyExistsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>The file is currently present with {preExistingFile.assignedAdvisorName}, under {preExistingFile.status}.</p>
                        <p className={Styles.modalTitle}>Select the advisor you want to move this file to.</p>
                        <Select style={{ width: "100%" }} value={selectedSingleAdvisor} onChange={(event) => { setSelectedSingleAdvisor(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                            {
                                filteredAdvisors.map((item) => {
                                    return (
                                        <MenuItem value={item}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {
                            (preExistingFile.status === "New" || preExistingFile.status === "Rescheduled" || preExistingFile.status === "Closed")
                            ?
                            <p className={Styles.modalTitle}>The file will move to "New"</p>
                            :
                            <p className={Styles.modalTitle}>The file will move to "Interested"</p>
                        }
                        

                        <div className={Styles.buttonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: submitting ? "#ccc" : "rgba(53, 47, 108, 0.5)" }} onClick={() => { if (!submitting) { setAlreadyExistsModal(false) } }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { if (!submitting) { handleReassign()} }} variant='contained' style={{ backgroundColor: submitting ? "#ccc" : null }} className={Styles.primaryButton}>Assign</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default NumberRecords