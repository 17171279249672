import React, { useEffect, useRef, useState } from "react";
import Styles from "./CustomAutoComplete.module.css";
import { CircularProgress, Input, InputAdornment, TextField } from "@mui/material";
import { motion, useAnimationControls } from "framer-motion";
import _ from "lodash";

const CustomAutoComplete = ({ className, variant, value, InputProps, onChange, options, label }) => {
    const animationControl = useAnimationControls();
    const [inputQuery, setInputQuery] = useState("");
    const [suggestedInput, setSuggestedInput] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(false);
    const newSuggestionRef = useRef();
    const selectedValue = useRef();


    useEffect(() => {

        const findOptions = async () => {
            if (options.includes(inputQuery)) {
                return;
            }

            setLoading(true);
            if (inputQuery) {
                const newOptions = _.filter(options, item => {
                    if (item.toLowerCase().includes(inputQuery.toLowerCase())) {
                        return true;
                    } else {
                        return false
                    }
                })

                if (newOptions.length) {
                    await animationControl.start({
                        opacity: 1,
                        boxShadow: '2px 2px 10px -1px rgba(168,166,168,1)',
                        transition: {
                            ease: 'linear'
                        }
                    });

                    setSuggestedInput(newOptions.slice(0, 5));
                } else {

                    animationControl.start({
                        opacity: 0,
                        boxShadow: "unset",
                        transition: {
                            ease: 'linear'
                        }
                    })
                }


            } else {
                setSuggestedInput([])
                animationControl.start({
                    opacity: 0,
                    boxShadow: "unset",
                    transition: {
                        ease: 'linear'
                    }
                });

            }
            setLoading(false);

        }

        newSuggestionRef.current = setTimeout(() => {
            findOptions()
        }, 100);

        return () => {
            if (newSuggestionRef.current) {
                clearTimeout(newSuggestionRef.current);
                setLoading(false);
            }
        }

    }, [inputQuery])


    const handleItemSelect = (item) => {

        selectedValue.current = item;
        setInputQuery(item);
        onChange("", item)
        inputRef.current.focus();

        animationControl.start({
            opacity: 0,
            boxShadow: "unset",
            transition: {
                ease: 'linear'
            }
        }).then(() => {
            setSuggestedInput([]);
        })
    }

    const handleBlur = (event) => {
       
        event.stopPropagation();
        setTimeout(() => {
            if (selectedValue.current) {
                setInputQuery(selectedValue.current)
            }else {
                setInputQuery("")
            }
            animationControl.start({
                opacity: 0,
                boxShadow: "unset",
                transition: {
                    ease: 'linear'
                }
            }).then(() => {
                setSuggestedInput([]);
            })
        }, 500)


    }


    return (
        <div onBlur={handleBlur} className={Styles.autoCompleteContainer}>

            <TextField
                ref={inputRef}
                endAdornment={
                    loading
                    &&
                    <InputAdornment>
                        <CircularProgress style={{ color: '#2E186A' }} size='12px' />
                    </InputAdornment>
                } fullWidth='true'
                
                disableUnderline
                variant={variant}
                value={inputQuery}
                InputProps={InputProps}
                onChange={event => {setInputQuery(event.target.value) }}
                sx={{padding:"8px 12px"}}
                className={Styles.inputField} />

            {
                suggestedInput.length
                    ?
                    <motion.div initial={{ opacity: 0, boxShadow: "unset" }} animate={animationControl} className={Styles.optionsContainer}>
                        {
                            suggestedInput.map((item, index) => {
                                return (
                                    <div key={index.toString()}>
                                        <p  className={Styles.options} onClick={(event) => {handleItemSelect(item) }} >
                                            {item}
                                        </p>
                                    </div>
                                )

                            })

                        }
                    </motion.div>
                    :
                    null
            }

        </div>
    )
}

export default CustomAutoComplete;