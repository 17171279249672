import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiOperationsNewLeads.module.css"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { Box, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoCall } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import moment from "moment";

const HiOperationsNewLeads = () => {

    const [loading, setLoading] = useState(true)
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)

        let dbRef = null

        switch(newValue) {
            case 0: {
                dbRef = ref(firebaseDatabase, `HI_OP_USER_WITH_OPERATIONS_LEADS`)
                break
            }
            case 1: {
                dbRef = ref(firebaseDatabase, `HI_CROSS_SALE_LEADS`)
                break
            }
            case 2: {
                dbRef = ref(firebaseDatabase, `HI_FROM_WEBSITE_LEADS`)
                break
            }
            case 3: {
                dbRef = ref(firebaseDatabase, `INTERNALLY_CREATED_LEADS`)
                break
            }

            default: {}
        }

        let temp = []
        let temp2 = []

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    if ((userData.uid === "93nbcASsRuV8g8pLF4mga0wxbHM2" || userData.uid === "vuc57CqdO8dJCCXFtiQtJoH9VD53") && item.planDetail.name.includes("Prima")) {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    } 
                    if (userData.uid === "0uH2KMkUD7TlfEV3yXL7ZfQGEbh2" && item.planDetail.name === "SC Gold Plus") {
                        let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                        item.travelAge = moment(travelDate).diff(moment(), 'days')
                        temp.push(item)
                    }
                    
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
        
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>New Leads</h1>

            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Advisor Leads" {...a11yProps(0)} />
                    <Tab label="Cross Sale Leads" {...a11yProps(1)} />
                    <Tab label="Website Leads" {...a11yProps(2)} />
                    <Tab label="Direct Leads" {...a11yProps(3)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.sentToOperationsDate?item.sentToOperationsDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.travelAge} days</TableCell>
                                                    <TableCell>{item.assignedAdvisorName}</TableCell>
                                                    <TableCell><IoCall size={20} color="#352F6C" onClick={()=>{navigate("/health-insurance/operations-new-leads/operations-new-lead-detail", {state:{item:item, tab:value}})}} style={{cursor:"pointer"}} /></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiOperationsNewLeads