import React, { useContext, useEffect, useState } from "react";
import Styles from './AdvisorDashboards.module.css';
import Notifications from "../DashboardElements/Notifications/Notifications.component";
import fetchNotification from "../../../workload/NotificationFetcher";
import UserContext from "../../../context/UserData.context";
import AdvisorLeads from "../DashboardElements/AdvisorLeads/AdvisorLeads.component";
import AdvisorOverall from "../DashboardElements/AdvisorOverall/AdvisorOverall.component";

const AdvisorDashboard = () => {
    const [notifications, setNotifications] = useState({ loading: true, data: [], noDataFound: false });
    const [userData] = useContext(UserContext);

    useEffect(() => {
        if (userData.uid) {
            (async () => {
                const notificationList = await fetchNotification(userData.uid);
                setNotifications({ loading: false, data: [...notificationList], noDataFound: notificationList.length === 0 })
            })()
        }
    }, [userData]);


    return (
        <div className={Styles.dashboardContainer}>
            <div className={Styles.topContainer}>
                <div className={Styles.notifications}>
                    <Notifications notificationList={notifications.data} loading={notifications.loading} noDataFound={notifications.noDataFound} />
                </div>

                <div className={Styles.rescheduled}>
                    <h2>Reschedule Reminder</h2>
                    <p>Coming Soon</p>
                </div>
            </div>

            <div className={Styles.topContainer}>
                {
                    userData.uid
                    &&
                    <AdvisorLeads uid={userData.uid} />
                }

                {
                    userData.uid
                    &&
                    <AdvisorOverall uid={userData.uid} />
                }

            </div>


        </div>
    )
}

export default AdvisorDashboard;