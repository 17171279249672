import React, { useContext, useEffect, useState } from "react";
import Styles from "./VendorReLookLeads.module.css"
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";

const VendorReLookLeads = () => {
    
    const [visibleList, setVisibleList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    useEffect (() => {
        let temp = []

        onValue(ref(firebaseDatabase, `VENDOR_RE_LOOK_LEADS/${userData.nbfcName}`), async (snap) => {
            if (snap.exists()) {
                let num = 0
                for (const key in snap.val()) {
                    let obj = snap.child(key).val()
                    if (obj.assignedRmUid === userData.uid) {
                        temp.push (obj)
                    }
                    
                    num++
                    if (num === snap.size) {
                        setVisibleList(temp)
                        setLoading(false)
                    }
                }
            } else {
                console.log("here")
                setLoading(false)
            }
        }, {onlyOnce:true})
    }, [])

    return (
        <div>
            <h1 style={{ color: "#352F6C", marginBottom:40 }}>Re-look Leads</h1>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Date Received</TableCell>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.customerId}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.sentToVendorDate}</TableCell>
                                                    <TableCell><Button style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained' onClick={()=>{navigate("/vendor-re-look-leads/vendor-complete-detail", {state:{item:item}})}}>View</Button></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default VendorReLookLeads