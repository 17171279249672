import { onValue, ref } from "firebase/database"
import { firebaseDatabase } from "../backend/firebaseHandler"

export const checkIfUserActive = async (uid) => {
    const activePromise = new Promise((resolve, reject) => {
        const allUsersRef = ref(firebaseDatabase, `USER_ARCHIVE/${uid}/status`);
        onValue(allUsersRef, (dataSnapshot) => {
            if (dataSnapshot.exists()) {
                const currentStatus = dataSnapshot.val();
                resolve(currentStatus)
            }else {
                resolve("Not Found");
            }
        },{onlyOnce:true})
    })
   
    return await activePromise.then();
}