import Styles from "./NbfcManagerConfirmLeads.module.css"
import React, { useState } from "react";
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"
import { IoMdInformationCircle } from "react-icons/io"
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { IoFolderOpenSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NbfcManagerConfirmLeads = ({ activeList }) => {

    const [infoModal, setInfoModal] = useState(false)
    const [advisorDetail, setAdvisorDetail] = useState({})
    const navigate = useNavigate()

    const handleAdvisorInfo = (application) => {
        onValue(ref(firebaseDatabase, `USER_ARCHIVE/${application.assignedAdvisorUid}`), (snap) => {
            if (snap.exists()) {
                setAdvisorDetail(snap.val())
                setInfoModal(true)
            }
        }, {onlyOnce:true})
    }
    
    return (
        <div>
            <Box>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>#</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>App ID</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Name</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Ph Number</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Email ID</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Date Received</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>Status</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>PF Link Date</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>PF Document</TableCell>
                                <TableCell style={{ fontWeight: 700, color: "#352F6C", backgroundColor: "rgba(1, 1, 1, 0)" }}>File</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>SC Advisor</TableCell>
                                <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Executive</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ backgroundColor: "white" }}>
                            {
                                activeList.map((item, index) => {
                                    return (
                                        <TableRow key={index.toString()} >
                                            <TableCell>{(index + 1).toString()}</TableCell>
                                            <TableCell>
                                                {item.applicationId}
                                            </TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.phoneNumber}</TableCell>
                                            <TableCell>{item.emailId}</TableCell>
                                            <TableCell>{item.sentToVendorDate.split(" ")[0]}</TableCell>
                                            <TableCell>{item.status==="Approved"?"Pending":item.pfLinkDate?"Accepted by Student":item.status}</TableCell>
                                            <TableCell>{item.pfLinkDate?item.pfLinkDate.split("-").reverse().join("-"):"-"}</TableCell>
                                            <TableCell>
                                                {
                                                    item.pfUrl
                                                    ?
                                                    <HiDocumentCheck onClick={()=>{window.open(item.pfUrl, "_blank")}} color={"#352F6C"} size={25} style={{cursor:"pointer"}} />
                                                    :
                                                    "-"
                                                }
                                            </TableCell>
                                            <TableCell><IoFolderOpenSharp  size={20} color="#352F6C" onClick={()=>{navigate("/vendor-view-file", { state: { item: item } })}} style={{cursor:"pointer"}} /></TableCell>
                                            <TableCell><div style={{display:"flex", flexDirection:"row", alignItems:"center"}}><p>{item.assignedAdvisorName}</p><IoMdInformationCircle size={20} color="#352F6C" onClick={()=>{handleAdvisorInfo(item)}} style={{cursor:"pointer"}} /></div></TableCell>
                                            <TableCell>{item.assignedRmName}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Modal open={infoModal} onClose={() => { setInfoModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle}>Advisor Detail</p>
                        <div className={Styles.infoContainer}>
                            <p className={Styles.infoItem}>Name</p>
                            <p className={Styles.infoItem}>{advisorDetail.name}</p>

                            <p className={Styles.infoItem}>Phone Number</p>
                            <p className={Styles.infoItem}>{advisorDetail.phoneNumber}</p>

                            <p className={Styles.infoItem}>Email-Id</p>
                            <p className={Styles.infoItem}>{advisorDetail.emailId}</p>
                        </div>

                        <Button onClick={() => {setInfoModal(false)}} variant='contained' className={Styles.primaryButton}>Okay</Button>

                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default NbfcManagerConfirmLeads