import React, { useContext, useState } from "react";
import Styles from "./AddSingleLead.module.css"
import InputContainer from "../../InputContainer/InputContainer.component";
import { Alert, Button, MenuItem, Select, Snackbar } from "@mui/material";
import educationLoan from "../../../entities/EducationLoan.sample";
import healthInsurance from "../../../entities/HealthInsurance.sample";
import { onValue, push, ref, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseSC } from "../../../backend/firebaseHandler";
import UserContext from "../../../context/UserData.context";
import moment from "moment";
import CustomAutoComplete from "../../CustomAutoComplete/CustomAutoComplete.component";

const AddSingleLead = () => {

    const [submitting, setSubmitting] = useState(false)
    const [leadDepartment, setLeadDepartment] = useState("")
    const [application, setApplication] = useState({})
    const [userData] = useContext(UserContext)
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })
    const [purpose, setPurpose] = useState("")
    const [universities, setUniversities] = useState([])

    const handleDepartment = (value) => {
        let sampleData = {}

        const forKeyRef = ref(firebaseDatabase, `/POOL_DIRECT_APPLICATIONS/${value}`)
        const key = push(forKeyRef).key;

        if (value === "Education Loan") {
            sampleData = { ...educationLoan }
        } else {
            sampleData = { ...healthInsurance }
        }

        sampleData.key = key
        sampleData.phoneNumber = ""
        sampleData.name = ""
        sampleData.emailId = ""
        sampleData.desiredCountry = ""
        sampleData.desiredUniversity = ""
        sampleData.source = userData.name
        sampleData.sourceDescription = ""
        sampleData.type = 0
        sampleData.date = moment().format("DD-MM-YYYY h:mm a")
        sampleData.status = "Pool"
        sampleData.subStatus = ""
        sampleData.product = value
        sampleData.receivedFrom = { "position": userData.position, "name": userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid }
        sampleData.advisorPool = []
        sampleData.singleLeadRemark = ""

        setApplication({...sampleData})
        setLeadDepartment(value)
    }

    const handleSubmit = async () => {
        console.log("*****The entered phone number is: " + application.phoneNumber + "*****")
        if (userData.uid === "E42tLmeqS7cltIuEmgFtBM2GpFk1" || userData.uid === "aX0vk1p67lWloOqpKGmbHMnlSH73") {
            if (purpose === "") {
                setSnackBar({severity:"warning", message:"Please select the purpose", visibility:true})
                return
            }
            if (application.phoneNumber.trim().length < 8) {
                setSnackBar({severity:"warning", message:"Please enter a valid phone number", visibility:true})
                return
            }

            setSubmitting(true)
            if (purpose === "Distribution") {
                await set(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${application.product}/${application.phoneNumber}`), application)
            } else {
                await set(ref(firebaseDatabase, `FOR_RECORDING_APPLICATIONS/${userData.uid}/${application.phoneNumber}`), application)
            }
            setSubmitting(false)
            setSnackBar({severity:"success", message:"Added!", visibility:true})
            setApplication({})
            setLeadDepartment("")
        } else {
            if (userData.uid === "vd3Awrh576eZlWu5gInDjdLAh9o1") {
                if (application.phoneNumber.trim().length < 7) {
                    setSnackBar({severity:"warning", message:"Please enter a valid phone number", visibility:true})
                    return
                }
            } else {
                if (application.phoneNumber.trim().length !== 12) {
                    setSnackBar({severity:"warning", message:"Please enter a valid phone number", visibility:true})
                    return
                }
            }
        
            setSubmitting(true)
            await set(ref(firebaseDatabase, `POOL_DIRECT_APPLICATIONS/${application.product}/${application.phoneNumber}`), application)
            setSubmitting(false)
            setSnackBar({severity:"success", message:"Added!", visibility:true})
            setApplication({})
            setLeadDepartment("")
        }
        
    }

    const handleTravelingTo = (value) => {
        let temp = []
        
        setApplication({...application, desiredCountry: value})
        if (value) {
            onValue(ref(firebaseDatabaseSC, "COUNTRY_WISE_UNIVERSITIES/"+value), (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        temp.push(snapShot.child(key).val())
                        
                    }
                    setUniversities(temp)
                }
            }, {onlyOnce:true})
        } else {
            setUniversities(temp)
        }
    }

    return (
        <div className={Styles.addSingleLeadContainer}>
            <div className={Styles.headerContainer}>
                <h1 style={{ color: "#352F6C" }}>Add Single Lead</h1>
            </div>

            <div className={Styles.formContainer}>
                <div style={{ width: "100%" }}>
                    <p className={Styles.label}>Department</p>
                    <Select style={{ width: "100%" }} value={leadDepartment} onChange={(event) => { handleDepartment(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                        <MenuItem value="Education Loan">Education Loan</MenuItem>
                        <MenuItem value="Health Insurance">Health Insurance</MenuItem>
                    </Select>
                </div>    

                {
                    leadDepartment !== ""
                    &&
                    <>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Name</p>
                            <input value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>   
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Phone Number (With country code)</p>
                            <input value={application.phoneNumber} onChange={(event) => { setApplication({ ...application, phoneNumber: event.target.value.replace(/[^0-9.]+/g, "") }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>  
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Email Id</p>
                            <input value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>  
                        
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Select Country</p>
                            <CustomAutoComplete
                                className={Styles.inputField}
                                label="Select Traveling to"
                                variant="standard"
                                value={application.desiredCountry}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event, value) => {handleTravelingTo(value)}}
                                options={["Australia", "Canada", "Germany", "Ireland", "Singapore", "UAE", "United Kingdom", "United States", "Others"]}
                            />  
                        </div> 
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Select University</p>
                            <CustomAutoComplete
                                className={Styles.inputField}
                                label="Select Universities"
                                variant="standard"
                                value={application.desiredUniversity}
                                InputProps={{ disableUnderline: true }}
                                onChange={(event, value) => {setApplication({ ...application, desiredUniversity: value })}}
                                options={universities}
                            />  
                        </div> 
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Source</p>
                            <input value={application.sourceDescription} onChange={(event) => { setApplication({ ...application, sourceDescription: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>  
                        <div style={{ width: "100%", gridColumn:"2/4" }}>
                            <p className={Styles.label}>Remark</p>
                            <input value={application.singleLeadRemark} onChange={(event) => { setApplication({ ...application, singleLeadRemark: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>  

                        {
                            (userData.uid === "E42tLmeqS7cltIuEmgFtBM2GpFk1" || userData.uid === "aX0vk1p67lWloOqpKGmbHMnlSH73")
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Purpose</p>
                                    <Select style={{ width: "100%" }} value={purpose} onChange={(event) => { setPurpose(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Distribution">Distribution</MenuItem>
                                        <MenuItem value="Record Maintenance">Record Maintenance</MenuItem>
                                    </Select>
                                </div> 
                            </>
                        }
                    </>
                }            
            </div>

            <Button disabled={submitting || (leadDepartment==="")} onClick={() => { handleSubmit() }} className={Styles.button} style={{ backgroundColor: (submitting||(leadDepartment==="")) ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>Add</Button>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default AddSingleLead