import React, { useState } from "react";
import Styles from "./HiInterestedCollapseRow.module.css"
import { Button, Collapse, TableCell, TableRow } from "@mui/material";
import { IoCall  } from "react-icons/io5"
import { IoMdInformationCircleOutline  } from "react-icons/io"
import { useNavigate } from "react-router-dom";
import { RiPushpinFill } from "react-icons/ri";

const HiInterestedCollapseRow = ({userType, item, index}) => {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    const handleClick = () => {
        if (userType === "Manager") {
            navigate("/health-insurance/lead-status/complete-lead-detail", {state:{item:item}})
        } else if (userType === "Admin") {
            navigate("/all-hi-lead-status/complete-lead-detail", {state:{item:item}})
        }
    }

    return (
        <>
            <TableRow key={index.toString()} >
                <TableCell><RiPushpinFill size={20} color="#ccc" style={{display:item.isPinned?null:"none"}} /></TableCell>
                <TableCell>{(index + 1).toString()}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{item.emailId}</TableCell>
                <TableCell>{item.desiredUniversity}</TableCell>
                <TableCell>{item.travelAge} days</TableCell>
                <TableCell>{isNaN(item.callAge)?"-":item.callAge} days</TableCell>
                <TableCell>{item.nextCallDateTime?item.nextCallDateTime:""}</TableCell>
                {
                    (userType === "Manager" || userType === "Admin")
                    ?
                    <> 
                        <TableCell>{item.assignedAdvisorName}</TableCell>
                        <TableCell><IoMdInformationCircleOutline size={20} color="#352F6C" onClick={()=>{setOpen(!open)}} style={{cursor:"pointer"}} /></TableCell>
                        <TableCell><Button onClick={()=>{handleClick()}} variant="contained" style={{backgroundColor:"#352F6C"}}>View</Button></TableCell>
                    </>
                    :
                    <>
                        <TableCell><IoMdInformationCircleOutline size={20} color="#352F6C" onClick={()=>{setOpen(!open)}} style={{cursor:"pointer"}} /></TableCell>
                        <TableCell><IoCall size={20} color="#352F6C" onClick={()=>{navigate("/health-insurance/interested-leads/interested-lead-detail", {state:{item:item}})}} style={{cursor:"pointer"}} /></TableCell>
                    </>
                }
            </TableRow>

            <TableRow  key={index.toString()}>
                <TableCell colSpan={userType === "Advisor"?11:12} style={{padding:0}}>
                    <Collapse in={open} >
                        <div className={Styles.detailContainer}>
                            <p className={Styles.comment}>{item.subStatus}</p>
                            <p className={Styles.comment}>Remark from you: {item.remarkFromAdvisor}</p>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default HiInterestedCollapseRow