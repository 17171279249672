const getMailList = () => {
    const MailList = [
        {
            name: 'Avanse',
            uid: 'Avanse',
            url: ""
        },
        {
            name: 'Credila',
            uid: 'Credila',
            url: ""
        },
        {
            name: 'InCred',
            uid: 'InCred',
            url: ""
        },
        {
            name: 'IDFC First Bank',
            uid: 'IDFC',
            url: ""
        },
        {
            name: 'Auxilo',
            uid: 'Auxilo',
            url: ""
        },
        {
            name: 'Tata Capital',
            uid: 'TataCapital',
            url: ""
        },
        {
            name: 'Union Bank',
            uid: 'UnionBank',
            url: ""
        },
        {
            name: 'Axis Bank',
            uid: 'AxisBank',
            url: ""
        },
        {
            name: 'ICICI',
            uid: 'ICICI',
            url: ""
        },
        {
            name: 'Prodigy',
            uid: 'Prodigy',
            url: ""
        }
    ]

    return MailList
}

export default getMailList