import React from "react";
import Styles from "./AdvisorCaseDetailsLeadsIncognito.module.css"
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { HiDocumentCheck } from "react-icons/hi2"

const AdvisorCaseDetailLeadsIncognito = ({activeList}) => {

    const navigate = useNavigate()

    return (
        <div className={Styles.advisorCaseDetailsLeadContainer}>
            <div style={{width:"100%", marginTop:40}}>
                <Box>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Customer ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Case Details Date</TableCell>
                                    <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Last Call Date</TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{backgroundColor:"white"}}>
                                {
                                    activeList.map((item, index) => {
                                        return (
                                            <TableRow key={index.toString()} >
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{(index + 1).toString()}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.customerId}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.name}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>xxxxxxxxx{item.phoneNumber.substring(9, 12)}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.emailId}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.caseDetailDate}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}>{item.lastCallDate.split(" ")[0]}</TableCell>
                                                <TableCell style={{color:item.outstandingDate?"#ccc":"#444"}}><HiDocumentCheck onClick={()=>{navigate("/advisor-incognito-leads/advisor-incognito-lead-detail", {state:{item:item}})}} color={item.isLodVerified==="Yes"?"#12b004":"#352F6C"} size={25} style={{cursor:"pointer"}} /></TableCell>
                                                <TableCell><Button onClick={()=>{navigate ("/advisor-incognito-leads/advisor-incognito-case-detail", {state:{item:item}})}} style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained'>Update CD</Button></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    )
}

export default AdvisorCaseDetailLeadsIncognito