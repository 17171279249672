import React, { useContext, useEffect, useState } from "react";
import Styles from "./HiManagerWithOprationsLeads.module.css"
import moment from "moment";
import { Box, Button, CircularProgress, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { IoDocument } from "react-icons/io5";
import { onValue, ref } from "firebase/database";
import { firebaseDatabase } from "../../backend/firebaseHandler";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserData.context";

const HiManagerWithOprationsLeads = () => {

    const [visibleList, setVisibleList] = useState([])
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true)
    const [userData] = useContext(UserContext)

    const navigate = useNavigate()

    useEffect(() => {
        handleChange(null, 0)
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setLoading(true)
        let temp = []
        let temp2 = []
        let dbRef = null

        if (newValue === 0) {
            dbRef = ref(firebaseDatabase, `HI_OP_USER_WITH_OPERATIONS_LEADS`)
        } else if (newValue === 1) {
            dbRef = ref(firebaseDatabase, `HI_CROSS_SALE_LEADS`)
        } else if (newValue === 2) {
            dbRef = ref(firebaseDatabase, `HI_FROM_WEBSITE_LEADS`)
        } 
        
        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let item = snap.child(key).val()
                    let travelDate = moment(item.travelDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                    item.travelAge = moment(travelDate).diff(moment(), 'days')
                    temp.push(item)
                }

                temp2 = temp.sort((a, b) => {
                    const date_1 = moment(a.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    const date_2 = moment(b.sentToOperationsDate,"DD-MM-YYYY h:mm a")
                    return moment(date_2).diff(date_1)
                })
            }

            setVisibleList([...temp2])
            setLoading(false)
        }, {onlyOnce:true})
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleClick = (item) => {
        if (userData.position === "Manager") {
            navigate("/health-insurance/lead-status/complete-lead-detail", {state:{item:item}})
        } else {
            navigate("/all-hi-lead-status/complete-lead-detail", {state:{item:item}})
        }
    }

    return (
        <div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20, marginBottom:40}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Advisor Lead" {...a11yProps(0)} />
                    <Tab label="Cross Sale Leads" {...a11yProps(1)} />
                    <Tab label="Website Leads" {...a11yProps(2)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer}>
                {
                    loading
                    ?
                    <CircularProgress className={Styles.progress} />
                    :
                    <Box>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>#</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Ph Number</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Email ID</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>University</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Plan Name</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Premium</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Sent to OP on</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Days before travel</TableCell>
                                        <TableCell style={{fontWeight:700, color:"#352F6C", backgroundColor:"rgba(1, 1, 1, 0)"}}>Advisor</TableCell>
                                        <TableCell style={{backgroundColor:"rgba(1, 1, 1, 0)"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{backgroundColor:"white"}}>
                                    {
                                        visibleList.map((item, index) => {
                                            return (
                                                <TableRow key={index.toString()} >
                                                    <TableCell>{(index + 1).toString()}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.phoneNumber}</TableCell>
                                                    <TableCell>{item.emailId}</TableCell>
                                                    <TableCell>{item.desiredUniversity}</TableCell>
                                                    <TableCell>{item.planDetail?item.planDetail.name:""}</TableCell>
                                                    <TableCell>{item.planDetail?item.planDetail.premium:""}</TableCell>
                                                    <TableCell>{item.sentToOperationsDate?item.sentToOperationsDate.split(" ")[0]:""}</TableCell>
                                                    <TableCell>{item.travelAge} days</TableCell>
                                                    <TableCell>{item.assignedAdvisorName}</TableCell>
                                                    <TableCell><Button onClick={()=>{handleClick(item)}} variant="contained" style={{backgroundColor:"#352F6C"}}>View</Button></TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </div>
        </div>
    )
}

export default HiManagerWithOprationsLeads