import React, { useState } from "react";
import Styles from "./VendorReloginCollapseRow.module.css"
import { Button, Collapse, TableCell, TableRow } from "@mui/material";

const VendorReloginCollapseRow = ({item, index}) => {

    const [open, setOpen] = useState(false)

    return (
        <>
            <TableRow key={index.toString()} >
                <TableCell>{(index + 1).toString()}</TableCell>
                <TableCell>{item.applicationId}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{item.emailId}</TableCell>
                <TableCell>{item.sentToVendorDate}</TableCell>
                <TableCell>{item.subStatus.split(" - ")[0]}</TableCell>
                <TableCell><Button style={{backgroundColor:"#352F6C", height:30}} size='medium' variant='contained' onClick={()=>{setOpen(!open)}}>Reason</Button></TableCell>
            </TableRow> 

            <TableRow  key={index.toString()}>
                <TableCell colSpan={9} style={{padding:0}}>
                    <Collapse in={open} >
                        <div className={Styles.detailContainer}>
                            <p className={Styles.comment}>{item.noteFromVendor}</p>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default VendorReloginCollapseRow