import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdvisorIncognitoLeads.module.css"
import UserContext from "../../../context/UserData.context";
import { useNavigate } from "react-router-dom";
import { limitToFirst, onValue, orderByChild, query, ref } from "firebase/database";
import { firebaseDatabase } from "../../../backend/firebaseHandler";
import { Tab, Tabs, CircularProgress } from "@mui/material";
import AdvisorNewLeadsIncognito from "../../AdvisorLeadFlow/AdvisorNewLeadsIncognito/AdvisorNewLeadsIncognito.component";
import AdvisorInterestedLeadsIncognito from "../../AdvisorLeadFlow/AdvisorInterestedLeadsIncognito/AdvisorInterestedLeadsIncognito.component";
import AdvisorCaseDetailLeadsIncognito from "../../AdvisorLeadFlow/AdvisorCaseDetailsLeadsIncognito/AdvisorCaseDetailsLeadsIncognito.component";
import AdvisorWithOperationLeads from "../../AdvisorLeadFlow/AdvisorWithOperationLeads/AdvisorWithOperationLeads.component";
import AdvisorWithVendorLeads from "../../AdvisorLeadFlow/AdvisorWithVendorLeads/AdvisorWithVendorLeads.component";

const AdvisorIncognitoLeads = () => {

    const [activeList, setActiveList] = useState([])
    const [userData, setUserData] = useContext(UserContext)
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        const sessionTab = sessionStorage.getItem("topTab");
        if (sessionTab !== null && sessionTab !== undefined && sessionTab !== "") {
            handleChange(null, parseInt(sessionTab));
        } else {
            handleChange(null, 0);
        }

    }, [userData])

    const handleChange = (event, newValue) => {
        setValue(newValue)
        sessionStorage.setItem("topTab", newValue);

        let temp = []
        let dbRef = null;

        setLoading(true)
        switch (newValue) {
            case 0: {
                break;
            }
            case 1: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}`);
                break;
            }
            case 2: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}`)
                break;
            }
            case 3: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_WITH_OPERATIONS_LEADS/${userData.uid}`)
                break;
            }
            case 4: {
                dbRef = ref(firebaseDatabase, `INCOGNITO_WITH_VENDORS_LEADS/${userData.uid}`)
                break;
            }
            case 5: {
                dbRef = query(ref(firebaseDatabase, `INCOGNITO_PF_PAID_LEADS/${userData.uid}`), limitToFirst(50))
                break;
            }
            case 6: {
                dbRef = query(ref(firebaseDatabase, `INCOGNITO_SL_RECEIVED_LEADS/${userData.uid}`), limitToFirst(50))
                break;
            }
            case 7: {
                dbRef = query(ref(firebaseDatabase, `INCOGNITO_DISBURSED_LEADS/${userData.uid}`), limitToFirst(50))
                break;
            }
            case 8: {
                dbRef = query(ref(firebaseDatabase, `INCOGNITO_CLOSED_LEADS/${userData.uid}`), limitToFirst(50))
                break;
            }
            default: {
                dbRef = query(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}`), limitToFirst(50))             
            }
        }

        if (dbRef && newValue !== 0) {
            onValue(dbRef, (snapShot) => {
                if (snapShot.exists()) {
                    for (const key in snapShot.val()) {
                        console.log(key)
                        let item = snapShot.child(key).val()
                        temp.push(item)
                    }
                } 
                setActiveList(temp)
                setLoading(false)
            }, { onlyOnce: true })
        } else {
            setActiveList([]);
            setLoading(false)
        }

    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <div>
                <h1 style={{ color: "#352F6C" }}>Your Leads</h1>
            </div>

            <div>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Active" {...a11yProps(0)} />
                    <Tab label="Interested" {...a11yProps(1)} />
                    <Tab label="Case Details" {...a11yProps(2)} />
                    <Tab label="Operations" {...a11yProps(3)} />
                    <Tab label="Vendor Status" {...a11yProps(4)} />
                    <Tab label="PF Paid" {...a11yProps(5)} />
                    <Tab label="SL Received" {...a11yProps(6)} />
                    <Tab label="Disbursed" {...a11yProps(7)} />
                    <Tab label="Closed" {...a11yProps(8)} />
                </Tabs>
            </div>

            <div className={Styles.progressComponentContainer} style={{marginTop:40}}>
                {
                    loading
                        ?
                        <CircularProgress className={Styles.progress} />
                        :
                        <div>
                            {
                                value === 0 && <AdvisorNewLeadsIncognito activeList={activeList} userData={userData} />
                            }

                            {
                                value === 1 && <AdvisorInterestedLeadsIncognito activeList={activeList} />
                            }

                            {
                                value === 2 && <AdvisorCaseDetailLeadsIncognito activeList={activeList} />
                            }

                            {
                                value === 3 && <AdvisorWithOperationLeads activeList={activeList} type={"INCOGNITO"} />
                            }

                            {
                                value === 4 && <AdvisorWithVendorLeads activeList={activeList} type={"INCOGNITO"} />
                            }

                            {/* {
                                value === 5 && <AdvisorPfPaidLeads activeList={activeList} />
                            }

                            {
                                value === 6 && <AdvisorSlReceivedLeads activeList={activeList} />
                            }

                            {
                                value === 7 && <AdvisorDisbursementLeads activeList={activeList} />
                            } */}
                        </div>
                }
            </div>
        </div>
    )
}

export default AdvisorIncognitoLeads
